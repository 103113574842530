import { CookiesData } from '@cue/admin-cookies';
import { Observable, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public entity = '/cookies';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  get(): Observable<{
    data: CookiesData[];
    success: boolean;
    errorCode: string;
    errorDescription: string;
  }> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http.get<{
      data: any;
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url);
  }

  update(data: CookiesData[]): Observable<{ data: any; success: boolean; errorCode: string; errorDescription: string }> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http
      .post<{
        data: CookiesData[];
        success: boolean;
        errorCode: string;
        errorDescription: string;
      }>(url, data)
      .pipe(catchError((_) => of({ data: [], success: false, errorCode: '-1', errorDescription: 'Unexpected error' })));
  }
}
