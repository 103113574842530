import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ConfigService } from '@cue/admin-core';

@Directive({
  selector: '[appHideCalendar]',
  standalone: true,
})
export class HideCalendarDirective implements AfterViewInit {
  constructor(
    private configService: ConfigService,
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.configService.value.hideCalendar === true) {
      this.elementRef.nativeElement.style.setProperty('display', 'none', 'important');
    }
  }
}
