import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CONSTANTS } from '@cue/admin-constants';

@Component({
  selector: 'app-qr-code-warning-dialog',
  templateUrl: './qr-code-warning-dialog.component.html',
  styleUrls: ['./qr-code-warning-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, DialogModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeWarningDialogComponent implements OnInit {
  public dialogWidth: number;

  @Input() successInformation: string = 'qrCode.added';
  @Output() cancelDialogRefreshGrid = new EventEmitter();
  @Output() cancelDialog = new EventEmitter();

  constructor() {
    this.getScreenSize();
  }

  ngOnInit(): void {}

  /* -------------------------------------------------------------------- */
  // obecná funkce pro všechny dialogy
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if (window.innerWidth < CONSTANTS.dialogWidth) {
      this.dialogWidth = window.innerWidth - CONSTANTS.dialogWidthGap;
    } else {
      this.dialogWidth = CONSTANTS.dialogWidth - CONSTANTS.dialogWidthGap;
    }
  }
}
