import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { QrCodeService, UniqueNameValidator } from '@cue/admin-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qr-code-new',
  templateUrl: './qr-code-new.component.html',
  styleUrls: ['./qr-code-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, MatIconModule, CommonModule, ButtonModule],
})
export class QrCodeNewComponent implements OnInit {
  // QR codes input
  @Input() qrCodeList: { id: string; name: string; code: string }[] = [];

  // QR codes output
  @Output() add = new EventEmitter<{ id: string; name: string; code: string }>();
  @Output() delete = new EventEmitter<{ id: string; name: string; code: string }>();

  /*public qrCodeForEdit = null;*/

  public qrCodeNameForm = new FormGroup({
    qrCodeName: new FormControl('', [], UniqueNameValidator.uniqueNameValidatorAsync(this.qrCodeService, undefined)),
    /*qrCodeNameEdit: new FormControl('')*/
  });

  constructor(
    private qrCodeService: QrCodeService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.qrCodeNameForm
      .get('qrCodeName')
      .setValidators([Validators.required, UniqueNameValidator.uniqueNameValidator(this.qrCodeList.map((x) => x.name))]);
  }

  onAdd(id: string, name: string) {
    if (this.qrCodeNameForm.valid) {
      this.add.emit({ id, name, code: null });
      this.qrCodeNameForm.get('qrCodeName').patchValue('', { emitEvent: false });
      this.qrCodeNameForm.get('qrCodeName').markAsUntouched();
      this.qrCodeNameForm.get('qrCodeName').markAsPristine();
      this.qrCodeNameForm
        .get('qrCodeName')
        .setValidators([Validators.required, UniqueNameValidator.uniqueNameValidator(this.qrCodeList.map((x) => x.name))]);
    } else {
      this.qrCodeNameForm.get('qrCodeName').markAsTouched();
      this.qrCodeNameForm.get('qrCodeName').markAsDirty();
      this.changeDetectorRef.markForCheck();
    }
  }

  navigateToQRCode(id: string) {
    this.router.navigate(['qrCodes', 'detail', id]);
  }

  // obecná funkce pro všechny formuláře
  public isInvalid(field: any) {
    return field.invalid && (field.touched || field.dirty);
  }
}
