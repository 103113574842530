import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor() {}

  openHelp(urlPostfix: string) {
    fetch(CONSTANTS.help.confluenceOnline, { mode: 'no-cors' })
      .then((res) => {
        window.open(urlPostfix, CONSTANTS.help.tabName);
        //window.open(CONSTANTS.help.online + urlPostfix, CONSTANTS.help.tabName);
        return true;
      })
      .catch((err) => {
        return false;
      });
  }
}
