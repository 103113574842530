import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwitchModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-smart-switch',
  templateUrl: './smart-switch.component.html',
  styleUrls: ['./smart-switch.component.scss'],
  standalone: true,
  imports: [SwitchModule, TranslateModule],
})
export class SmartSwitchComponent implements OnInit {
  @Input() valueBefore: string;
  @Input() valueAfter: string;
  @Input() checked: boolean;

  @Input() selectedCount: number;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() clearFiltersClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
