import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class TimePickerService {
  public timepickerFormat = CONSTANTS.timepickers.analyticsBasicOverview_workingHoursDaily.format;

  public timePickerTimeFromMinutes(minutes: number): Date {
    if (minutes || minutes === 0) {
      const finalMinutes = minutes % 60;
      const hours = Math.floor(minutes / 60);
      return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), hours, finalMinutes);
    }
    return null;
  }

  public timePickerStepsFromMinutes(minutes: number): { hour: number; minute: number } {
    const finalMinutes = minutes % 60;
    const hours = Math.floor(minutes / 60);
    return { hour: hours, minute: finalMinutes };
  }

  public minutesFromDate(value: Date): number {
    return value ? value.getHours() * 60 + value.getMinutes() : null;
  }

  timePickerTimeFromHoursMinutes(hours: number, minutes: number): Date {
    const date = new Date();
    date.setHours(hours, minutes);
    return date;
  }
}
