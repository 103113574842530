import { Directive, AfterViewInit, NgZone, Output, EventEmitter, Inject, ElementRef, Injectable } from '@angular/core';
import { injectDestroy } from 'ngxtension/inject-destroy';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ResponsiveService } from '@cue/admin-core';

@Injectable()
@Directive({
  selector: '[appCloseHamburgerDropdown]',
  standalone: true,
})
export class CloseHamburgerDropdownDirective implements AfterViewInit {
  @Output() appClickOutside = new EventEmitter<boolean>();
  private readonly terminator = injectDestroy();
  private appClickOutsideElement: ElementRef<HTMLElement>;

  constructor(
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private responsiveService: ResponsiveService,
  ) {}

  ngAfterViewInit(): void {
    this.appClickOutsideElement = this.elementRef;
    this.zone.runOutsideAngular(() => {
      fromEvent(this.document, 'click')
        .pipe(
          takeUntil(this.terminator),
          filter(() => this.responsiveService.showDropdown$.value),
          filter((event: MouseEvent) => this.isClickedOutside(event)),
        )
        .subscribe(() => {
          this.zone.run(() => {
            this.appClickOutside.emit(true);
            this.responsiveService.toggleDropdown();
          });
        });
    });
  }

  private isClickedOutside(event: MouseEvent): boolean {
    const clickedElement = event.target as HTMLElement;
    const hamburgerBtn = document.getElementById('hamburgerBtn');
    const appCloseHamburgerDropdownDirective = this.appClickOutsideElement.nativeElement;

    return !hamburgerBtn.contains(clickedElement) && !appCloseHamburgerDropdownDirective.contains(clickedElement);
  }
}
