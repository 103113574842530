<ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'required'">
        {{ 'validation.required' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'min'">
        {{ 'validation.min' | translate: { min: value.min } }}
    </ng-container>

    <ng-container *ngSwitchCase="'max'">
        {{ 'validation.max' | translate: { max: value.max } }}
    </ng-container>

    <ng-container *ngSwitchCase="'pattern'">
        {{ 'validation.password' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'lessThanTo'">
        {{
            'validation.lessThanTo'
                | translate
                    : {
                          value: value
                      }
        }}
    </ng-container>

    <ng-container *ngSwitchCase="'biggerThanFrom'">
        {{
            'validation.biggerThanFrom'
                | translate
                    : {
                          value: value
                      }
        }}
    </ng-container>

    <ng-container *ngSwitchCase="'greaterThanError'">
        {{
            'validation.greaterThanError'
                | translate
                    : {
                          value: value
                      }
        }}
    </ng-container>

    <ng-container *ngSwitchCase="'invalidEmailPattern'">
        {{ 'validation.username' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'minlength'">
        {{
            'validation.minlength'
                | translate
                    : {
                          value: value.requiredLength
                      }
        }}
    </ng-container>

    <ng-container *ngSwitchCase="'maxlength'">
        {{
            'validation.maxlength'
                | translate
                    : {
                          value: value.requiredLength
                      }
        }}
    </ng-container>

    <ng-container *ngSwitchCase="'email'">
        {{ 'validation.email' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'warningEmailTimeNotShorter'">
        {{ 'validation.warningEmailTimeNotShorter' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'warningEmailAllDayTimeNotShorter'">
        {{ 'validation.warningEmailAllDayTimeNotShorter' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'valueIsUsed'">
        {{ 'validation.valueIsUsed' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'valueIsZero'">
        {{ 'validation.zero' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'patternError'">
        {{ 'validation.patternError' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'trimErrorLeading'">
        {{ 'validation.trimErrorLeading' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'trimErrorTrailing'">
        {{ 'validation.trimErrorTrailing' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'whiteSpaceError'">
        {{ 'validation.whiteSpaceError' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'autoCleaning'">
        {{ 'resources.automaticCleaningValidation' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="'timeRangeError'">
        {{ 'validation.min' | translate: { min: value.minValue.getHours() + ':' + value.minValue.getMinutes() } }}
    </ng-container>

    <ng-container *ngSwitchDefault>
        {{ 'general.error' | translate }}
        {{ key }}
    </ng-container>
</ng-container>
