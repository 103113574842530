import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LoaderComponent {
  @Input() colorText: boolean = false;
  @Input() colorPrimary: boolean = false;
  @Input() colorWhite: boolean = false;
}
