/* src/app/shared/components/localization-text-area/localization-text-area.component.scss */
:host .flag {
  max-width: 20px;
  margin-right: 0.25rem;
}
:host ::ng-deep .k-tabstrip {
  height: 140px;
}
:host ::ng-deep .k-tabstrip-content {
  box-shadow: none;
  border: none;
  padding: 1px 4px;
}
:host textarea {
  width: 100%;
  resize: vertical;
}
:host .tab-error {
  font-size: 15px;
  margin-left: 0.25rem;
}
/*# sourceMappingURL=localization-text-area.component.css.map */
