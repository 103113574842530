import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class SetRankService {
  abstract setRank(payload: any): Observable<any>;
  abstract getRank(): Observable<any>;
}
