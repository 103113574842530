import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input, TemplateRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ShowOption, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipsModule, InlineSVGModule, NgTemplateOutlet, NgIf],
  standalone: true,
})
export class IconTooltipComponent {
  @Input() position: 'top' | 'bottom' | 'left' | 'right' = 'right';
  @Input() tooltipTitle: string = null;
  @Input() tooltipContent: string | TemplateRef<unknown> = null;
  @Input() icon: IconProp = ['fas', 'question-circle'];
  @Input() svgIcon: string;
  @Input() showOn: ShowOption = 'click';
  @Input() ownIcon: string;
  @Input() ownIconSize: string = '1.6em';
  @Input() link: string;
  @Input() linkTarget: string = '_blank';
  @Input() linkDesc: string;
  @Input() type: 'info' | 'warning' = 'info';

  get hasTextContent(): boolean {
    if (this.tooltipContent && typeof this.tooltipContent === 'string') {
      return true;
    }
    return false;
  }

  get hasTemplateContent(): boolean {
    if (this.tooltipContent && typeof this.tooltipContent !== 'string') {
      return true;
    }
    return false;
  }

  get tooltipContentTemplate(): TemplateRef<unknown> {
    if (this.hasTemplateContent && typeof this.tooltipContent !== 'string') {
      return this.tooltipContent;
    }
    return null;
  }

  get hasContent(): boolean {
    return this.hasTextContent || this.hasTemplateContent;
  }

  openWithSessionStorage(link: string) {
    if (link.startsWith('/')) {
      window.open(link);
    } else {
      window.open(link, this.linkTarget, 'noopener, noreferrer');
    }
  }
}
