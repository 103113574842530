import Quill from 'quill';
const Link = Quill.import('formats/link');
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

export class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    const sanitizedUrl = super.sanitize(url);

    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol);
    });
    if (hasWhitelistedProtocol) return sanitizedUrl;

    return `https://${sanitizedUrl}`;
  }
}
