import { AddEditDigitalSignage } from '@cue/admin-digital-signage';
import { Observable, of, catchError, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  public entity = '/path';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getDetailById(id: string): Observable<any> {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/path/grid?&' + query, {}).pipe(
      map(
        (resp: any) =>
          resp.data.map((item) => ({
            ...item,
          }))[0],
      ),
    );
  }

  update(id: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.put(url, payload).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  delete(ids: string[]): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/delete';
    return this.http.post(url, ids).pipe(
      map((r) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  multiEdit(payload): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/multiEdit';
    return this.http.post(url, payload).pipe(
      map((r) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }
}
