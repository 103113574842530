import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private oauthTokenRefreshing = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private config: ConfigService,
    private translateService: TranslateService,
    private router: Router,
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {}

  private addTokenHeader(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  handle401(request: HttpRequest<any>, next: HttpHandler): any {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/account/refresh';
    const accessToken = sessionStorage.getItem('API_TOKEN');
    const refreshToken = sessionStorage.getItem('REFRESH_TOKEN');

    if (accessToken == null || refreshToken == null) {
      sessionStorage.removeItem('API_TOKEN');
      sessionStorage.removeItem('REFRESH_TOKEN');
      this.router.navigateByUrl('/account/login');
    }

    if (!this.oauthTokenRefreshing) {
      this.oauthTokenRefreshing = true;
      this.tokenSubject.next(null);
      return this.httpClient
        .post(url, {
          refreshToken: refreshToken,
          accessToken: accessToken,
        })
        .pipe(
          switchMap((response: any) => {
            if (response.success) {
              sessionStorage.setItem('API_TOKEN', response.data.token);
              sessionStorage.setItem('REFRESH_TOKEN', response.data.refreshToken);
              this.oauthTokenRefreshing = false;
              this.tokenSubject.next(response.data.token);
              const authReq = this.addTokenHeader(request, response.data.token);
              return next.handle(authReq).pipe(
                catchError((err: HttpErrorResponse) => {
                  if (err.status == 401) {
                    sessionStorage.removeItem('API_TOKEN');
                    sessionStorage.removeItem('REFRESH_TOKEN');
                    this.router.navigateByUrl('/account/login');
                  }
                  return throwError(err);
                }),
              );
            } else {
              this.oauthTokenRefreshing = false;
              sessionStorage.removeItem('API_TOKEN');
              sessionStorage.removeItem('REFRESH_TOKEN');
              this.router.navigateByUrl('/account/login');
              return throwError('Cannot refresh token');
            }
          }),
        );
    } else {
      return this.tokenSubject.pipe(
        filter((x) => x !== null),
        take(1),
        switchMap((token) => {
          const authReq = this.addTokenHeader(request, token);
          return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status == 401) {
                sessionStorage.removeItem('API_TOKEN');
                sessionStorage.removeItem('REFRESH_TOKEN');
                this.router.navigateByUrl('/account/login');
              }
              return throwError(err);
            }),
          );
        }),
      );
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (
      this.config.value &&
      this.config.value.apiURL &&
      (request.url.startsWith(this.config.value.apiURL + CONSTANTS.api.prefix) || request.url.startsWith(this.config.value.calendarURL))
    ) {
      const token = sessionStorage.getItem('API_TOKEN');
      let lang = 'en';
      if (this.translateService && this.translateService.currentLang) {
        lang = this.translateService.currentLang;
      }
      request = request.clone({
        setHeaders: {
          CueStringLocale: lang,
          Authorization: 'Bearer ' + token,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((event: any) => {
        if (event instanceof HttpErrorResponse) {
          switch (event.status) {
            case 401: {
              if (request.url.search('account/me') === -1) {
                return this.handle401(request, next);
              }
              return throwError(event);
            }
            default: {
              return throwError(event);
            }
          }
        } else {
          return throwError(event);
        }
      }),
    );
  }
}
