import { Routes } from '@angular/router';
import { provideData } from '@cue/admin-data';
import { CONSTANTS } from '@cue/admin-constants';

export const languagesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/languages-layout/languages-layout.component').then((x) => x.LanguagesLayoutComponent),
    providers: [
      provideData([
        {
          dataType: CONSTANTS.entity.activeLanguage,
          loadAllUrl: CONSTANTS.entity.activeLanguage + CONSTANTS.api.load,
          importUrl: CONSTANTS.entity.activeLanguage + CONSTANTS.api.import,
          deleteUrl: CONSTANTS.entity.activeLanguage + CONSTANTS.api.delete,
        },
        {
          dataType: CONSTANTS.entity.language,
          loadAllUrl: CONSTANTS.entity.language + CONSTANTS.api.load,
        },
      ]),
    ],
  },
];
