import { ControlContainer } from '@angular/forms';

export class FormErrorHandler {
  constructor(public controlContainer: ControlContainer) {}
  getErrors(name: string) {
    const group = this.controlContainer.control;
    const control = group.get(name);

    return control.touched || control.dirty ? control.errors : null;
  }
}
