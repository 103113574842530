import { Action } from '@ngrx/store';
import { ServersPageModel } from '../models';

export enum ServersActionTypes {
  Load = '[Servers] Load',
  LoadDone = '[Servers] LoadDone',
  LoadFailed = '[Servers] LoadFailed',
}

export class ServersLoadAction implements Action {
  readonly type = ServersActionTypes.Load;
}

export class ServersLoadDoneAction implements Action {
  readonly type = ServersActionTypes.LoadDone;
  constructor(public data: ServersPageModel) {}
}

export class ServersLoadFailedAction implements Action {
  readonly type = ServersActionTypes.LoadFailed;
  constructor(public data: ServersPageModel) {}
}
