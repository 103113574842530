import { Directive, Renderer2, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: 'img[imageError]',
  standalone: true,
})
export class ImageErrorDirective implements OnInit, OnDestroy {
  private onErrorSrc = 'assets/img/placeholder.jpg';
  unlisten?: () => void;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.unlisten = this.renderer.listen(this.el.nativeElement, 'error', () => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.onErrorSrc);
      });
    });
  }

  ngOnDestroy() {
    this.unlisten?.();
  }
}
