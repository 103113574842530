import { Action } from '@ngrx/store';
import { AppModel } from '../models';

export enum AppActionTypes {
  Language = '[App] Language',
}

export class AppLanguageAction implements Action {
  readonly type = AppActionTypes.Language;
  constructor(public data: string) {}
}
