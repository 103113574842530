import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileLibraryImage } from '@cue/admin-shared';

import { map, catchError, Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { TranslateService } from '@ngx-translate/core';
import { AreaSettings } from '../../pages/areas/models';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  public entity = CONSTANTS.entity.area;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  loadData(includeNavigationData = false): Observable<any> {
    let payload = {
      area: true,
      resource: true,
      navigation: includeNavigationData == true ? true : undefined,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  patchNavigation(pairs: { areaId: number; navigationData: string }[]): Observable<any> {
    return this.postAction(CONSTANTS.api.patchNavigation, pairs);
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  loadById(id: any): Observable<any> {
    return this.getAction(this.entity + '/' + id + CONSTANTS.api.load);
  }

  updateFloorplanData(
    id: number,
    data: {
      scale: number;
      imageLocalization: { languageCode: string; imageId: string; newImage: FileLibraryImage }[];
      zoomPOI: boolean;
      POISize: number;
    },
  ): Observable<any> {
    const url = `${this.configService.value.apiURL}` + '/api/area/' + id + '/floorplan/edit';
    return this.http.post<any>(url, { data }).pipe(
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Cannot upload new floorplan to the area.',
        });
      }),
    );
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  updateAreaRank(data: { id: number; childrenIds: number[] }): Observable<unknown> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/updateAreaRank';
    return this.http.post(url, data).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  saveSettings(payload: AreaSettings): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/saveSettings';
    return this.http.post(url, payload).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: true,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
