import { Injectable } from '@angular/core';
import { loadProjectInstances, projectInstancesLoaded, projectSelected } from './projects.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProjectService } from '@cue/admin-core';

@Injectable()
export class ProjectsEffects {
  selectProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelected),
      map((p) =>
        loadProjectInstances({
          projectId: p.projectId,
        }),
      ),
    ),
  );

  loadProjectInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectInstances),
      filter((action) => !!action.projectId),
      switchMap((action) => this.projectsService.getProjectData(action.projectId)),
      map((response) =>
        projectInstancesLoaded({
          instances: response.data.instances,
          permissions: response.data.permissions,
        }),
      ),
      catchError((_) => of(projectInstancesLoaded({ instances: [], permissions: [] }))),
    ),
  );

  constructor(
    private projectsService: ProjectService,
    private actions$: Actions,
  ) {}
}
