import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsTemplateService {
  public entity = CONSTANTS.entity.analyticsTemplate;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  generateToken(): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.generateToken, {});
  }

  getSettings() {
    return this.getAction(this.entity + '/settings');
  }

  setSettings(payload: any) {
    return this.http.post<any>(`${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/settings', payload);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
