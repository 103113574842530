import { FileLibraryImage, FileLibraryImageDropdown } from '@cue/admin-shared';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@cue/admin-core';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class FileLibraryService {
  public entity = CONSTANTS.entity.file;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getImagesByIds(ids: string[]): Observable<{
    data: FileLibraryImageDropdown[];
    success: boolean;
    errorCode: string;
    errorDescription: string;
  }> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/images';
    return ids.length <= 40
      ? this.http.get<{
          data: FileLibraryImageDropdown[];
          success: boolean;
          errorCode: string;
          errorDescription: string;
        }>(url + '?ids=' + ids.join('&ids='))
      : this.http.post<{
          data: FileLibraryImageDropdown[];
          success: boolean;
          errorCode: string;
          errorDescription: string;
        }>(url, ids);
  }

  getImagesFilterable(
    name: string,
    take: number,
  ): Observable<{
    data: FileLibraryImageDropdown[];
    success: boolean;
    errorCode: string;
    errorDescription: string;
  }> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/images?name=' + name + '&take=' + take;
    return this.http.get<{
      data: FileLibraryImageDropdown[];
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url);
  }

  getImages(): Observable<{
    data: FileLibraryImageDropdown[];
    success: boolean;
    errorCode: string;
    errorDescription: string;
  }> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/images';
    return this.http.get<{
      data: FileLibraryImageDropdown[];
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url);
  }

  getImageForDownload(id: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/imageForDownload/' + id;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  imagesForPrint(ids: string[]): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/imagesForPrint';
    return this.http.post(url, ids).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }
}
