/* src/app/shared/components/icon-tooltip/icon-tooltip.component.scss */
:host {
  display: inline-block;
}
::ng-deep .text-center .icon-tooltip .k-tooltip-title,
::ng-deep .text-center .icon-tooltip .k-tooltip-content {
  text-align: center;
}
.window-open {
  cursor: pointer;
  text-decoration: underline;
}
::ng-deep .icon-tooltip {
  max-width: 550px;
  z-index: 1000000;
}
::ng-deep .icon-tooltip .k-callout {
  color: #000;
}
::ng-deep .icon-tooltip .k-popup {
  min-width: unset !important;
  border-radius: 3px;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip {
  border-radius: 3px;
  background: #151515;
  padding: 10px;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content {
  font-size: 12px;
  color: white;
  white-space: pre-wrap;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title a,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content a {
  color: #e82d2d;
  text-decoration: underline;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title ul,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title li,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title p,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content ul,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content li,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content p {
  color: white;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title ul,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content ul {
  list-style-type: circle;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title li:hover,
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-content li:hover {
  background: transparent;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-tooltip-title {
  font-weight: bold;
  margin-bottom: 5px;
}
::ng-deep .icon-tooltip .k-popup .k-tooltip .k-callout {
  color: #151515;
}
fa-icon {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
}
img {
  cursor: pointer;
}
.svg-icon {
  cursor: pointer;
}
.svg-icon.info ::ng-deep svg path {
  fill: #404040 !important;
  transition: all 0.25s ease;
}
.svg-icon.info:hover ::ng-deep svg path {
  fill: black !important;
}
.svg-icon.warning ::ng-deep svg path {
  fill: rgb(226, 158, 10) !important;
  transition: all 0.25s ease;
}
.svg-icon.warning:hover ::ng-deep svg path {
  fill: #f9cf73 !important;
}
.svg-icon.error ::ng-deep svg path {
  fill: rgb(212, 38, 8) !important;
  transition: all 0.25s ease;
}
.svg-icon.error:hover ::ng-deep svg path {
  fill: #f97962 !important;
}
/*# sourceMappingURL=icon-tooltip.component.css.map */
