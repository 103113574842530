import { createAction, props } from '@ngrx/store';

// Add
export const AddDataAction = createAction('[Data service] Add data', props<{ dataType: string; payload: any; commandId: string }>());

export const AddDataSuccessAction = createAction(
  '[Data service] Add data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean; data: any }>(),
);

export const AddDataFailedAction = createAction(
  '[Data service] Add data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// Import
export const ImportDataAction = createAction('[Data service] Import data', props<{ dataType: string; payload: any; commandId: string }>());

export const ImportDataSuccessAction = createAction(
  '[Data service] Import data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const ImportDataFailedAction = createAction(
  '[Data service] Import data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// Edit
export const EditDataAction = createAction(
  '[Data service] Edit data',
  props<{ id: string; dataType: string; payload: any; commandId: string }>(),
);

export const EditDataSuccessAction = createAction(
  '[Data service] Edit data success',
  props<{ id: string; dataType: string; commandId: string; success: boolean; data: any }>(),
);

export const EditDataFailedAction = createAction(
  '[Data service] Edit data failed',
  props<{ id: string; dataType: string; error: string; commandId: string; success: boolean }>(),
);

// Delete
export const DeleteDataAction = createAction('[Data service] Delete data', props<{ dataType: string; payload: any; commandId: string }>());

export const DeleteDataSuccessAction = createAction(
  '[Data service] Delete data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const DeleteDataFailedAction = createAction(
  '[Data service] Delete data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// LOAD ALL
export const LoadAllDataAction = createAction('[Data service] Load all data', props<{ dataType: string }>());

export const AllDataLoadedErrorAction = createAction('[Data service] Load all data error', props<{ dataType: string; error: string }>());

export const AllDataLoadedSuccessAction = createAction('[Data service] Load all data success', props<{ dataType: string; data: any[] }>());

// LOAD BY ID
export const LoadDataByIdAction = createAction('[Data service] Load data by id', props<{ dataType: string; id: string }>());

export const DataByIdLoadedErrorAction = createAction(
  '[Data service] Data by id error',
  props<{ dataType: string; id: string; error: string }>(),
);

export const DataByIdLoadedSuccessAction = createAction(
  '[Data service] Data by id success',
  props<{ dataType: string; id: string; data: any }>(),
);

/* ***** Active Directory ***** */

// Import
export const ImportADDataAction = createAction(
  '[Data service] ImportAD data',
  props<{ dataType: string; payload: any; commandId: string }>(),
);

export const ImportADDataSuccessAction = createAction(
  '[Data service] ImportAD data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const ImportADDataFailedAction = createAction(
  '[Data service] ImportAD data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// Refresh
export const RefreshADDataAction = createAction(
  '[Data service] RefreshAD data',
  props<{ dataType: string; payload: any; commandId: string }>(),
);

export const RefreshADDataSuccessAction = createAction(
  '[Data service] RefreshAD data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const RefreshADDataFailedAction = createAction(
  '[Data service] RefreshAD data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

/* ***** Specialni ***** */

// Import users
export const ImportUsersDataAction = createAction(
  '[Data service] ImportUsers data',
  props<{ id: string; dataType: string; payload: any; commandId: string }>(),
);

export const ImportUsersDataSuccessAction = createAction(
  '[Data service] ImportUsers data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const ImportUsersDataFailedAction = createAction(
  '[Data service] ImportUsers data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// Delete users
export const DeleteUsersDataAction = createAction(
  '[Data service] DeleteUsers data',
  props<{ id: string; dataType: string; payload: any; commandId: string }>(),
);

export const DeleteUsersDataSuccessAction = createAction(
  '[Data service] DeleteUsers data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const DeleteUsersDataFailedAction = createAction(
  '[Data service] DeleteUsers data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// SendPin
export const SendPinDataAction = createAction(
  '[Data service] SendPin data',
  props<{ dataType: string; payload: any; commandId: string }>(),
);

export const SendPinDataSuccessAction = createAction(
  '[Data service] SendPin data success',
  props<{ dataType: string; id: string; commandId: string; success: boolean }>(),
);

export const SendPinDataFailedAction = createAction(
  '[Data service] SendPin data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);

// AccountEdit
export const AccountEditDataAction = createAction(
  '[Data service] AccountEdit data',
  props<{ dataType: string; payload: any; commandId: string }>(),
);

export const AccountEditSuccessAction = createAction(
  '[Data service] AccountEdit data success',
  props<{ dataType: string; commandId: string; success: boolean }>(),
);

export const AccountEditDataFailedAction = createAction(
  '[Data service] AccountEdit data failed',
  props<{ dataType: string; error: string; commandId: string; success: boolean }>(),
);
