/* src/app/shared/components/connection-agent-device-code-flow/connection-agent-device-code-flow.component.scss */
.infoDiv {
  margin-top: 10px;
}
.infoLabel {
  font-weight: 500;
}
.infoValue {
  font-weight: normal;
}
.item-error {
  color: #a52a2a;
  font-weight: bold;
}
.item-success {
  color: #009200;
  font-weight: bold;
}
.assist-callback-url {
  margin-top: 30px;
}
.assist-callback-url mat-icon {
  margin-left: 5px;
  color: #4e1289;
  cursor: pointer;
}
.assist-callback-url mat-icon:hover {
  transform: scale(1.3);
}
/*# sourceMappingURL=connection-agent-device-code-flow.component.css.map */
