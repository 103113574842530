import { NgForOf, UpperCasePipe } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UpperCasePipe, NgForOf],
  standalone: true,
})
export class LangComponent {
  constructor(public translate: TranslateService) {
    if (!localStorage.getItem('currentLanguage')) {
      const browserLang = translate.getBrowserLang();
      var newLang = browserLang.match(/en|cs/) ? browserLang : 'en';
      localStorage.setItem('currentLanguage', newLang);
    }
    translate.use(localStorage.getItem('currentLanguage'));
    translate.currentLang = localStorage.getItem('currentLanguage');
  }

  changeLang($e) {
    localStorage.setItem('currentLanguage', $e);
    this.translate.use($e);
    this.translate.currentLang = $e;

    location.reload();
  }
}
