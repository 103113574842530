import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    private appNotificationService: AppNotificationService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  getTemplateSettings(): Observable<any> {
    return this.getAction(CONSTANTS.entity.template + '/templateSettings');
  }

  editTemplateSettings(payload: any): Observable<any> {
    return this.postAction(CONSTANTS.entity.template + '/editTemplateSettings', payload);
  }

  getTemplate(id: number): Observable<any> {
    return this.getAction(CONSTANTS.entity.template + '/' + id + '/load');
  }

  multiResetToDefault(payload: { id: number; subject: string; body: string }[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + CONSTANTS.entity.template + '/' + 'multiEdit';
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
