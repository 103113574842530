import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-indeterminate-dropdownlist',
  templateUrl: './indeterminate-dropdownlist.component.html',
  styleUrls: ['./indeterminate-dropdownlist.component.scss'],
  imports: [TranslateModule, DropDownListModule, ReactiveFormsModule, NgIf, NgTemplateOutlet],
  standalone: true,
})
export class IndeterminateDropdownlistComponent implements OnInit {
  @Input() cName: string;
  @Input() isMultiEdit: boolean;
  @Input() hasFormControl: boolean;
  @Input() label: string = null;
  @Input() cValue: boolean;
  @Input() isDisabled: boolean;
  @Input() withDefaultValue: boolean;
  @Input() labelDown = true;
  @Input() defaultValue = null;

  @Output() valueChange = new EventEmitter();

  public dropdownData: { value: boolean; name: string }[];
  parentForm: FormGroup;

  constructor(
    public controlContainer: ControlContainer,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control as FormGroup;
    this.dropdownData = [
      { value: true, name: this.translateService.instant('general.yes') },
      { value: false, name: this.translateService.instant('general.no') },
      {
        value: null,
        name: this.isMultiEdit
          ? this.translateService.instant('general.noChange')
          : this.defaultValue != null
            ? this.translateService.instant('general.default') + ' (' + this.defaultValue + ')'
            : this.translateService.instant('general.default'),
      },
    ];
  }
}
