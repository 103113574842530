import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CONSTANTS } from '@cue/admin-constants';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  standalone: true,
  imports: [DialogsModule, NgIf, LoaderComponent],
})
export class DialogWrapperComponent {
  public dialogWidth: number;

  @Input({ required: true }) title;
  @Input() activeButtonLabel = 'Save';
  @Input() cancelButtonLabel = 'Cancel';
  @Input() activeButtonDisabled = false;
  @Input() cancelButtonDisabled = false;
  @Input() activeButtonExist = true;
  @Input() cancelButtonExist = true;
  @Input() activeButtonLoading = false;

  @Output() activeDialog = new EventEmitter<Event>();
  @Output() cancelDialog = new EventEmitter<Event>();

  constructor() {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if (window.innerWidth < CONSTANTS.dialogWidth) {
      this.dialogWidth = window.innerWidth - CONSTANTS.dialogWidthGap;
    } else {
      this.dialogWidth = CONSTANTS.dialogWidth - CONSTANTS.dialogWidthGap;
    }
  }
}
