import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { of, Observable } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, tap } from 'rxjs/operators';

import { ConfigService } from '../services';

const post = 'POST';
const delayTime = 3000;

@Injectable()
export class DemoInterceptor implements HttpInterceptor {
  constructor(private config: ConfigService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let response;

    if (request.method != post) {
      return next.handle(request);
    }

    if (this.config.value && this.config.value.demo) {
      return of(null)
        .pipe(
          mergeMap(() => {
            if (response) {
              return of(response);
            } else {
              return next.handle(request);
            }
          }),
        )
        .pipe(materialize())
        .pipe(delay(delayTime))
        .pipe(dematerialize());
    } else {
      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          return event;
        }),
      );
    }
  }
}
