import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SchedulerComponent implements OnInit, AfterViewInit {
  private _data: any = [];

  private daysCodes: string[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  private daysList: string[] = this.daysCodes;

  schedulerInstance: any = null;

  @ViewChild('schedulerContainer', { read: ElementRef }) container: ElementRef;

  @Input() set data(value) {
    if (this._data != value) {
      this._data = value;
      if (this.schedulerInstance) {
        $(this.schedulerInstance).jqs('reset');
        $(this.schedulerInstance).jqs('import', value);
      }
    }
  }

  @Output() saveScheduler: EventEmitter<any> = new EventEmitter<any>();

  @Output() schedulerChanged: EventEmitter<any> = new EventEmitter<any>();

  listen() {
    if (this.schedulerInstance) {
      let data = $(this.schedulerInstance).jqs('export');
      this.schedulerChanged.emit(JSON.parse(data));
      this.saveScheduler.emit();
    }
  }

  ngAfterViewInit() {
    ($(this.container.nativeElement) as any).jqs({
      data: this._data,
      daysList: this.daysList,
      onInit: (instance) => {
        this.schedulerInstance = instance;
      },
    });
  }

  constructor(public translate: TranslateService) {
    this.daysList = [];
    this.daysCodes.forEach((x) => {
      this.translate.get('schedulerComponent.' + x).subscribe((res: string) => {
        this.daysList.push(res);
      });
    });
  }

  ngOnInit() {}
}
