import { AbstractControl, ValidatorFn } from '@angular/forms';

export function greaterThan(num: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value - num < 0.0000000001) {
      return {
        greaterThanError: num,
      };
    }
    return null;
  };
}
