import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ConfigService } from '@cue/admin-core';

@Directive({
  selector: '[appAnalyticsHide]',
  standalone: true,
})
export class AnalyticsDirective implements AfterViewInit {
  @Input() appAnalyticsHide;

  constructor(
    private configService: ConfigService,
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.appAnalyticsHide !== false) {
      if (!this.configService.value.analytics) {
        this.elementRef.nativeElement.style.setProperty('display', 'none', 'important');
      }
    }
  }
}
