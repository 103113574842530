import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-error-translator',
  templateUrl: './form-error-translator.component.html',
  styleUrls: ['./form-error-translator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgSwitch, NgSwitchCase, NgSwitchDefault],
  standalone: true,
})
export class FormErrorTranslatorComponent {
  constructor() {}

  @Input() key: string;

  @Input() value: any;
}
