import { ServersPageModel } from '../models';
import { ServersActionTypes } from '../actions/servers.actions';

const initialState: ServersPageModel = {
  state: {
    status: null,
    errorCode: null,
    loading: false,
    loaded: false,
    dialog: null,
    selection: null,
    waiting: false,
  },
  servers: null,
};

export function serversReducer(state: ServersPageModel = initialState, action: any): ServersPageModel {
  switch (action.type) {
    case ServersActionTypes.Load:
      return {
        ...state,
        state: {
          ...state.state,
          loading: true,
          loaded: false,
        },
      };
    case ServersActionTypes.LoadDone:
      return {
        state: {
          ...state.state,
          loading: false,
          loaded: true,
        },
        servers: action.data.servers,
      };
    case ServersActionTypes.LoadFailed:
      return {
        state: {
          ...state.state,
          errorCode: action.data.state.errorCode,
          loading: false,
          loaded: false,
        },
        servers: null,
      };
    default:
      return state;
  }
}
