/* src/app/shared/components/response-grid-dialog/response-grid-dialog.component.scss */
:host ::ng-deep .dialogGrid .k-grid-content {
  min-height: 42px;
}
:host ::ng-deep .dialogGrid .k-header.k-grid-toolbar {
  background-color: #ffffff;
  border-top-style: none;
  padding: 4px;
}
:host ::ng-deep .dialogGrid .k-grouping-header {
  background-color: #e0e0e0;
}
:host ::ng-deep .dialogGrid .k-grid-header {
  background-color: #e0e0e0;
}
:host ::ng-deep .dialogGrid .k-grid-pager {
  background-color: #e0e0e0;
  padding: 4px;
}
:host ::ng-deep .dialogGrid td {
  padding: 2px 4px;
}
:host ::ng-deep .dialogGrid th {
  padding: 6px 4px;
}
.errorColumn {
  color: #a52a2a;
}
.successColumn {
  color: #009200;
}
/*# sourceMappingURL=response-grid-dialog.component.css.map */
