import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { combineReducers, provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { DataEffects } from './effects/data.effects';
import { reducers } from './reducers/data.reducers';
import { DataModuleConfiguration } from './models';
import { CONFIGURATIONS } from './di/tokens';
import { DataService } from './services';

export function provideDataStores() {
  return [
    provideState({
      name: 'data',
      reducer: combineReducers(reducers),
    }),
    provideEffects([DataEffects]),
  ];
}

export function provideData(configurations: DataModuleConfiguration[]) {
  return [
    {
      provide: CONFIGURATIONS,
      multi: true,
      useValue: configurations,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        const cnfs = inject(CONFIGURATIONS) as unknown as DataModuleConfiguration[][];
        const service = inject(DataService);
        if (cnfs) {
          cnfs.forEach((x) => service.addConfigurations(x));
        }
      },
    },
  ];
}
