import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DesignDataRequest } from '@cue/admin-design';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public entity = CONSTANTS.entity.analyticsTemplate;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getDashboards() {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/dashboard';
    return this.http.get<any>(url);
  }

  createEventIndex() {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/createEventIndex';
    return this.http.get<any>(url);
  }

  createResourceIndexPast() {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/createResourceIndexPastData';
    return this.http.get<any>(url);
  }

  createResourceIndexFuture() {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/createResourceIndexFutureIndex';
    return this.http.get<any>(url);
  }

  createNavigationIndex() {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/createNavigationIndex';
    return this.http.get<any>(url);
  }

  navigationEnabled(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/navigationEnabled';
    return this.http.get<any>(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  getTimeZone(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/getKibanaTimeZone';
    return this.http.get<any>(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  setTimeZone(timeZone: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/analyticsBasicOverview/setKibanaTimeZone';
    return this.http.post(url, { timeZone }).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }
}
