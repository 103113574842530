import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidationService } from '@cue/admin-core';

export class UniqueNameValidator {
  static uniqueNameValidatorAsync(service: ValidationService, id: string | number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return service.isUniqueName(control.value, id).pipe(map((response) => (response.success ? null : { valueIsUsed: true })));
    };
  }

  static uniqueNameValidator(names): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let result: boolean = false;
      names.forEach((x) => {
        if (control.value && control.value.replace(/ /g, '').toLowerCase() == x.replace(/ /g, '').toLowerCase()) {
          result = true;
        }
      });
      return result ? { valueIsUsed: { value: control.value } } : null;
    };
  }

  static uniqueNameValidatorWithId(names: any[], id: number, fieldName: string, isNumberValue = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let result: boolean = false;
      if (control.value) {
        let controlValue = control.value;
        if (isNumberValue) {
          controlValue = parseInt(control.value);
          names.forEach((x) => {
            if (x.id != id && x[fieldName] && controlValue && controlValue == x[fieldName]) {
              result = true;
            }
          });
        } else {
          names.forEach((x) => {
            if (
              x.id != id &&
              x[fieldName] &&
              controlValue &&
              controlValue.replace(/ /g, '').toLowerCase() == x[fieldName].replace(/ /g, '').toLowerCase()
            ) {
              result = true;
            }
          });
        }
      } else {
        result = false;
      }
      return result ? { valueIsUsed: { value: control.value } } : null;
    };
  }
}
