/* src/app/shared/components/set-rank-dialog/set-rank-dialog.component.scss */
.sortable-item {
  margin: 2px;
  border-radius: 5px;
  padding: 0 20px;
  border: none;
  border-color: transparent !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #4e1289 !important;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease;
  height: 30px;
}
.sortable-item:hover {
  background: #410f72 !important;
  color: #ffffff !important;
}
.sortable-layer {
  width: 240px;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 5px;
}
/*# sourceMappingURL=set-rank-dialog.component.css.map */
