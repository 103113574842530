<form [formGroup]="qrCodeNameForm">
    <ng-container *ngFor="let qrCode of qrCodeList">
        <div class="grid-col">
            <div class="textbox-button">
                <div>
                    {{ qrCode.name }}<span *ngIf="qrCode.code">: {{ qrCode.code }}</span>
                    <!--    <ng-container *ngIf="qrCodeForEdit === qrCode.name">
          <input
            id="qrCodeNameEdit"
            formControlName="qrCodeName"
            type="text"
            class="k-textbox"
          />
        </ng-container>-->
                </div>
                <div class="k-d-flex">
                    <!-- <button
          *ngIf="qrCodeForEdit !== qrCode.name"
          kendoButton
          [primary]="true"
          (click)="onEdit(qrCode)"
          class="buttonIcon"
          [title]="'actions.edit' | translate"
          [disabled]="qrCodeForEdit"
        >
          <mat-icon svgIcon="icon_edit" class="icon"></mat-icon>
        </button>-->
                    <button
                        style="margin-right: 0.5rem"
                        kendoButton
                        (click)="navigateToQRCode(qrCode.id)"
                        class="buttonIcon"
                        [title]="'actions.delete' | translate"
                        [disabled]="qrCode.id === '00000000-0000-0000-0000-000000000000'"
                    >
                        <mat-icon svgIcon="icon_edit" class="icon"></mat-icon>
                    </button>
                    <button
                        kendoButton
                        (click)="delete.emit({ id: qrCode.id, name: qrCode.name, code: qrCode.code })"
                        class="buttonIcon"
                        [title]="'actions.delete' | translate"
                    >
                        <mat-icon svgIcon="icon_delete" class="icon"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="grid">
        <div class="grid-col">
            <div class="form-group">
                <label for="qrCodeName" class="k-form-field">
                    <span>{{ 'qrCode.newQrCodeName' | translate }}</span>
                </label>
                <div class="textbox-button">
                    <input id="qrCodeName" formControlName="qrCodeName" type="text" class="k-textbox" />
                    <!--errors-->
                    <div
                        *ngIf="
                            isInvalid(qrCodeNameForm.get('qrCodeName')) &&
                            (qrCodeNameForm.get('qrCodeName').hasError('required') ||
                                qrCodeNameForm.get('qrCodeName').hasError('valueIsUsed'))
                        "
                        class="error"
                    >
                        <ng-container *ngIf="qrCodeNameForm.get('qrCodeName').hasError('required')">
                            {{ 'validation.required' | translate }}
                        </ng-container>
                        <ng-container *ngIf="qrCodeNameForm.get('qrCodeName').hasError('valueIsUsed')">
                            {{ 'validation.valueIsUsed' | translate }}
                        </ng-container>
                    </div>

                    <button
                        kendoButton
                        (click)="onAdd('00000000-0000-0000-0000-000000000000', qrCodeNameForm.get('qrCodeName').value)"
                        class="buttonIcon"
                        [title]="'actions.add' | translate"
                    >
                        <mat-icon svgIcon="icon_add"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
