/**-----------------------------------------------------------------------------------------
* Copyright © 2023 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, HostBinding, Input, Component, isDevMode, Injectable, InjectionToken, Optional, Inject, NgModule } from '@angular/core';
import { isDocumentAvailable } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import * as i1 from '@angular/platform-browser';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-icons',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1703060475,
    version: '14.3.0',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

const sizeClasses = {
    'default': 'k-icon',
    'xsmall': 'k-icon-xs',
    'small': 'k-icon-sm',
    'medium': 'k-icon-md',
    'large': 'k-icon-lg',
    'xlarge': 'k-icon-xl',
    'xxlarge': 'k-icon-xxl',
    'xxxlarge': 'k-icon-xxxl'
};
/**
 * @hidden
 */
class IconBaseDirective {
    constructor(element, renderer) {
        this.element = element;
        this.renderer = renderer;
        validatePackage(packageMetadata);
    }
    get horizontalFlip() {
        return this.flip === 'horizontal' || this.flip === 'both';
    }
    get verticalFlip() {
        return this.flip === 'vertical' || this.flip === 'both';
    }
    /**
     * Specifies the theme color for the Icon.
     *
     * The possible values are:
     * * `inherit` (Default)&mdash;Applies coloring based on the current color.
     * * `primary` &mdash;Applies coloring based on primary theme color.
     * * `secondary`&mdash;Applies coloring based on secondary theme color.
     * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
     * * `info`&mdash;Applies coloring based on info theme color.
     * * `success`&mdash; Applies coloring based on success theme color.
     * * `warning`&mdash; Applies coloring based on warning theme color.
     * * `error`&mdash; Applies coloring based on error theme color.
     * * `dark`&mdash; Applies coloring based on dark theme color.
     * * `light`&mdash; Applies coloring based on light theme color.
     * * `inverse`&mdash; Applies coloring based on inverse theme color.
     *
     */
    set themeColor(themeColor) {
        const element = this.element.nativeElement;
        if (this._themeColor) {
            this.renderer.removeClass(element, `!k-color-${this._themeColor}`);
        }
        this._themeColor = themeColor;
        // needed to ensure the theme color will be always applied; e.g. the class .k-button-icon, specific
        // for icon buttons applies "color: inherit" which overwrites the theme color
        if (themeColor) {
            this.renderer.addClass(element, `!k-color-${themeColor}`);
        }
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the size of the Icon.
     *
     * The possible values are:
     * * `default` (Default) (Font-size: 16px; Width: 16px; Height: 16px)
     * * `xsmall` (Font-size: 12px; Width: 12px; Height: 12px;)
     * * `small` (Font-size: 14px; Width: 14px; Height: 14px;)
     * * `medium` (Font-size: 16px; Width: 16px; Height: 16px;)
     * * `large` (Font-size: 20px; Width: 20px; Height: 20px;)
     * * `xlarge` (Font-size: 24px; Width: 24px; Height: 24px;)
     * * `xxlarge` (Font-size: 32px; Width: 32px; Height: 32px;)
     * * `xxxlarge` (Font-size: 48px; Width: 48px; Height: 48px;)
     *
     */
    set size(size) {
        const currentClass = sizeClasses[this.size];
        const newClass = sizeClasses[size];
        const element = this.element.nativeElement;
        this.renderer.removeClass(element, currentClass);
        if (size && size !== 'default') {
            this.renderer.addClass(element, newClass);
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
}
IconBaseDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconBaseDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }], target: i0.ɵɵFactoryTarget.Directive });
IconBaseDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: IconBaseDirective, selector: "[kendoIconBase]", inputs: { flip: "flip", themeColor: "themeColor", size: "size" }, host: { properties: { "class.k-flip-h": "this.horizontalFlip", "class.k-flip-v": "this.verticalFlip" } }, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconBaseDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoIconBase]'
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }]; }, propDecorators: { horizontalFlip: [{
                type: HostBinding,
                args: ['class.k-flip-h']
            }], verticalFlip: [{
                type: HostBinding,
                args: ['class.k-flip-v']
            }], flip: [{
                type: Input
            }], themeColor: [{
                type: Input
            }], size: [{
                type: Input
            }] } });

/**
 * Represents the Kendo UI Icon component for Angular.
 */
class IconComponent extends IconBaseDirective {
    constructor(element, renderer) {
        super(element, renderer);
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
        this.hostAriaHidden = true;
    }
    /**
     * Defines the name for an existing icon in a Kendo UI theme, which will be rendered.
     * All [Kendo UI Icons](slug:icon_list) are supported.
     */
    set name(name) {
        if (isDocumentAvailable()) {
            const element = this.element.nativeElement;
            this.renderer.removeClass(element, `k-i-${this.name}`);
            this.renderer.addClass(element, `k-i-${name}`);
        }
        this._name = name;
    }
    get name() {
        return this._name;
    }
}
IconComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconComponent, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }], target: i0.ɵɵFactoryTarget.Component });
IconComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: IconComponent, selector: "kendo-icon", inputs: { name: "name" }, host: { properties: { "class.k-icon": "this.hostClass", "class.k-font-icon": "this.hostClass", "attr.aria-hidden": "this.hostAriaHidden" } }, exportAs: ["kendoIcon"], usesInheritance: true, ngImport: i0, template: '', isInline: true });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconComponent, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoIcon',
                    selector: 'kendo-icon',
                    template: ''
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }]; }, propDecorators: { hostClass: [{
                type: HostBinding,
                args: ['class.k-icon']
            }, {
                type: HostBinding,
                args: ['class.k-font-icon']
            }], hostAriaHidden: [{
                type: HostBinding,
                args: ['attr.aria-hidden']
            }], name: [{
                type: Input
            }] } });

/**
 * Represents the Kendo UI SVG Icon component for Angular.
 */
class SVGIconComponent extends IconBaseDirective {
    constructor(domSanitizer, element, renderer) {
        super(element, renderer);
        this.domSanitizer = domSanitizer;
        this.element = element;
        this.renderer = renderer;
        this.hostClass = true;
        this.hostAriaHidden = true;
    }
    /**
     * Defines the SVG icon, which will be rendered. All [Kendo UI SVG Icons](slug:svgicon_list) are supported.
     */
    set icon(icon) {
        const element = this.element.nativeElement;
        const hasDocument = isDocumentAvailable();
        if (this._icon && this._icon.name && hasDocument) {
            this.renderer.removeClass(element, `k-svg-i-${this._icon.name}`);
        }
        this._icon = icon;
        if (hasDocument) {
            this.renderer.addClass(element, `k-svg-i-${this._icon.name}`);
        }
    }
    get icon() {
        return this._icon;
    }
    get content() {
        return this.domSanitizer.bypassSecurityTrustHtml(this.icon.content);
    }
    get visible() {
        return this.icon && isDocumentAvailable();
    }
    ngOnInit() {
        this.verifyIconProperty();
    }
    verifyIconProperty() {
        if (!isDevMode()) {
            return;
        }
        if (!this._icon) {
            throw new Error(`
                Invalid configuration.
                The input [icon] is required for the Kendo UI SVG Icon component for Angular.
                See https://www.telerik.com/kendo-angular-ui/components/icons/svg-icon
            `);
        }
    }
}
SVGIconComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconComponent, deps: [{ token: i1.DomSanitizer }, { token: i0.ElementRef }, { token: i0.Renderer2 }], target: i0.ɵɵFactoryTarget.Component });
SVGIconComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: SVGIconComponent, selector: "kendo-svg-icon, kendo-svgicon", inputs: { icon: "icon" }, host: { properties: { "class.k-svg-icon": "this.hostClass", "class.k-icon": "this.hostClass", "attr.aria-hidden": "this.hostAriaHidden" } }, exportAs: ["kendoSVGIcon"], usesInheritance: true, ngImport: i0, template: `
        <svg [style.pointerEvents]="'none'" *ngIf="visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             [attr.viewBox]="icon.viewBox" [innerHTML]="content">
        </svg>`, isInline: true, directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconComponent, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoSVGIcon',
                    selector: 'kendo-svg-icon, kendo-svgicon',
                    template: `
        <svg [style.pointerEvents]="'none'" *ngIf="visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             [attr.viewBox]="icon.viewBox" [innerHTML]="content">
        </svg>`
                }]
        }], ctorParameters: function () { return [{ type: i1.DomSanitizer }, { type: i0.ElementRef }, { type: i0.Renderer2 }]; }, propDecorators: { hostClass: [{
                type: HostBinding,
                args: ['class.k-svg-icon']
            }, {
                type: HostBinding,
                args: ['class.k-icon']
            }], hostAriaHidden: [{
                type: HostBinding,
                args: ['attr.aria-hidden']
            }], icon: [{
                type: Input
            }] } });

/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;

/**
 * A service that returns the current icon settings to be used in subscribers.
 * Use the public `notify` method to change the icon settings dynamically.
 */
class IconSettingsService {
    constructor() {
        /**
         * @hidden
         */
        this.changes = new Subject();
    }
    /**
     * Notifies subscribers that the icon settings were changed.
     *
     * @param iconSettings - (Optional) A new value for the [icon settings token]({% slug api_icons_icon_settings %}).
     */
    notify(iconSettings) {
        this.changes.next(iconSettings);
    }
    /**
     * Returns the [`SVGIcon`](slug:api_icons_svgicon) object for the provided key.
     * Override in a custom service to provide custom SVG icons.
     *
     * @hidden
     */
    getSvgIcon(_name) {
        return null;
    }
    /**
     * Returns the list of classes to be rendered on the host `SPAN` element of custom font icons.
     * Override in a custom service to provide classes for custom font icons.
     *
     * @hidden
     */
    getCustomFontIconClass(_key) {
        return null;
    }
}
IconSettingsService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconSettingsService, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
IconSettingsService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconSettingsService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconSettingsService, decorators: [{
            type: Injectable
        }] });

/**
 * A token that specifies the [IconSettings](slug:api_icons_iconsettings) of the Kendo UI for Angular components ([see example](slug:icon_settings#toc-icons-configuration)).
 *
 * ```ts
 * import { NgModule } from '@angular/core';
 *
 * @NgModule({
 * ...
 * providers: [{ provide: ICON_SETTINGS, useValue: { type: 'svg', size: 'xsmall' }}]
 * })
 * export class AppModule {}
 * ```
 *
 */
const ICON_SETTINGS = new InjectionToken('Kendo UI Icon-Settings token');

/**
 * @hidden
 */
class IconsService {
    constructor(_iconSettings, iconSettingsService) {
        this._iconSettings = _iconSettings;
        this.iconSettingsService = iconSettingsService;
        /**
         * Notifies subscribers of the initial icon settings, and upon each call to `notify`.
         */
        this.changes = new BehaviorSubject(this.iconSettings || { type: 'svg' });
        this.subs = new Subscription();
        if (iconSettingsService) {
            this.subs.add(iconSettingsService.changes
                .pipe(map(iconSettings => isPresent(iconSettings) ? iconSettings : this._iconSettings), tap(iconSettings => this._iconSettings = iconSettings))
                .subscribe(iconSettings => this.changes.next(iconSettings)));
        }
    }
    /**
     * @hidden
     */
    get iconSettings() {
        return this._iconSettings;
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    /**
     * Returns the [`SVGIcon`](slug:api_icons_svgicon) object for the provided key.
     * Override in a custom service to provide custom SVG icons.
     */
    getSvgIcon(name) {
        const customIcon = this.iconSettingsService && this.iconSettingsService.getSvgIcon(name);
        return customIcon;
    }
    /**
     * Returns the list of classes to be rendered on the host `SPAN` element of custom font icons.
     * Override in a custom service to provide classes for custom font icons.
     */
    getCustomFontIconClass(key) {
        const customClass = this.iconSettingsService && this.iconSettingsService.getCustomFontIconClass(key);
        return customClass;
    }
}
IconsService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsService, deps: [{ token: ICON_SETTINGS, optional: true }, { token: IconSettingsService, optional: true }], target: i0.ɵɵFactoryTarget.Injectable });
IconsService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [ICON_SETTINGS]
                }] }, { type: IconSettingsService, decorators: [{
                    type: Optional
                }] }]; } });

/**
 * @hidden
 */
class IconWrapperComponent {
    constructor(iconsService) {
        this.iconsService = iconsService;
        this.hostClass = true;
        /**
         * Provided by consuming components in case an additional k-specific class needs to be rendered
         * on the internal Icon component.
         */
        this.innerCssClass = '';
        /**
         * Provided by consuming components in case a custom font icon class is set
         * by the developer using the consuming component through its API.
         */
        this.customFontClass = '';
        this.subs = new Subscription();
        this.subs = iconsService.changes.subscribe(iconSettings => {
            this.iconSettings = iconSettings;
        });
    }
    get customClasses() {
        const classes = [this.customFontClass, this.innerCssClass, this.customFontIconClass].filter(cl => !!cl).join(' ');
        return classes;
    }
    get hasSvgIcon() {
        this.svgIcon = this.iconsService.getSvgIcon(this.name) || this.svgIcon;
        if (this.svgIcon) {
            return true;
        }
        if (isDevMode()) {
            console.groupCollapsed(`Kendo UI for Angular: Icon type is set to 'svg', but only font icon name is provided. The "${this.name}" font icon will be rendered instead.`);
            console.warn(`
Starting from v7.0.0 (October, 2023) of the Kendo Themes, the font icons will be removed from the theme.
* To render an SVG icon, update the component configuration by setting the "svgIcon" input to the respective icon definition.
* To continue to use font icons, install the "@progress/kendo-font-icons" package and import "@progress/kendo-font-icons/dist/index.css".

Please note, that font icons are not compatible with a strict Content Security Policy and require the use of the "unsafe-inline" override.

For further details, check the following documentation articles:
https://www.telerik.com/kendo-angular-ui/components/icons/icon-settings
https://www.telerik.com/kendo-angular-ui/components/styling/icons/
https://www.telerik.com/kendo-angular-ui/components/styling/svg-icons/
https://www.telerik.com/design-system/docs/foundation/iconography/font-icons/
https://www.telerik.com/design-system/docs/foundation/iconography/icon-list/
            `);
            console.groupEnd();
        }
        return false;
    }
    get customFontIconClass() {
        return this.iconsService.getCustomFontIconClass(this.name) || this.customFontClass;
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
IconWrapperComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconWrapperComponent, deps: [{ token: IconsService }], target: i0.ɵɵFactoryTarget.Component });
IconWrapperComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: IconWrapperComponent, selector: "kendo-icon-wrapper", inputs: { name: "name", svgIcon: "svgIcon", innerCssClass: "innerCssClass", customFontClass: "customFontClass", size: "size" }, host: { properties: { "class.k-icon-wrapper-host": "this.hostClass" } }, exportAs: ["kendoIconWrapper"], ngImport: i0, template: `
        <kendo-svgicon
            *ngIf="iconSettings?.type === 'svg' && hasSvgIcon; else font"
            [ngClass]="innerCssClass"
            [icon]="svgIcon"
            [size]="size || iconSettings?.size"
            [themeColor]="iconSettings?.themeColor"
            [flip]="iconSettings?.flip"></kendo-svgicon>
        <ng-template #font>
            <kendo-icon
                *ngIf="!customFontIconClass; else custom"
                [ngClass]="innerCssClass"
                [name]="name"
                [size]="size || iconSettings?.size"
                [themeColor]="iconSettings?.themeColor"
                [flip]="iconSettings?.flip"></kendo-icon>
            <ng-template #custom>
                <span [ngClass]="customClasses"></span>
            </ng-template>
        </ng-template>
    `, isInline: true, components: [{ type: SVGIconComponent, selector: "kendo-svg-icon, kendo-svgicon", inputs: ["icon"], exportAs: ["kendoSVGIcon"] }, { type: IconComponent, selector: "kendo-icon", inputs: ["name"], exportAs: ["kendoIcon"] }], directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconWrapperComponent, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoIconWrapper',
                    selector: 'kendo-icon-wrapper',
                    template: `
        <kendo-svgicon
            *ngIf="iconSettings?.type === 'svg' && hasSvgIcon; else font"
            [ngClass]="innerCssClass"
            [icon]="svgIcon"
            [size]="size || iconSettings?.size"
            [themeColor]="iconSettings?.themeColor"
            [flip]="iconSettings?.flip"></kendo-svgicon>
        <ng-template #font>
            <kendo-icon
                *ngIf="!customFontIconClass; else custom"
                [ngClass]="innerCssClass"
                [name]="name"
                [size]="size || iconSettings?.size"
                [themeColor]="iconSettings?.themeColor"
                [flip]="iconSettings?.flip"></kendo-icon>
            <ng-template #custom>
                <span [ngClass]="customClasses"></span>
            </ng-template>
        </ng-template>
    `
                }]
        }], ctorParameters: function () { return [{ type: IconsService }]; }, propDecorators: { hostClass: [{
                type: HostBinding,
                args: ['class.k-icon-wrapper-host']
            }], name: [{
                type: Input
            }], svgIcon: [{
                type: Input
            }], innerCssClass: [{
                type: Input
            }], customFontClass: [{
                type: Input
            }], size: [{
                type: Input
            }] } });

const exportedModules$2 = [
    IconComponent
];
const declarations$2 = [
    ...exportedModules$2
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icon module
 * import { IconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconModule], // import Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class IconModule {
}
IconModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
IconModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconModule, declarations: [IconComponent], imports: [CommonModule], exports: [IconComponent, CommonModule] });
IconModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconModule, imports: [[CommonModule], CommonModule] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [declarations$2],
                    exports: [exportedModules$2, CommonModule],
                    imports: [CommonModule]
                }]
        }] });

const exportedModules$1 = [
    SVGIconComponent
];
const declarations$1 = [
    ...exportedModules$1
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the SVG Icon component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the SVG Icon module
 * import { SVGIconModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, SVGIconModule], // import SVG Icon module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class SVGIconModule {
}
SVGIconModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
SVGIconModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconModule, declarations: [SVGIconComponent], imports: [CommonModule], exports: [SVGIconComponent] });
SVGIconModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconModule, imports: [[CommonModule]] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SVGIconModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [declarations$1],
                    exports: [exportedModules$1],
                    imports: [CommonModule]
                }]
        }] });

const exportedModules = [
    IconWrapperComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Icons components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Icons module
 * import { IconsModule } from '@progress/kendo-angular-icons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, IconsModule], // import Icons module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class IconsModule {
}
IconsModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
IconsModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsModule, declarations: [IconWrapperComponent], imports: [IconModule,
        SVGIconModule], exports: [IconModule,
        SVGIconModule, IconWrapperComponent] });
IconsModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsModule, providers: [IconsService], imports: [[
            IconModule,
            SVGIconModule
        ], IconModule,
        SVGIconModule] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: IconsModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations,
                    imports: [
                        IconModule,
                        SVGIconModule
                    ],
                    exports: [
                        IconModule,
                        SVGIconModule,
                        exportedModules
                    ],
                    providers: [IconsService]
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { ICON_SETTINGS, IconComponent, IconModule, IconSettingsService, IconWrapperComponent, IconsModule, IconsService, SVGIconComponent, SVGIconModule };

