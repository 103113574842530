<kendo-dialog [width]="dialogWidth" (close)="cancelDialog.emit()">
    <kendo-dialog-titlebar>
        {{ title }}
    </kendo-dialog-titlebar>
    <ng-content> </ng-content>
    <kendo-dialog-actions>
        <ng-content select="[buttons]"></ng-content>
        <button *ngIf="activeButtonExist" class="k-button k-primary" (click)="activeDialog.emit($event)" [disabled]="activeButtonDisabled">
            @if (activeButtonLoading) {
                <app-loader class="loaderIcon save-button-loader"></app-loader>
            }
            {{ activeButtonLabel }}
        </button>
        <button *ngIf="cancelButtonExist" class="k-button" (click)="cancelDialog.emit($event)" [disabled]="cancelButtonDisabled">
            {{ cancelButtonLabel }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
