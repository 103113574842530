/* src/app/shared/components/legend/legend.component.scss */
:host {
  position: fixed;
  bottom: 30px;
  transform: translateY(100%);
  right: 60px;
  transition: all 0.25s ease;
}
:host:hover {
  transform: translateY(0);
  bottom: 0;
}
.wrapper {
  background: #f5f5f5;
  border-radius: 5px 5px 0 0;
  padding: 8px 12px;
  display: inline-block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.wrapper .type {
  border-bottom: 1px solid #b9b9b9;
  margin-bottom: 10px;
  margin-top: 10px;
}
.wrapper .type:nth-of-type(1) {
  margin-top: 0;
}
.wrapper .type:nth-last-of-type(1) {
  margin-bottom: 0;
  border-bottom: none;
}
.wrapper h3 {
  margin-top: 0;
}
.wrapper p {
  margin: 0;
}
.wrapper .row-container {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper .row-container input {
  margin-right: 10px;
  pointer-events: none;
}
/*# sourceMappingURL=legend.component.css.map */
