/**-----------------------------------------------------------------------------------------
* Copyright © 2023 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { EventEmitter, TemplateRef, Component, Input, Output, HostBinding, InjectionToken, Injectable, Inject, Optional, Directive, forwardRef, ContentChildren, ViewChildren, ViewChild, isDevMode, Renderer2, Host, HostListener, ContentChild, NgModule } from '@angular/core';
import * as i2$1 from '@angular/animations';
import { style, animate, keyframes, trigger, state, transition } from '@angular/animations';
import { of, Subscription, Subject, merge } from 'rxjs';
import { delay, takeUntil, take, filter, map, share, tap, switchMap } from 'rxjs/operators';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX, RTL, ComponentMessages } from '@progress/kendo-angular-l10n';
import { xIcon, windowMinimizeIcon, windowIcon, windowRestoreIcon } from '@progress/kendo-svg-icons';
import * as i2 from '@progress/kendo-angular-buttons';
import { Button, ButtonModule } from '@progress/kendo-angular-buttons';
import { validatePackage } from '@progress/kendo-licensing';
import * as i1$1 from '@progress/kendo-angular-common';
import { shouldShowValidationUI, isDocumentAvailable, focusableSelector, DraggableDirective, isChanged, WatermarkModule, DraggableModule } from '@progress/kendo-angular-common';
import { offset, scrollPosition, positionWithScroll, getDocumentElement, getWindowViewPort } from '@progress/kendo-popup-common';
import * as i3 from '@progress/kendo-angular-icons';
import { IconsModule } from '@progress/kendo-angular-icons';

/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const isTruthy = (value) => !!value;
const toClassList = (classNames) => String(classNames).trim().split(' ');
const focusableRegex = /^(?:a|input|select|textarea|button|object)$/i;
/**
 * @hidden
 */
var Keys;
(function (Keys) {
    Keys[Keys["esc"] = 27] = "esc";
    Keys[Keys["tab"] = 9] = "tab";
    Keys[Keys["enter"] = 13] = "enter";
    Keys[Keys["space"] = 32] = "space";
    Keys[Keys["ctrl"] = 17] = "ctrl";
    Keys[Keys["shift"] = 16] = "shift";
    Keys[Keys["left"] = 37] = "left";
    Keys[Keys["up"] = 38] = "up";
    Keys[Keys["right"] = 39] = "right";
    Keys[Keys["down"] = 40] = "down";
})(Keys || (Keys = {}));
/**
 * @hidden
 */
const DIALOG_ELEMENTS_HANDLING_ESC_KEY = 'k-dialog-wrapper k-actions k-dialog-titlebar-action';
/**
 * @hidden
 */
const DIALOG_ELEMENTS_HANDLING_ARROWS = 'k-actions';
/**
 * @hidden
 */
const WINDOW_CLASSES = 'k-window';
/**
 * @hidden
 */
const hasClasses = (element, classNames) => {
    const namesList = toClassList(classNames);
    return Boolean(toClassList(element.className).find((className) => namesList.indexOf(className) >= 0));
};
/**
 * @hidden
 */
const isVisible = (element) => {
    const rect = element.getBoundingClientRect();
    return !!(rect.width && rect.height) && window.getComputedStyle(element).visibility !== 'hidden';
};
/**
 * @hidden
 */
const isFocusable = (element, checkVisibility = true) => {
    if (element.tagName) {
        const tagName = element.tagName.toLowerCase();
        const tabIndex = element.getAttribute('tabIndex');
        const validTabIndex = tabIndex !== null && !isNaN(tabIndex) && tabIndex > -1;
        let focusable = false;
        if (focusableRegex.test(tagName)) {
            focusable = !element.disabled;
        }
        else {
            focusable = validTabIndex;
        }
        return focusable && (!checkVisibility || isVisible(element));
    }
    return false;
};
/**
 * @hidden
 */
const setHTMLAttributes = (attributes, renderer, element) => {
    for (const attribute in attributes) {
        if (attribute) {
            renderer.setAttribute(element, attribute, attributes[attribute]);
        }
    }
};
/**
 * Receives CSS class declarations either as an object, string or array and returns an array of the class names.
 *
 * @hidden
 */
const parseCSSClassNames = (value) => {
    if (isObject(value)) {
        return parseObjectClassNames(value);
    }
    if (isString(value)) {
        return parseStringClassNames(value);
    }
    if (Array.isArray(value)) {
        return parseArrayClassNames(value);
    }
};
const parseObjectClassNames = (value) => {
    const classes = [];
    Object.keys(value).forEach(className => {
        const currentClassName = splitStringToArray(className);
        if (value[className] && currentClassName[0]) {
            classes.push(...currentClassName);
        }
    });
    return classes;
};
const parseStringClassNames = (value) => {
    const classes = [];
    const classesArray = splitStringToArray(value);
    classesArray.forEach(className => {
        classes.push(className);
    });
    return classes;
};
const parseArrayClassNames = (value) => {
    const classes = [];
    value.forEach((className) => {
        const current = splitStringToArray(className);
        if (current[0]) {
            classes.push(...current);
        }
    });
    return classes;
};
/**
 * @hidden
 */
const preventDefault = ({ originalEvent: event }) => {
    event.stopPropagation();
    event.preventDefault();
};
/**
 * @hidden
 */
const isWindowAvailable = () => {
    return typeof window !== 'undefined';
};
/**
 * @hidden
 */
const preventOnDblClick = release => (mouseDown) => of(mouseDown).pipe(delay(150), takeUntil(release));
/**
 * @hidden
 */
const RESIZE_DIRECTIONS = ['n', 'e', 's', 'w', 'se', 'sw', 'ne', 'nw'];
/**
 * @hidden
 */
const OFFSET_STYLES = ['top', 'left', 'width', 'height'];
/**
 * @hidden
 */
const isString = (value) => value instanceof String || typeof value === 'string';
/**
 * @hidden
 */
const isObject = (value) => isPresent(value) && !Array.isArray(value) && typeof value === 'object';
/**
 * @hidden
 */
const isNumber = (value) => typeof value === 'number' && isFinite(value);
/**
 * @hidden
 */
const createValueWithUnit = (value) => value + (isNumber(value) ? 'px' : '');
/**
 * @hidden
 */
const splitStringToArray = (value) => value.trim().replace(/\s+/g, " ").split(' ');
/**
 * @hidden
 */
const findPrimaryButton = (buttons) => {
    for (let i = buttons.length - 1; i >= 0; i--) {
        const classList = buttons[i].classList;
        for (let j = 0; j < classList.length; j++) {
            if (classList[j].endsWith('-primary')) {
                return buttons[i];
            }
        }
    }
};

/**
 * Specifies the action buttons of the Dialog
 * ([see example]({% slug actionbuttons_dialog %})).
 */
class DialogActionsComponent {
    constructor(el) {
        this.el = el;
        /**
         * Specifies the possible layout of the action buttons.
         * @default 'stretched'
         */
        this.layout = 'stretched';
        /**
         * Fires when the user clicks an action button.
         */
        this.action = new EventEmitter();
        this.hostClasses = true;
    }
    /**
     * Allows the declarative specification of the actions.
     */
    set actions(value) {
        if (value instanceof TemplateRef) {
            this.actionsTemplate = value;
        }
        else if (Array.isArray(value)) {
            this.actionsArray = value;
        }
        else {
            throw new Error('"actions" must be either TemplateRef or DialogAction[] instance.');
        }
    }
    get startClassName() {
        return this.layout === 'start';
    }
    get centerClassName() {
        return this.layout === 'center';
    }
    get endClassName() {
        return this.layout === 'end';
    }
    get stretchedClassName() {
        return this.layout === 'stretched';
    }
    /**
     * @hidden
     */
    onButtonClick(action, _e) {
        this.action.emit(action);
    }
    /**
     * @hidden
     */
    buttonClass(action) {
        let classes = ['k-button k-button-md k-rounded-md'];
        const fillMode = action.fillMode ? action.fillMode : 'solid';
        const themeColor = action.themeColor ? action.themeColor : 'base';
        const cssClasses = action.cssClass ? parseCSSClassNames(action.cssClass) : [];
        classes.push(`k-button-${fillMode} k-button-${fillMode}-${themeColor}`);
        if (cssClasses.length > 0) {
            classes = classes.concat(cssClasses);
        }
        return classes.join(' ');
    }
    /**
     * @hidden
     */
    isDivider(action) {
        return action === 'spacer';
    }
}
DialogActionsComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogActionsComponent, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Component });
DialogActionsComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: DialogActionsComponent, selector: "kendo-dialog-actions", inputs: { actions: "actions", layout: "layout" }, outputs: { action: "action" }, host: { properties: { "class.k-actions": "this.hostClasses", "class.k-actions-horizontal": "this.hostClasses", "class.k-window-actions": "this.hostClasses", "class.k-dialog-actions": "this.hostClasses", "class.k-actions-start": "this.startClassName", "class.k-actions-center": "this.centerClassName", "class.k-actions-end": "this.endClassName", "class.k-actions-stretched": "this.stretchedClassName" } }, ngImport: i0, template: `
        <ng-content *ngIf="!actions"></ng-content>
        <ng-container *ngIf="actionsArray; else actionTemplate">
            <ng-container *ngFor="let action of actionsArray">
                <ng-container *ngIf="isDivider(action); else defaultAction">
                    <span class="k-spacer"></span>
                </ng-container>
                <ng-template #defaultAction>
                    <button
                        type="button"
                        [ngClass]="buttonClass(action)"
                        (click)="onButtonClick(action, $event)"
                        [attr.aria-label]="action.text"
                    >
                        {{ action.text }}
                    </button>
                </ng-template>
            </ng-container>
        </ng-container>
        <ng-template #actionTemplate [ngTemplateOutlet]="actionsTemplate"></ng-template>
    `, isInline: true, directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgForOf, selector: "[ngFor][ngForOf]", inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }, { type: i4.NgTemplateOutlet, selector: "[ngTemplateOutlet]", inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogActionsComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'kendo-dialog-actions',
                    template: `
        <ng-content *ngIf="!actions"></ng-content>
        <ng-container *ngIf="actionsArray; else actionTemplate">
            <ng-container *ngFor="let action of actionsArray">
                <ng-container *ngIf="isDivider(action); else defaultAction">
                    <span class="k-spacer"></span>
                </ng-container>
                <ng-template #defaultAction>
                    <button
                        type="button"
                        [ngClass]="buttonClass(action)"
                        (click)="onButtonClick(action, $event)"
                        [attr.aria-label]="action.text"
                    >
                        {{ action.text }}
                    </button>
                </ng-template>
            </ng-container>
        </ng-container>
        <ng-template #actionTemplate [ngTemplateOutlet]="actionsTemplate"></ng-template>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }]; }, propDecorators: { actions: [{
                type: Input
            }], layout: [{
                type: Input
            }], action: [{
                type: Output
            }], hostClasses: [{
                type: HostBinding,
                args: ['class.k-actions']
            }, {
                type: HostBinding,
                args: ['class.k-actions-horizontal']
            }, {
                type: HostBinding,
                args: ['class.k-window-actions']
            }, {
                type: HostBinding,
                args: ['class.k-dialog-actions']
            }], startClassName: [{
                type: HostBinding,
                args: ['class.k-actions-start']
            }], centerClassName: [{
                type: HostBinding,
                args: ['class.k-actions-center']
            }], endClassName: [{
                type: HostBinding,
                args: ['class.k-actions-end']
            }], stretchedClassName: [{
                type: HostBinding,
                args: ['class.k-actions-stretched']
            }] } });

class PreventableEvent {
    /**
     * @hidden
     */
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * @hidden
 */
const DIALOG_LOCALIZATION_SERVICE = new InjectionToken('Dialog LocalizationService');

/**
 * @hidden
 */
class TitleBarLocalizationService extends LocalizationService {
    constructor(prefix, messageService, rtl, dialogLocalization) {
        super(prefix, messageService, rtl);
        this.dialogLocalization = dialogLocalization;
    }
    get(shortKey) {
        if (this.dialogLocalization) {
            return this.dialogLocalization.get(shortKey);
        }
        return super.get(shortKey);
    }
}
TitleBarLocalizationService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: TitleBarLocalizationService, deps: [{ token: L10N_PREFIX }, { token: i1.MessageService, optional: true }, { token: RTL, optional: true }, { token: DIALOG_LOCALIZATION_SERVICE, optional: true }], target: i0.ɵɵFactoryTarget.Injectable });
TitleBarLocalizationService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: TitleBarLocalizationService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: TitleBarLocalizationService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [L10N_PREFIX]
                }] }, { type: i1.MessageService, decorators: [{
                    type: Optional
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [RTL]
                }] }, { type: i1.LocalizationService, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [DIALOG_LOCALIZATION_SERVICE]
                }] }]; } });

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
Messages.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: Messages, deps: null, target: i0.ɵɵFactoryTarget.Directive });
Messages.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: Messages, selector: "kendo-dialog-messages-base", inputs: { closeTitle: "closeTitle", restoreTitle: "restoreTitle", maximizeTitle: "maximizeTitle", minimizeTitle: "minimizeTitle" }, usesInheritance: true, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: Messages, decorators: [{
            type: Directive,
            args: [{
                    // eslint-disable-next-line @angular-eslint/directive-selector
                    selector: 'kendo-dialog-messages-base'
                }]
        }], propDecorators: { closeTitle: [{
                type: Input
            }], restoreTitle: [{
                type: Input
            }], maximizeTitle: [{
                type: Input
            }], minimizeTitle: [{
                type: Input
            }] } });

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
}
LocalizedMessagesDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: LocalizedMessagesDirective, deps: [{ token: i1.LocalizationService }], target: i0.ɵɵFactoryTarget.Directive });
LocalizedMessagesDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: LocalizedMessagesDirective, selector: "\n    [kendoDialogLocalizedMessages],\n    [kendoWindowLocalizedMessages],\n    [kendoDialogTitleBarLocalizedMessages]\n  ", providers: [
        {
            provide: Messages,
            useExisting: forwardRef(() => LocalizedMessagesDirective)
        }
    ], usesInheritance: true, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: LocalizedMessagesDirective, decorators: [{
            type: Directive,
            args: [{
                    providers: [
                        {
                            provide: Messages,
                            useExisting: forwardRef(() => LocalizedMessagesDirective)
                        }
                    ],
                    selector: `
    [kendoDialogLocalizedMessages],
    [kendoWindowLocalizedMessages],
    [kendoDialogTitleBarLocalizedMessages]
  `
                }]
        }], ctorParameters: function () { return [{ type: i1.LocalizationService }]; } });

/**
 * Represents the [Kendo UI DialogTitleBar component for Angular]({% slug api_dialog_dialogtitlebarcomponent %}).
 *
 * It is used as part of the Dialog content when the component is created dynamically by using an [Angular service]({% slug service_dialog %}).
 */
class DialogTitleBarComponent {
    constructor(zone, hostElement, localizationService) {
        this.zone = zone;
        this.hostElement = hostElement;
        this.localizationService = localizationService;
        /**
         * Fires when the close button of the title-bar is clicked.
         */
        this.close = new EventEmitter();
        /**
         * @hidden
         */
        this.xIcon = xIcon;
    }
    get className() {
        return true;
    }
    get closeButtonTitle() {
        return this.closeTitle || this.localizationService.get('closeTitle');
    }
    ngAfterViewInit() {
        this.zone.onStable.pipe(take(1)).subscribe(() => {
            const element = this.hostElement.nativeElement.querySelector('.k-dialog-title');
            element.setAttribute('id', this.id);
        });
    }
    /**
     * @hidden
     */
    onCloseClick(e) {
        e.preventDefault();
        const eventArgs = new PreventableEvent();
        this.close.emit(eventArgs);
    }
}
DialogTitleBarComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogTitleBarComponent, deps: [{ token: i0.NgZone }, { token: i0.ElementRef }, { token: i1.LocalizationService, optional: true }], target: i0.ɵɵFactoryTarget.Component });
DialogTitleBarComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: DialogTitleBarComponent, selector: "kendo-dialog-titlebar", inputs: { id: "id", closeTitle: "closeTitle" }, outputs: { close: "close" }, host: { properties: { "class.k-window-titlebar": "this.className", "class.k-dialog-titlebar": "this.className" } }, providers: [
        TitleBarLocalizationService,
        {
            provide: LocalizationService,
            useExisting: TitleBarLocalizationService
        },
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.dialog'
        }
    ], ngImport: i0, template: `
         <ng-container
            kendoDialogTitleBarLocalizedMessages
            i18n-closeTitle="kendo.dialog.closeTitle|The title of the close button"
            closeTitle="Close"
        >
            <span class="k-window-title k-dialog-title">
                <ng-content></ng-content>
            </span>

            <div class="k-window-titlebar-actions k-dialog-titlebar-actions">
                <button
                    kendoButton
                    fillMode="flat"
                    role="button"
                    [attr.title]="closeButtonTitle"
                    [attr.aria-label]="closeButtonTitle"
                    icon="close"
                    [svgIcon]="xIcon"
                    class="k-window-titlebar-action k-dialog-titlebar-action"
                    (click)="onCloseClick($event)"
                >
                </button>
            </div>
        </ng-container>
    `, isInline: true, components: [{ type: i2.ButtonComponent, selector: "button[kendoButton], span[kendoButton], kendo-button", inputs: ["arrowIcon", "toggleable", "togglable", "selected", "tabIndex", "imageUrl", "iconClass", "icon", "disabled", "size", "rounded", "fillMode", "themeColor", "svgIcon", "role", "primary", "look"], outputs: ["selectedChange", "click"], exportAs: ["kendoButton"] }], directives: [{ type: LocalizedMessagesDirective, selector: "\n    [kendoDialogLocalizedMessages],\n    [kendoWindowLocalizedMessages],\n    [kendoDialogTitleBarLocalizedMessages]\n  " }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogTitleBarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'kendo-dialog-titlebar',
                    providers: [
                        TitleBarLocalizationService,
                        {
                            provide: LocalizationService,
                            useExisting: TitleBarLocalizationService
                        },
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.dialog'
                        }
                    ],
                    template: `
         <ng-container
            kendoDialogTitleBarLocalizedMessages
            i18n-closeTitle="kendo.dialog.closeTitle|The title of the close button"
            closeTitle="Close"
        >
            <span class="k-window-title k-dialog-title">
                <ng-content></ng-content>
            </span>

            <div class="k-window-titlebar-actions k-dialog-titlebar-actions">
                <button
                    kendoButton
                    fillMode="flat"
                    role="button"
                    [attr.title]="closeButtonTitle"
                    [attr.aria-label]="closeButtonTitle"
                    icon="close"
                    [svgIcon]="xIcon"
                    class="k-window-titlebar-action k-dialog-titlebar-action"
                    (click)="onCloseClick($event)"
                >
                </button>
            </div>
        </ng-container>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.NgZone }, { type: i0.ElementRef }, { type: i1.LocalizationService, decorators: [{
                    type: Optional
                }] }]; }, propDecorators: { close: [{
                type: Output
            }], id: [{
                type: Input
            }], closeTitle: [{
                type: Input
            }], className: [{
                type: HostBinding,
                args: ['class.k-window-titlebar']
            }, {
                type: HostBinding,
                args: ['class.k-dialog-titlebar']
            }] } });

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-dialog',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1703060636,
    version: '14.3.0',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Indicates that the **Close** button is clicked. Used when the results from
 * the Dialogs that are opened through `DialogService` are filtered
 * ([see example]({% slug api_dialog_dialogservice %}#toc-open)).
 */
class DialogCloseResult {
}

/**
 * @hidden
 */
function animations(duration, direction, animationType) {
    switch (animationType) {
        case 'slide': {
            const translate = direction === 'left' || direction === 'right' ? 'translateX' : 'translateY';
            const start = direction === 'right' || direction === 'down' ? -100 : 100;
            const end = 0;
            return [
                style({ transform: `${translate}(${start}%)` }),
                animate(`${duration}ms ease-in`, style({ transform: `${translate}(${end}%)` }))
            ];
        }
        case 'expand': {
            const scale = direction === 'up' || direction === 'down' ? 'scaleY' : 'scaleX';
            const startScale = 0;
            const endScale = 1;
            let origin;
            if (direction === 'down') {
                origin = 'top';
            }
            else if (direction === 'left') {
                origin = 'right';
            }
            else if (direction === 'right') {
                origin = 'left';
            }
            else {
                origin = 'bottom';
            }
            return [
                style({ transform: `${scale}(${startScale})`, transformOrigin: origin }),
                animate(`${duration}ms ease-in`, style({ transform: `${scale}(${endScale})` }))
            ];
        }
        case 'zoom': {
            const startZoom = 0;
            const endZoom = 1;
            return [
                animate(duration, keyframes([
                    style({ transform: `scale(${startZoom})` }),
                    style({ transform: `scale(${endZoom})` })
                ]))
            ];
        }
        case 'fade': {
            const startFade = 0;
            const endFade = 1;
            return [
                animate(duration, keyframes([
                    style({ opacity: `${startFade}` }),
                    style({ opacity: `${endFade}` })
                ]))
            ];
        }
        case 'translate':
            return [
                style({ transform: 'translate(0, -10%)' }),
                animate(`${duration}ms cubic-bezier(.2, 1, .2, 1)`)
            ];
        default:
            return [
                style({ transform: 'translate(0, -10%)' }),
                animate(`${duration}ms cubic-bezier(.2, 1, .2, 1)`)
            ];
    }
}

/**
 * @hidden
 */
const createPlayer = (builder, animation, animatedElement) => {
    const factory = builder.build(animation);
    let player = factory.create(animatedElement);
    player.onDone(() => {
        if (player) {
            player.destroy();
            player = null;
        }
    });
    return player;
};

/**
 * @hidden
 */
const animateContent = (animation, defAnimationConfig, animatedElement, builder) => {
    let animationConfig = defAnimationConfig;
    if (typeof animation !== 'boolean') {
        animationConfig = animation;
        animationConfig.duration = animationConfig.duration ? animationConfig.duration : defAnimationConfig.duration;
    }
    const animationSpecs = animations(animationConfig.duration, animationConfig.direction, animationConfig.type);
    const player = createPlayer(builder, animationSpecs, animatedElement);
    player.play();
};

const DEFAULT_ANIMATION_CONFIG = { duration: 300, type: 'translate' };
/**
 * Represents the [Kendo UI Dialog component for Angular]({% slug overview_dialog_dialogs %}).
 */
class DialogComponent {
    constructor(wrapper, renderer, localization, cdr, ngZone, builder) {
        this.wrapper = wrapper;
        this.renderer = renderer;
        this.cdr = cdr;
        this.ngZone = ngZone;
        this.builder = builder;
        /**
         * Specifies the layout of the action buttons in the Dialog.
         * This option is only applicable if the action buttons are specified through the `actions` options.
         *
         * @default 'stretched'
         */
        this.actionsLayout = 'stretched';
        /**
         * Configures the Dialog opening animation ([see example]({% slug animations_dialog %})).
         * By default the animation type is set to `translate` and its duration is `300ms`.
         *
         * @default true
         */
        this.animation = true;
        /**
         * @hidden
         */
        this.titleId = null;
        /**
         * @hidden
         */
        this.contentId = null;
        /**
         * @hidden
         */
        this.showLicenseWatermark = false;
        /**
         * Fires when the user clicks an action button of the Dialog.
         * The event is fired only when the action buttons are specified through the `actions` options.
         */
        this.action = new EventEmitter();
        /**
         * Fires when the user clicks the **Close** button of the Dialog or the **ESC** key.
         */
        this.close = new EventEmitter();
        this.tabIndex = 0;
        this._themeColor = null;
        this.subscriptions = [];
        this.domSubs = new Subscription();
        const isValid = validatePackage(packageMetadata);
        this.showLicenseWatermark = shouldShowValidationUI(isValid);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.subscriptions.push(localization.changes.subscribe(({ rtl }) => (this.direction = rtl ? 'rtl' : 'ltr')));
        this.titleId = this.generateTitleId();
        this.contentId = this.generateContentId();
    }
    /**
     * The Dialog allows you to specify predefined theme colors.
     * The theme color will be applied as a background and border color to the titlebar while also amending the text color accordingly.
     *
     * The possible values are:
     * * `primary`
     * * `dark`
     * * `light`
     */
    set themeColor(themeColor) {
        this.handleThemeColorClass(this.themeColor, themeColor);
        this._themeColor = themeColor;
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * @hidden
     */
    set htmlAttributes(attributes) {
        setHTMLAttributes(attributes, this.renderer, this.wrapper.nativeElement);
        const el = this.wrapper.nativeElement;
        const dir = el.getAttribute('dir');
        const tIndex = el.getAttribute('tabindex');
        if (this.direction !== dir && dir) {
            this.direction = dir;
        }
        if (this.tabIndex !== tIndex && tIndex) {
            this.tabIndex = tIndex;
        }
        this._htmlAttributes = attributes;
    }
    get htmlAttributes() {
        return this._htmlAttributes;
    }
    /**
     * @hidden
     */
    set cssClass(classes) {
        this.setServiceClasses(this._cssClass, classes);
        this._cssClass = classes;
    }
    get cssClass() {
        return this._cssClass;
    }
    get dir() {
        return this.direction;
    }
    ngAfterContentInit() {
        this.bubble('close', this.titlebarContent.first);
        this.renderer.setAttribute(this.wrapper.nativeElement.querySelector('.k-dialog'), 'aria-describedby', this.contentId);
        if (this.titlebarContent.first) {
            this.titlebarContent.first.id = this.titleId;
        }
        else {
            this.subscriptions.push(this.titlebarContent.changes.subscribe(() => {
                if (isPresent(this.titlebarContent.first)) {
                    this.titlebarContent.first.id = this.titleId;
                    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
                        this.bubble('close', this.titlebarContent.first);
                        this.renderer.setAttribute(this.wrapper.nativeElement.querySelector('.k-dialog'), 'aria-labelledby', this.titleId);
                    });
                }
            }));
        }
    }
    ngAfterViewInit() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.handleInitialFocus();
        this.bubble('close', this.titlebarView.first);
        this.bubble('action', this.actionsView);
        if (this.titlebarView.first || this.titlebarContent.first) {
            //Needed for Dialogs created via service
            this.renderer.setAttribute(this.wrapper.nativeElement.querySelector('.k-dialog'), 'aria-labelledby', this.titleId);
        }
        else {
            this.subscriptions.push(this.titlebarView.changes.subscribe(() => {
                if (isPresent(this.titlebarView.first)) {
                    this.titlebarView.first.id = this.titleId;
                    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
                        this.bubble('close', this.titlebarView.first);
                        this.renderer.setAttribute(this.wrapper.nativeElement.querySelector('.k-dialog'), 'aria-labelledby', this.titleId);
                    });
                }
            }));
        }
        this.initDomEvents();
        this.handleThemeColorClass(null, this.themeColor);
    }
    ngOnInit() {
        if (this.animation) {
            animateContent(this.animation, DEFAULT_ANIMATION_CONFIG, this.dialog.nativeElement, this.builder);
        }
        this.renderer.removeAttribute(this.wrapper.nativeElement, 'title');
        this.cdr.detectChanges();
    }
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
        if (this.domSubs) {
            this.domSubs.unsubscribe();
        }
    }
    /**
     * Focuses the wrapper of the Dialog component.
     */
    focus() {
        const wrapper = this.wrapper.nativeElement;
        if (isPresent(wrapper)) {
            wrapper.focus();
        }
    }
    initDomEvents() {
        if (!this.wrapper) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.domSubs.add(this.renderer.listen(this.wrapper.nativeElement, 'keydown', (ev) => {
                this.onKeyDown(ev);
            }));
        });
    }
    onKeyDown(event) {
        const target = event.target;
        const parent = target.parentElement;
        if (hasClasses(target, DIALOG_ELEMENTS_HANDLING_ESC_KEY) || hasClasses(parent, DIALOG_ELEMENTS_HANDLING_ESC_KEY)) {
            if (event.keyCode === Keys.esc) {
                this.ngZone.run(() => {
                    this.close.emit(new DialogCloseResult());
                });
            }
        }
        if (hasClasses(target, 'k-button') && hasClasses(parent, DIALOG_ELEMENTS_HANDLING_ARROWS) &&
            (event.keyCode === Keys.left || event.keyCode === Keys.right)) {
            this.ngZone.run(() => {
                this.handleActionButtonFocus(parent, event.keyCode);
            });
        }
        if (event.keyCode === Keys.tab) {
            this.ngZone.run(() => {
                this.keepFocusWithinComponent(target, event);
            });
        }
    }
    setServiceClasses(prevValue, value) {
        const el = this.wrapper.nativeElement;
        if (prevValue) {
            parseCSSClassNames(prevValue).forEach(className => {
                this.renderer.removeClass(el, className);
            });
        }
        if (value) {
            parseCSSClassNames(value).forEach(className => {
                this.renderer.addClass(el, className);
            });
        }
    }
    /**
     * @hidden
     */
    handleInitialFocus() {
        const wrapper = this.wrapper.nativeElement;
        const primaryButton = this.findPrimary(wrapper);
        if (this.autoFocusedElement) {
            const initiallyFocusedElement = wrapper.querySelector(this.autoFocusedElement);
            if (initiallyFocusedElement) {
                initiallyFocusedElement.focus();
            }
        }
        else if (this.shouldFocusPrimary(primaryButton)) {
            primaryButton.focus();
        }
        else {
            wrapper.focus();
        }
    }
    /**
     * @hidden
     */
    findPrimary(wrapper) {
        const actionBtns = wrapper.querySelectorAll('.k-actions .k-button');
        return findPrimaryButton(actionBtns);
    }
    /**
     * @hidden
     */
    handleActionButtonFocus(parent, key) {
        const focusableActionButtons = this.getAllFocusableChildren(parent);
        for (let i = 0; i < focusableActionButtons.length; i++) {
            const current = focusableActionButtons[i];
            if (current === document.activeElement) {
                if (key === Keys.left && i > 0) {
                    focusableActionButtons[i - 1].focus();
                    break;
                }
                if (key === Keys.right && i < focusableActionButtons.length - 1) {
                    focusableActionButtons[i + 1].focus();
                    break;
                }
            }
        }
    }
    /**
     * @hidden
     */
    keepFocusWithinComponent(target, event) {
        const wrapper = this.wrapper.nativeElement;
        const [firstFocusable, lastFocusable] = this.getFirstAndLastFocusable(wrapper);
        const tabAfterLastFocusable = !event.shiftKey && target === lastFocusable;
        const shiftTabAfterFirstFocusable = event.shiftKey && target === firstFocusable;
        if (tabAfterLastFocusable) {
            event.preventDefault();
            firstFocusable.focus();
        }
        if (shiftTabAfterFirstFocusable) {
            event.preventDefault();
            lastFocusable.focus();
        }
    }
    /**
     * @hidden
     */
    shouldFocusPrimary(el) {
        return isPresent(el) && isFocusable(el);
    }
    /**
     * @hidden
     */
    getAllFocusableChildren(parent) {
        return parent.querySelectorAll(focusableSelector);
    }
    /**
     * @hidden
     */
    getFirstAndLastFocusable(parent) {
        const all = this.getAllFocusableChildren(parent);
        const firstFocusable = all.length > 0 ? all[0] : parent;
        const lastFocusable = all.length > 0 ? all[all.length - 1] : parent;
        return [firstFocusable, lastFocusable];
    }
    /**
     * @hidden
     */
    generateTitleId() {
        return 'kendo-dialog-title-' + Math.ceil(Math.random() * 1000000).toString();
    }
    /**
     * @hidden
     */
    generateContentId() {
        return 'kendo-dialog-content-' + Math.ceil(Math.random() * 1000000).toString();
    }
    get wrapperClass() {
        return true;
    }
    get styles() {
        const styles = {};
        if (this.width) {
            styles.width = createValueWithUnit(this.width);
        }
        if (this.height) {
            styles.height = createValueWithUnit(this.height);
        }
        if (this.minWidth) {
            styles.minWidth = createValueWithUnit(this.minWidth);
        }
        if (this.maxWidth) {
            styles.maxWidth = createValueWithUnit(this.maxWidth);
        }
        if (this.minHeight) {
            styles.minHeight = createValueWithUnit(this.minHeight);
        }
        if (this.maxHeight) {
            styles.maxHeight = createValueWithUnit(this.maxHeight);
        }
        return styles;
    }
    bubble(eventName, component) {
        if (component) {
            const emit = e => this[eventName].emit(e);
            const s = component[eventName].subscribe(emit);
            this.subscriptions.push(s);
        }
    }
    handleThemeColorClass(previousValue, currentValue) {
        const dialog = this.dialog.nativeElement;
        if (previousValue) {
            const classToRemove = `k-dialog-${previousValue}`;
            this.renderer.removeClass(dialog, classToRemove);
        }
        if (currentValue) {
            const classToAdd = `k-dialog-${currentValue}`;
            this.renderer.addClass(dialog, classToAdd);
        }
    }
}
DialogComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogComponent, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: i1.LocalizationService }, { token: i0.ChangeDetectorRef }, { token: i0.NgZone }, { token: i2$1.AnimationBuilder }], target: i0.ɵɵFactoryTarget.Component });
DialogComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: DialogComponent, selector: "kendo-dialog", inputs: { actions: "actions", actionsLayout: "actionsLayout", autoFocusedElement: "autoFocusedElement", title: "title", width: "width", minWidth: "minWidth", maxWidth: "maxWidth", height: "height", minHeight: "minHeight", maxHeight: "maxHeight", animation: "animation", themeColor: "themeColor" }, outputs: { action: "action", close: "close" }, host: { properties: { "attr.dir": "this.dir", "attr.tabIndex": "this.tabIndex", "class.k-dialog-wrapper": "this.wrapperClass" } }, providers: [
        LocalizationService,
        {
            provide: DIALOG_LOCALIZATION_SERVICE,
            useExisting: LocalizationService
        },
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.dialog'
        }
    ], queries: [{ propertyName: "titlebarContent", predicate: DialogTitleBarComponent }], viewQueries: [{ propertyName: "actionsView", first: true, predicate: DialogActionsComponent, descendants: true }, { propertyName: "dialog", first: true, predicate: ["dialog"], descendants: true, static: true }, { propertyName: "titlebarView", predicate: DialogTitleBarComponent, descendants: true }], exportAs: ["kendoDialog"], ngImport: i0, template: `
        <ng-container
            kendoDialogLocalizedMessages
            i18n-closeTitle="kendo.dialog.closeTitle|The title of the close button"
            closeTitle="Close"
        >
            <div class="k-overlay" @overlayAppear></div>

            <div #dialog class="k-window k-dialog" role="dialog" aria-modal="true" [ngStyle]="styles">
                <kendo-dialog-titlebar *ngIf="title" [closeTitle]="closeTitle" [id]="titleId">{{ title }}</kendo-dialog-titlebar>
                <ng-content select="kendo-dialog-titlebar" *ngIf="!title"></ng-content>

                <div [id]="contentId" class="k-window-content k-dialog-content">
                    <ng-content *ngIf="!contentTemplate"></ng-content>
                    <ng-template [ngTemplateOutlet]="contentTemplate" *ngIf="contentTemplate"></ng-template>
                </div>

                <ng-content select="kendo-dialog-actions" *ngIf="!actions"></ng-content>
                <kendo-dialog-actions *ngIf="actions" [actions]="actions" [layout]="actionsLayout"> </kendo-dialog-actions>

                <div kendoWatermarkOverlay *ngIf="showLicenseWatermark"></div>
            </div>
        </ng-container>
    `, isInline: true, components: [{ type: DialogTitleBarComponent, selector: "kendo-dialog-titlebar", inputs: ["id", "closeTitle"], outputs: ["close"] }, { type: DialogActionsComponent, selector: "kendo-dialog-actions", inputs: ["actions", "layout"], outputs: ["action"] }, { type: i1$1.WatermarkOverlayComponent, selector: "div[kendoWatermarkOverlay]" }], directives: [{ type: LocalizedMessagesDirective, selector: "\n    [kendoDialogLocalizedMessages],\n    [kendoWindowLocalizedMessages],\n    [kendoDialogTitleBarLocalizedMessages]\n  " }, { type: i4.NgStyle, selector: "[ngStyle]", inputs: ["ngStyle"] }, { type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgTemplateOutlet, selector: "[ngTemplateOutlet]", inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"] }], animations: [
        trigger('overlayAppear', [
            state('in', style({ opacity: 1 })),
            transition('void => *', [style({ opacity: 0.1 }), animate('.3s cubic-bezier(.2, .6, .4, 1)')])
        ])
    ] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogComponent, decorators: [{
            type: Component,
            args: [{
                    animations: [
                        trigger('overlayAppear', [
                            state('in', style({ opacity: 1 })),
                            transition('void => *', [style({ opacity: 0.1 }), animate('.3s cubic-bezier(.2, .6, .4, 1)')])
                        ])
                    ],
                    exportAs: 'kendoDialog',
                    providers: [
                        LocalizationService,
                        {
                            provide: DIALOG_LOCALIZATION_SERVICE,
                            useExisting: LocalizationService
                        },
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.dialog'
                        }
                    ],
                    selector: 'kendo-dialog',
                    template: `
        <ng-container
            kendoDialogLocalizedMessages
            i18n-closeTitle="kendo.dialog.closeTitle|The title of the close button"
            closeTitle="Close"
        >
            <div class="k-overlay" @overlayAppear></div>

            <div #dialog class="k-window k-dialog" role="dialog" aria-modal="true" [ngStyle]="styles">
                <kendo-dialog-titlebar *ngIf="title" [closeTitle]="closeTitle" [id]="titleId">{{ title }}</kendo-dialog-titlebar>
                <ng-content select="kendo-dialog-titlebar" *ngIf="!title"></ng-content>

                <div [id]="contentId" class="k-window-content k-dialog-content">
                    <ng-content *ngIf="!contentTemplate"></ng-content>
                    <ng-template [ngTemplateOutlet]="contentTemplate" *ngIf="contentTemplate"></ng-template>
                </div>

                <ng-content select="kendo-dialog-actions" *ngIf="!actions"></ng-content>
                <kendo-dialog-actions *ngIf="actions" [actions]="actions" [layout]="actionsLayout"> </kendo-dialog-actions>

                <div kendoWatermarkOverlay *ngIf="showLicenseWatermark"></div>
            </div>
        </ng-container>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: i1.LocalizationService }, { type: i0.ChangeDetectorRef }, { type: i0.NgZone }, { type: i2$1.AnimationBuilder }]; }, propDecorators: { actions: [{
                type: Input
            }], actionsLayout: [{
                type: Input
            }], autoFocusedElement: [{
                type: Input
            }], title: [{
                type: Input
            }], width: [{
                type: Input
            }], minWidth: [{
                type: Input
            }], maxWidth: [{
                type: Input
            }], height: [{
                type: Input
            }], minHeight: [{
                type: Input
            }], maxHeight: [{
                type: Input
            }], animation: [{
                type: Input
            }], themeColor: [{
                type: Input
            }], action: [{
                type: Output
            }], close: [{
                type: Output
            }], dir: [{
                type: HostBinding,
                args: ['attr.dir']
            }], tabIndex: [{
                type: HostBinding,
                args: ['attr.tabIndex']
            }], titlebarContent: [{
                type: ContentChildren,
                args: [DialogTitleBarComponent, { descendants: false }]
            }], titlebarView: [{
                type: ViewChildren,
                args: [DialogTitleBarComponent]
            }], actionsView: [{
                type: ViewChild,
                args: [DialogActionsComponent, { static: false }]
            }], dialog: [{
                type: ViewChild,
                args: ['dialog', { static: true }]
            }], wrapperClass: [{
                type: HostBinding,
                args: ['class.k-dialog-wrapper']
            }] } });

/**
 * Holds references to the object instance and published events of the Dialog.
 * Controls the Dialogs that were opened through the `DialogService`
 * ([see example]({% slug api_dialog_dialogservice %}#toc-open)).
 */
class DialogRef {
}

/**
 * The base class  which will be extended by a component that is provided as content through `content`
 * ([see example]({% slug service_dialog %}#toc-passing-title-content-and-actions-as-a-single-component)).
 */
class DialogContentBase {
    constructor(dialog) {
        this.dialog = dialog;
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        if (this.dialogTitleBar) {
            // when opening component inside dialog with service AND the component has defined its own titlebar
            this.dialogTitleBar.close.pipe(filter((e) => !e.isDefaultPrevented())).subscribe(() => {
                this.dialog.close();
            });
        }
        if (this.dialogActions) {
            if (this.dialogActions.actions) {
                this.dialogActions.action.subscribe(action => this.dialog.dialog.instance.action.emit(action));
            }
        }
    }
}
DialogContentBase.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContentBase, deps: [{ token: DialogRef }], target: i0.ɵɵFactoryTarget.Directive });
DialogContentBase.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DialogContentBase, viewQueries: [{ propertyName: "dialogTitleBar", first: true, predicate: DialogTitleBarComponent, descendants: true }, { propertyName: "dialogActions", first: true, predicate: DialogActionsComponent, descendants: true }], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContentBase, decorators: [{
            type: Directive
        }], ctorParameters: function () { return [{ type: DialogRef }]; }, propDecorators: { dialogTitleBar: [{
                type: ViewChild,
                args: [DialogTitleBarComponent, { static: false }]
            }], dialogActions: [{
                type: ViewChild,
                args: [DialogActionsComponent, { static: false }]
            }] } });

/**
 * @hidden
 */
class DialogContainerService {
    set container(container) {
        DialogContainerService.container = container;
    }
    get container() {
        return DialogContainerService.container;
    }
}
DialogContainerService.container = null;
DialogContainerService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContainerService, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
DialogContainerService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContainerService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContainerService, decorators: [{
            type: Injectable
        }] });

/**
 * The settings for the Dialog actions when the Dialog is opened through `DialogService`
 * ([see example]({% slug api_dialog_dialogservice %}#toc-open)).
 */
class DialogAction {
}

/**
 * The settings that can be used when the Dialog is opened through `DialogService`.
 * ([see example]({% slug api_dialog_dialogservice %}#toc-open)).
 */
class DialogSettings {
}

// eslint-disable max-line-length
const isNotComponent$1 = (component) => isString(component) || component instanceof TemplateRef;
class DialogInjector {
    constructor(getDialogRef, parentInjector) {
        this.getDialogRef = getDialogRef;
        this.parentInjector = parentInjector;
    }
    get(token, notFoundValue) {
        if (token === DialogRef) {
            return this.getDialogRef();
        }
        return this.parentInjector.get(token, notFoundValue);
    }
}
/**
 * A service for opening Dialog windows dynamically
 * ([see example]({% slug service_dialog %})).
 */
class DialogService {
    constructor(
    /**
     * @hidden
     */
    resolver, containerService) {
        this.resolver = resolver;
        this.containerService = containerService;
    }
    /**
     * Opens a Dialog window. Requires an element in the application that uses the
     * [`kendoDialogContainer`]({% slug api_dialog_dialogcontainerdirective %}) directive.
     * Created Dialogs will be mounted in the DOM directly after that element.
     *
     * @param {DialogAction} options - The options that define the Dialog.
     * @returns {DialogRef} - A reference to the Dialog object and the convenience properties.
     *
     * @example
     *
     * ```ts-no-run
     * _@Component({
     *   selector: 'my-app',
     *   template: `
     *     <button kendoButton (click)="open()">Harmless button</button>
     *     <div kendoDialogContainer></div>
     *   `
     * })
     * export class AppComponent {
     *     constructor( private dialogService: DialogService ) {}
     *
     *     public open() {
     *         var dialog = this.dialogService.open({
     *           title: "Please confirm",
     *           content: "Are you sure?",
     *           actions: [
     *             { text: "No" },
     *             { text: "Yes", themeColor: 'primary' }
     *           ]
     *         });
     *
     *         dialog.result.subscribe((result) => {
     *           if (result instanceof DialogCloseResult) {
     *             console.log("close");
     *           } else {
     *             console.log("action", result);
     *           }
     *         });
     *     }
     * }
     * ```
     *
     */
    open(options) {
        const factory = this.resolver.resolveComponentFactory(DialogComponent);
        const container = options.appendTo || this.containerService.container;
        if (!container) {
            throw new Error(`
Cannot attach dialog to the page.
Add an element that uses the kendoDialogContainer directive, or set the 'appendTo' property.
See https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog/service/.
          `);
        }
        // create DialogRef to (1) pass as result, (2) provide through injector
        const dialogRef = {
            close: () => {
                /* noop */
            },
            content: null,
            dialog: null,
            result: null
        };
        return this.initializeDialog(options.content, factory, container, dialogRef, options);
    }
    initializeDialog(component, factory, container, dialogRef, options) {
        const content = this.contentFrom(component, container, dialogRef);
        const dialog = container.createComponent(factory, undefined, undefined, content.nodes);
        dialogRef.dialog = dialog;
        dialog.changeDetectorRef.markForCheck();
        // copy @Input options to dialog instance
        this.applyOptions(dialog.instance, options);
        // create close handler and result stream
        const apiClose = new Subject();
        const close = (e) => {
            if (e instanceof PreventableEvent) {
                e = new DialogCloseResult();
            }
            apiClose.next(e || new DialogCloseResult());
            if (content.componentRef) {
                content.componentRef.destroy();
            }
            dialog.destroy();
        };
        const result = merge(apiClose, 
        // triggered when the titlebar or actions are defined in DialogSettings
        merge(dialog.instance.close, dialog.instance.action).pipe(map(e => (e instanceof PreventableEvent ? new DialogCloseResult() : e)), filter(e => {
            if (options.preventAction) {
                // add dialogRef only when using component
                const dialogRefParameter = isNotComponent$1(component) ? undefined : dialogRef;
                return !options.preventAction(e, dialogRefParameter);
            }
            return true;
        }))).pipe(take(1), 
        // Takes care for multiple subscriptions:
        // We subscribe internaly and the user may subscribe to get a close result - dialog.result.subscribe().
        // This causes multiple subscriptions to the same source and thus multiple emissions. share() solves that.
        share());
        result.subscribe(close);
        dialogRef.close = close;
        dialogRef.result = result;
        if (component && isDevMode()) {
            const hasContentTitle = content.nodes[0] && content.nodes[0].length > 0;
            const hasContentActions = content.nodes[2] && content.nodes[2].length > 0;
            const multipleTitles = options.title && hasContentTitle;
            const multipleActions = options.actions && hasContentActions;
            if (component.prototype instanceof DialogContentBase) {
                // content component extends DialogContentBase
                if (multipleTitles || multipleActions) {
                    console.warn(`
                    Multiple Title and/or Actions configurations detected.
                    When using a component as content, provide the title and actions either in the component's markup
                    or via the title and actions properties of the DialogSettings object, but not both.
                    See https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog/service/#toc-passing-title-content-and-actions-as-a-single-component'`);
                }
            }
            else {
                if (hasContentTitle || hasContentActions) {
                    console.warn(`
                    When Title and/or Actions markup is provided in content component's template,
                    the component needs to inherit the DialogContentBase class to ensure that close and result events are properly hooked.
                    See https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog/service/#toc-passing-title-content-and-actions-as-a-single-component'`);
                }
            }
        }
        return dialogRef;
    }
    applyOptions(instance, options) {
        instance.title = options.title;
        instance.actions = options.actions;
        instance.actionsLayout = options.actionsLayout || 'stretched';
        instance.width = options.width;
        instance.minWidth = options.minWidth;
        instance.maxWidth = options.maxWidth;
        instance.height = options.height;
        instance.minHeight = options.minHeight;
        instance.maxHeight = options.maxHeight;
        instance.autoFocusedElement = options.autoFocusedElement;
        instance.closeTitle = options.closeTitle;
        instance.cssClass = options.cssClass;
        instance.htmlAttributes = options.htmlAttributes;
        instance.animation = options.animation !== undefined ? options.animation : true;
        if (options.content instanceof TemplateRef) {
            instance.contentTemplate = options.content;
        }
    }
    contentFrom(content, container, dialogRef) {
        const renderer = container.injector.get(Renderer2);
        let nodes = [];
        let titleNodes = [];
        let actionNodes = [];
        let componentRef = null;
        if (typeof content === 'string') {
            nodes = [renderer.createText(content)];
        }
        else if (content && !(content instanceof TemplateRef)) {
            // Component
            const injector = new DialogInjector(() => dialogRef, container.injector);
            const factory = this.resolver.resolveComponentFactory(content);
            componentRef = container.createComponent(factory, undefined, injector);
            titleNodes = Array.from(componentRef.location.nativeElement.querySelectorAll('kendo-dialog-titlebar'));
            nodes = [componentRef.location.nativeElement];
            actionNodes = Array.from(componentRef.location.nativeElement.querySelectorAll('kendo-dialog-actions'));
            dialogRef.content = componentRef;
        }
        return {
            componentRef,
            nodes: [
                titleNodes,
                nodes,
                actionNodes // <ng-content select="kendo-dialog-actions">
            ]
        };
    }
}
DialogService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogService, deps: [{ token: i0.ComponentFactoryResolver }, { token: DialogContainerService }], target: i0.ɵɵFactoryTarget.Injectable });
DialogService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: i0.ComponentFactoryResolver }, { type: DialogContainerService, decorators: [{
                    type: Inject,
                    args: [DialogContainerService]
                }] }]; } });

/**
 * @hidden
 */
let newZIndex = 10002;
/**
 * @hidden
 */
const DEFAULT_OPTIONS = {
    draggable: true,
    height: null,
    left: null,
    minHeight: 100,
    minWidth: 120,
    position: 'absolute',
    resizable: true,
    state: 'default',
    top: null,
    width: null
};
/**
 * @hidden
 */
const createMoveStream = (el, ev) => mouseDown => {
    return el.kendoDrag
        .pipe(takeUntil(el.kendoRelease.pipe(tap(() => { ev.emit(); }))), map(({ pageX, pageY }) => ({
        originalX: mouseDown.pageX,
        originalY: mouseDown.pageY,
        pageX,
        pageY
    })));
};
/**
 * @hidden
 */
class DragResizeService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.close = new EventEmitter();
        this.focus = new EventEmitter();
        this.change = new EventEmitter();
        this.stateChange = new EventEmitter();
        this.dragStart = new EventEmitter();
        this.dragEnd = new EventEmitter();
        this.resizeStart = new EventEmitter();
        this.resizeEnd = new EventEmitter();
        this.options = Object.assign({}, DEFAULT_OPTIONS);
        this.lastAction = null;
        this.subscriptions = new Subscription();
        this.dragSubscription = new Subscription();
    }
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        if (this.dragSubscription) {
            this.dragSubscription.unsubscribe();
        }
    }
    init(el) {
        const state = this.options.state;
        const options = this.options;
        this.window = el;
        if (state !== 'default') {
            this.restoreOptions = Object.assign({}, options);
        }
        if (state === 'minimized') {
            options.height = 0;
            options.minHeight = 0;
        }
        if (state === 'maximized') {
            options.position = 'fixed';
        }
    }
    onDrag(el) {
        this.subscriptions.add(this.ngZone.runOutsideAngular(() => {
            let startPosition;
            let dragStarted;
            this.dragSubscription = el.kendoPress
                .pipe(tap((ev) => {
                if (!ev.isTouch) {
                    preventDefault(ev);
                }
                this.focus.emit();
                startPosition = this.currentPosition();
                dragStarted = false;
            }), switchMap(createMoveStream(el, this.dragEnd)))
                .subscribe(({ pageX, pageY, originalX, originalY }) => {
                if (!dragStarted) {
                    this.ensureWidth();
                    this.dragStart.emit();
                    dragStarted = true;
                }
                this.handleDrag({
                    originalX, originalY,
                    pageX, pageY, startPosition
                });
            });
        }));
    }
    handleDrag({ originalX, originalY, pageX, pageY, startPosition }) {
        this.options.left = startPosition.x + pageX - originalX;
        this.options.top = startPosition.y + pageY - originalY;
        if (this.options.state === 'minimized' && isPresent(this.restoreOptions)) {
            this.restoreOptions.left = this.options.left;
            this.restoreOptions.top = this.options.top;
        }
        this.change.emit({
            left: startPosition.x + pageX - originalX,
            top: startPosition.y + pageY - originalY
        });
    }
    onResize(handle, direction) {
        this.subscriptions.add(this.ngZone.runOutsideAngular(() => {
            let startOffsetAndPosition;
            let resizeStarted = false;
            handle.kendoPress.pipe(tap((ev) => {
                preventDefault(ev);
                this.focus.emit();
                startOffsetAndPosition = this.currentOffsetAndPosition();
                resizeStarted = false;
            }), switchMap(createMoveStream(handle, this.resizeEnd)))
                .subscribe(({ pageX, pageY, originalX, originalY }) => {
                if (!resizeStarted) {
                    this.resizeStart.emit(direction);
                    resizeStarted = true;
                }
                const deltaX = pageX - originalX;
                const deltaY = pageY - originalY;
                this.handleResize(startOffsetAndPosition, direction, deltaX, deltaY);
            });
        }));
    }
    handleResize(initial, dir, deltaX, deltaY) {
        const old = this.options;
        const ev = {};
        if (dir.indexOf('e') >= 0) {
            const newWidth = initial.width + deltaX;
            if (newWidth !== old.width && newWidth >= old.minWidth) {
                ev.width = newWidth;
            }
        }
        if (dir.indexOf('n') >= 0) {
            const newHeight = initial.height - deltaY;
            const newTop = initial.y + deltaY;
            if (newHeight !== old.height && newHeight >= old.minHeight && newTop !== old.top) {
                ev.height = newHeight;
                ev.top = newTop;
            }
        }
        if (dir.indexOf('s') >= 0) {
            const newHeight = initial.height + deltaY;
            if (newHeight !== old.height && newHeight >= old.minHeight) {
                ev.height = newHeight;
            }
        }
        if (dir.indexOf('w') >= 0) {
            const newLeft = initial.x + deltaX;
            const newWidth = initial.width - deltaX;
            if (newWidth !== old.width && newWidth >= old.minWidth && newLeft !== old.left) {
                ev.width = newWidth;
                ev.left = newLeft;
            }
        }
        if (isPresent(ev.width) || isPresent(ev.height)) {
            OFFSET_STYLES.forEach((style) => {
                if (isPresent(ev[style])) {
                    this.options[style] = ev[style];
                }
            });
            this.change.emit(ev);
        }
    }
    restoreAction() {
        this.lastAction = 'restore';
        this.defaultState();
    }
    defaultState() {
        if (isPresent(this.restoreOptions)) {
            this.options = Object.assign({}, this.restoreOptions);
        }
        this.options.state = 'default';
        this.stateChange.emit('default');
    }
    storeOptions() {
        this.restoreOptions = Object.assign({}, this.options);
    }
    maximizeAction() {
        this.lastAction = 'maximize';
        this.maximizeState();
    }
    maximizeState() {
        this.storeOptions();
        const wnd = this.windowViewPort;
        this.options = Object.assign({}, this.options, {
            height: wnd.height,
            left: 0,
            position: 'fixed',
            state: 'maximized',
            top: 0,
            width: wnd.width
        });
        this.stateChange.emit('maximized');
    }
    minimizeAction() {
        this.lastAction = 'minimize';
        this.minimizeState();
    }
    minimizeState() {
        this.storeOptions();
        this.options = Object.assign({}, this.options, {
            height: null,
            minHeight: 0,
            state: 'minimized'
        });
        this.stateChange.emit('minimized');
    }
    /**
     * Handles manual changes of the 'state' property.
     * Required to distinguish them from action clicks.
     */
    applyManualState() {
        const state = this.options.state;
        switch (state) {
            case 'default':
                this.clearHeight();
                this.defaultState();
                break;
            case 'maximized':
                this.clearHeight();
                this.maximizeState();
                break;
            case 'minimized':
                this.minimizeState();
                break;
            default:
                break;
        }
    }
    closeAction() {
        this.close.emit();
    }
    ensureWidth() {
        const windowOffset = offset(this.window.nativeElement);
        if (!isPresent(this.options.width)) {
            this.options.width = windowOffset.width;
            this.change.emit({ width: windowOffset.width });
        }
    }
    clearHeight() {
        if (this.options.height === 0) {
            delete this.options.height;
        }
        if (this.options.minHeight === 0) {
            delete this.options.minHeight;
        }
    }
    center() {
        if (this.options.state === 'maximized') {
            return;
        }
        const scroll = scrollPosition(this.window.nativeElement);
        const wnd = this.windowViewPort;
        const wrapper = offset(this.window.nativeElement);
        const ev = {};
        if (!isPresent(this.options.left)) {
            this.options.left = scroll.x + Math.max(0, (wnd.width - wrapper.width) / 2);
            ev.left = this.options.left;
        }
        if (!isPresent(this.options.top)) {
            this.options.top = scroll.y + Math.max(0, (wnd.height - wrapper.height) / 2);
            ev.top = this.options.top;
        }
        this.change.emit(ev);
    }
    currentOffsetAndPosition() {
        const o = this.options;
        const off = offset(this.window.nativeElement);
        return Object.assign({}, this.currentPosition(), {
            height: o.height ? o.height : off.height,
            width: o.width ? o.width : off.width
        });
    }
    currentPosition() {
        const o = this.options;
        if (!o.top || !o.left) {
            this.setPosition();
        }
        return {
            x: this.options.left,
            y: this.options.top
        };
    }
    setPosition() {
        const wrapper = positionWithScroll(this.window.nativeElement, getDocumentElement(this.window.nativeElement));
        this.options.left = wrapper.left;
        this.options.top = wrapper.top;
    }
    setRestoreOption(style, value) {
        if (isPresent(this.restoreOptions)) {
            this.restoreOptions[style] = value;
        }
    }
    get nextPossibleZIndex() {
        return newZIndex;
    }
    get nextZIndex() {
        return newZIndex++;
    }
    get windowViewPort() {
        return getWindowViewPort(this.window.nativeElement);
    }
}
DragResizeService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragResizeService, deps: [{ token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Injectable });
DragResizeService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragResizeService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragResizeService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: i0.NgZone }]; } });

/**
 * @hidden
 */
class ResizeHandleDirective {
    constructor(draggable, el, renderer, service) {
        this.draggable = draggable;
        this.el = el;
        this.renderer = renderer;
        this.service = service;
        this.subscriptions = new Subscription();
    }
    get hostClass() {
        return true;
    }
    ngOnInit() {
        this.setDisplay();
        this.renderer.addClass(this.el.nativeElement, 'k-resize-' + this.direction);
        this.subscriptions.add(of(this.draggable).subscribe(handle => {
            this.service.onResize(handle, this.direction);
        }));
        this.subscriptions.add(this.service.resizeStart.subscribe((dir) => {
            if (dir !== this.direction) {
                this.setDisplay('none');
            }
        }));
        this.subscriptions.add(this.service.dragStart.subscribe(() => {
            this.setDisplay('none');
        }));
        this.subscriptions.add(merge(this.service.resizeEnd, this.service.dragEnd).subscribe(() => {
            this.setDisplay('block');
        }));
        this.subscriptions.add(this.service.stateChange.subscribe((state) => {
            this.setDisplay(state === 'default' ? 'block' : 'none');
        }));
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    setDisplay(value = 'block') {
        this.renderer.setStyle(this.el.nativeElement, 'display', this.service.options.state === 'default' ? value : 'none');
    }
}
ResizeHandleDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: ResizeHandleDirective, deps: [{ token: i1$1.DraggableDirective, host: true }, { token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService }], target: i0.ɵɵFactoryTarget.Directive });
ResizeHandleDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: ResizeHandleDirective, selector: "[kendoWindowResizeHandle]", inputs: { direction: "direction" }, host: { properties: { "class.k-resize-handle": "this.hostClass" } }, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: ResizeHandleDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoWindowResizeHandle]'
                }]
        }], ctorParameters: function () { return [{ type: i1$1.DraggableDirective, decorators: [{
                    type: Host
                }] }, { type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService }]; }, propDecorators: { direction: [{
                type: Input
            }], hostClass: [{
                type: HostBinding,
                args: ['class.k-resize-handle']
            }] } });

class WindowTitleBarComponent {
    constructor(el, service, ngZone) {
        this.el = el;
        this.ngZone = ngZone;
        this.service = service;
    }
    ngOnInit() {
        this.dragDirective = new DraggableDirective(this.el, this.ngZone);
        this.dragDirective.ngOnInit();
        if (this.isDraggable) {
            this.subscribeDrag();
        }
        this.subscribeStateChange();
    }
    ngAfterViewInit() {
        const element = this.el.nativeElement.querySelector('.k-window-title');
        if (isPresent(element)) {
            element.setAttribute('id', this.id);
        }
    }
    ngOnDestroy() {
        this.dragDirective.ngOnDestroy();
        this.unsubscribeDrag();
        this.unsubscribeState();
    }
    /**
     * @hidden
     */
    subscribeDrag() {
        this.unsubscribeDrag();
        this.dragSubscription = of(this.dragDirective).subscribe(titleBar => {
            this.service.onDrag(titleBar);
        });
    }
    /**
     * @hidden
     */
    subscribeStateChange() {
        this.stateSubscription = this.service.stateChange.subscribe((state) => {
            if (this.service.options.draggable) {
                if (state === 'maximized') {
                    this.unsubscribeDrag();
                }
                else {
                    this.subscribeDrag();
                }
            }
        });
    }
    /**
     * @hidden
     */
    unsubscribeDrag() {
        if (this.dragSubscription) {
            this.service.dragSubscription.unsubscribe();
            this.dragSubscription.unsubscribe();
            this.dragSubscription = null;
        }
    }
    /**
     * @hidden
     */
    unsubscribeState() {
        if (this.stateSubscription) {
            this.stateSubscription.unsubscribe();
            this.stateSubscription = null;
        }
    }
    get className() {
        return true;
    }
    get touchAction() {
        if (this.isDraggable) {
            return 'none';
        }
    }
    /**
     * @hidden
     */
    handle(ev) {
        const target = ev.target;
        const state = this.service.options.state;
        if (!hasClasses(target, 'k-icon') && !isFocusable(target, false) && this.service.options.resizable) {
            if (state === 'default') {
                this.service.maximizeAction();
            }
            else if (state === 'maximized') {
                this.service.restoreAction();
            }
        }
    }
    get isDraggable() {
        const options = this.service.options;
        return options.draggable && options.state !== 'maximized';
    }
}
WindowTitleBarComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowTitleBarComponent, deps: [{ token: i0.ElementRef }, { token: DragResizeService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component });
WindowTitleBarComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowTitleBarComponent, selector: "kendo-window-titlebar", inputs: { template: "template", id: "id" }, host: { listeners: { "dblclick": "handle($event)" }, properties: { "class.k-window-titlebar": "this.className", "style.touch-action": "this.touchAction" } }, ngImport: i0, template: `
	<ng-content *ngIf="!template"></ng-content>
	<ng-template
		[ngTemplateOutlet]="template"
		[ngTemplateOutletContext]="{'$implicit': service}" *ngIf="template">
	</ng-template>
	`, isInline: true, directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgTemplateOutlet, selector: "[ngTemplateOutlet]", inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowTitleBarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'kendo-window-titlebar',
                    template: `
	<ng-content *ngIf="!template"></ng-content>
	<ng-template
		[ngTemplateOutlet]="template"
		[ngTemplateOutletContext]="{'$implicit': service}" *ngIf="template">
	</ng-template>
	`
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: DragResizeService }, { type: i0.NgZone }]; }, propDecorators: { template: [{
                type: Input
            }], id: [{
                type: Input
            }], className: [{
                type: HostBinding,
                args: ['class.k-window-titlebar']
            }], touchAction: [{
                type: HostBinding,
                args: ['style.touch-action']
            }], handle: [{
                type: HostListener,
                args: ['dblclick', ['$event']]
            }] } });

/**
 * @hidden
 */
class NavigationService {
    constructor(window, ngZone) {
        this.window = window;
        this.ngZone = ngZone;
    }
    process(ev) {
        const key = ev.keyCode;
        switch (key) {
            case Keys.up:
            case Keys.down:
            case Keys.left:
            case Keys.right: {
                ev.preventDefault();
                this.ngZone.run(() => {
                    this.handleArrow(key, ev);
                });
                break;
            }
            case Keys.esc:
                this.ngZone.run(() => {
                    this.handleEscape();
                });
                break;
            default:
                break;
        }
    }
    handleArrow(key, ev) {
        const options = this.window.options;
        if (ev.altKey) {
            this.handleStateChange(key, options.state);
            return;
        }
        if ((ev.ctrlKey || ev.metaKey) && options.state === 'default') {
            this.handleResize(key);
        }
        else {
            this.handleDrag(key);
        }
    }
    handleEscape() {
        this.window.closeAction();
    }
    handleDrag(key) {
        const options = this.window.options;
        if (!options.draggable) {
            return;
        }
        const offset = this.window.currentOffsetAndPosition();
        const restoreOptions = this.window.restoreOptions;
        const ev = {};
        let delta = 10;
        if (key === Keys.left || key === Keys.up) {
            delta *= -1;
        }
        switch (key) {
            case Keys.left:
            case Keys.right: {
                ev.left = offset.x + delta;
                options.left = ev.left;
                break;
            }
            case Keys.up:
            case Keys.down: {
                ev.top = offset.y + delta;
                options.top = ev.top;
                break;
            }
            default:
                break;
        }
        if (options.state === 'minimized' && isPresent(restoreOptions)) {
            restoreOptions.left = options.left;
            restoreOptions.top = options.top;
        }
        this.window.change.emit(ev);
    }
    handleResize(key) {
        const options = this.window.options;
        if (!options.resizable) {
            return;
        }
        const offset = this.window.currentOffsetAndPosition();
        let newWidth;
        let newHeight;
        const ev = {};
        let delta = 10;
        if (key === Keys.left || key === Keys.up) {
            delta *= -1;
        }
        switch (key) {
            case Keys.left:
            case Keys.right: {
                newWidth = offset.width + delta;
                if (newWidth !== options.width && newWidth >= options.minWidth) {
                    ev.width = newWidth;
                }
                break;
            }
            case Keys.up:
            case Keys.down: {
                newHeight = offset.height + delta;
                if (newHeight !== options.height && newHeight >= options.minHeight) {
                    ev.height = newHeight;
                }
                break;
            }
            default:
                break;
        }
        if (isPresent(ev.width) || isPresent(ev.height)) {
            OFFSET_STYLES.forEach((style) => {
                if (isPresent(ev[style])) {
                    this.window.options[style] = ev[style];
                }
            });
            this.window.change.emit(ev);
        }
    }
    handleStateChange(key, state) {
        if ((state === 'minimized' && key === Keys.up) ||
            (state === 'maximized' && key === Keys.down)) {
            this.window.restoreAction();
            return;
        }
        if (state === 'default') {
            if (key === Keys.up) {
                this.window.maximizeAction();
            }
            else if (key === Keys.down) {
                this.window.minimizeAction();
            }
        }
    }
}
NavigationService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: NavigationService, deps: [{ token: DragResizeService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Injectable });
NavigationService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: NavigationService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: NavigationService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: DragResizeService }, { type: i0.NgZone }]; } });

class WindowMinimizeActionDirective extends Button {
    constructor(el, renderer, _service, localization, ngZone) {
        super(el, renderer, null, localization, ngZone);
        this.buttonType = 'button';
        this.buttonClass = true;
        /**
         * @hidden
         */
        this.windowMinimizeIcon = windowMinimizeIcon;
        this.window = _service;
        this.fillMode = 'flat';
        this.icon = 'window-minimize';
    }
    /**
     * @hidden
     */
    onClick() {
        if (!this.isDisabled) {
            this.window.minimizeAction();
        }
    }
    get visible() {
        return this.window.options.state === 'default' ? 'inline-flex' : 'none';
    }
}
WindowMinimizeActionDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowMinimizeActionDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService, optional: true }, { token: i1.LocalizationService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component });
WindowMinimizeActionDirective.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowMinimizeActionDirective, selector: "button[kendoWindowMinimizeAction]", inputs: { window: "window" }, host: { listeners: { "click": "onClick()" }, properties: { "attr.type": "this.buttonType", "class.k-window-titlebar-action": "this.buttonClass", "style.display": "this.visible" } }, providers: [
        LocalizationService,
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.button'
        }
    ], exportAs: ["kendoWindowMinimizeAction"], usesInheritance: true, ngImport: i0, template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window-minimize"
            [svgIcon]="windowMinimizeIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `, isInline: true, components: [{ type: i3.IconWrapperComponent, selector: "kendo-icon-wrapper", inputs: ["name", "svgIcon", "innerCssClass", "customFontClass", "size"], exportAs: ["kendoIconWrapper"] }], directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowMinimizeActionDirective, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoWindowMinimizeAction',
                    providers: [
                        LocalizationService,
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.button'
                        }
                    ],
                    selector: 'button[kendoWindowMinimizeAction]',
                    template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window-minimize"
            [svgIcon]="windowMinimizeIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService, decorators: [{
                    type: Optional
                }] }, { type: i1.LocalizationService }, { type: i0.NgZone }]; }, propDecorators: { window: [{
                type: Input
            }], buttonType: [{
                type: HostBinding,
                args: ['attr.type']
            }], buttonClass: [{
                type: HostBinding,
                args: ['class.k-window-titlebar-action']
            }], onClick: [{
                type: HostListener,
                args: ['click']
            }], visible: [{
                type: HostBinding,
                args: ['style.display']
            }] } });

class WindowMaximizeActionDirective extends Button {
    constructor(el, renderer, _service, localization, ngZone) {
        super(el, renderer, null, localization, ngZone);
        this.buttonType = 'button';
        this.buttonClass = true;
        /**
         * @hidden
         */
        this.windowIcon = windowIcon;
        this.window = _service;
        this.fillMode = 'flat';
        this.icon = 'window';
    }
    /**
     * @hidden
     */
    onClick() {
        if (!this.isDisabled) {
            this.window.maximizeAction();
        }
    }
    get visible() {
        return this.window.options.state === 'default' ? 'inline-flex' : 'none';
    }
}
WindowMaximizeActionDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowMaximizeActionDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService, optional: true }, { token: i1.LocalizationService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component });
WindowMaximizeActionDirective.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowMaximizeActionDirective, selector: "button[kendoWindowMaximizeAction]", inputs: { window: "window" }, host: { listeners: { "click": "onClick()" }, properties: { "attr.type": "this.buttonType", "class.k-window-titlebar-action": "this.buttonClass", "style.display": "this.visible" } }, providers: [
        LocalizationService,
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.button'
        }
    ], exportAs: ["kendoWindowMaximizeAction"], usesInheritance: true, ngImport: i0, template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window"
            [svgIcon]="windowIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `, isInline: true, components: [{ type: i3.IconWrapperComponent, selector: "kendo-icon-wrapper", inputs: ["name", "svgIcon", "innerCssClass", "customFontClass", "size"], exportAs: ["kendoIconWrapper"] }], directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowMaximizeActionDirective, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoWindowMaximizeAction',
                    providers: [
                        LocalizationService,
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.button'
                        }
                    ],
                    selector: 'button[kendoWindowMaximizeAction]',
                    template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window"
            [svgIcon]="windowIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService, decorators: [{
                    type: Optional
                }] }, { type: i1.LocalizationService }, { type: i0.NgZone }]; }, propDecorators: { window: [{
                type: Input
            }], buttonType: [{
                type: HostBinding,
                args: ['attr.type']
            }], buttonClass: [{
                type: HostBinding,
                args: ['class.k-window-titlebar-action']
            }], onClick: [{
                type: HostListener,
                args: ['click']
            }], visible: [{
                type: HostBinding,
                args: ['style.display']
            }] } });

class WindowRestoreActionDirective extends Button {
    constructor(el, renderer, _service, localization, ngZone) {
        super(el, renderer, null, localization, ngZone);
        /**
         * @hidden
         */
        this.windowRestoreIcon = windowRestoreIcon;
        this.buttonType = 'button';
        this.buttonClass = true;
        this.window = _service;
        this.fillMode = 'flat';
        this.icon = 'window-restore';
    }
    /**
     * @hidden
     */
    onClick() {
        if (!this.isDisabled) {
            this.window.restoreAction();
        }
    }
    get visible() {
        return this.window.options.state === 'default' ? 'none' : 'inline-flex';
    }
}
WindowRestoreActionDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowRestoreActionDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService, optional: true }, { token: i1.LocalizationService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component });
WindowRestoreActionDirective.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowRestoreActionDirective, selector: "button[kendoWindowRestoreAction]", inputs: { window: "window" }, host: { listeners: { "click": "onClick()" }, properties: { "attr.type": "this.buttonType", "class.k-window-titlebar-action": "this.buttonClass", "style.display": "this.visible" } }, providers: [
        LocalizationService,
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.button'
        }
    ], exportAs: ["kendoWindowRestoreAction"], usesInheritance: true, ngImport: i0, template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window-restore"
            [svgIcon]="windowRestoreIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `, isInline: true, components: [{ type: i3.IconWrapperComponent, selector: "kendo-icon-wrapper", inputs: ["name", "svgIcon", "innerCssClass", "customFontClass", "size"], exportAs: ["kendoIconWrapper"] }], directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowRestoreActionDirective, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoWindowRestoreAction',
                    providers: [
                        LocalizationService,
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.button'
                        }
                    ],
                    selector: 'button[kendoWindowRestoreAction]',
                    template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="window-restore"
            [svgIcon]="windowRestoreIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService, decorators: [{
                    type: Optional
                }] }, { type: i1.LocalizationService }, { type: i0.NgZone }]; }, propDecorators: { window: [{
                type: Input
            }], buttonType: [{
                type: HostBinding,
                args: ['attr.type']
            }], buttonClass: [{
                type: HostBinding,
                args: ['class.k-window-titlebar-action']
            }], onClick: [{
                type: HostListener,
                args: ['click']
            }], visible: [{
                type: HostBinding,
                args: ['style.display']
            }] } });

class WindowCloseActionDirective extends Button {
    constructor(el, renderer, _service, localization, ngZone) {
        super(el, renderer, null, localization, ngZone);
        /**
         * @hidden
         */
        this.xIcon = xIcon;
        this.buttonType = 'button';
        this.buttonClass = true;
        this.window = _service;
        this.fillMode = 'flat';
        this.icon = 'x';
    }
    /**
     * @hidden
     */
    onClick() {
        if (!this.isDisabled) {
            this.window.closeAction();
        }
    }
}
WindowCloseActionDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowCloseActionDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService, optional: true }, { token: i1.LocalizationService }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component });
WindowCloseActionDirective.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowCloseActionDirective, selector: "button[kendoWindowCloseAction]", inputs: { window: "window" }, host: { listeners: { "click": "onClick()" }, properties: { "attr.type": "this.buttonType", "class.k-window-titlebar-action": "this.buttonClass" } }, providers: [
        LocalizationService,
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.button'
        }
    ], exportAs: ["kendoWindowCloseAction"], usesInheritance: true, ngImport: i0, template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="close"
            [svgIcon]="xIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `, isInline: true, components: [{ type: i3.IconWrapperComponent, selector: "kendo-icon-wrapper", inputs: ["name", "svgIcon", "innerCssClass", "customFontClass", "size"], exportAs: ["kendoIconWrapper"] }], directives: [{ type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgClass, selector: "[ngClass]", inputs: ["class", "ngClass"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowCloseActionDirective, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoWindowCloseAction',
                    providers: [
                        LocalizationService,
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.button'
                        }
                    ],
                    selector: 'button[kendoWindowCloseAction]',
                    template: `
        <kendo-icon-wrapper
            *ngIf="!imageUrl && !iconClass"
            innerCssClass="k-button-icon"
            name="close"
            [svgIcon]="xIcon">
        </kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService, decorators: [{
                    type: Optional
                }] }, { type: i1.LocalizationService }, { type: i0.NgZone }]; }, propDecorators: { window: [{
                type: Input
            }], buttonType: [{
                type: HostBinding,
                args: ['attr.type']
            }], buttonClass: [{
                type: HostBinding,
                args: ['class.k-window-titlebar-action']
            }], onClick: [{
                type: HostListener,
                args: ['click']
            }] } });

/**
 * Represents the [Kendo UI Window component for Angular]({% slug overview_window_dialogs %}).
 */
class WindowComponent {
    constructor(el, renderer, service, navigation, ngZone, localization) {
        this.el = el;
        this.renderer = renderer;
        this.service = service;
        this.navigation = navigation;
        this.ngZone = ngZone;
        this.localization = localization;
        /**
         * Specifies if the content of the component is persisted in the DOM when minimized.
         * @default false
         */
        this.keepContent = false;
        /**
         * Fires when the user starts to move the Window.
         */
        this.dragStart = new EventEmitter();
        /**
         * Fires when the Window was moved by the user.
         */
        this.dragEnd = new EventEmitter();
        /**
         * Fires when the user starts to resize the Window.
         */
        this.resizeStart = new EventEmitter();
        /**
         * Fires when the Window was resized by the user.
         */
        this.resizeEnd = new EventEmitter();
        /**
         * Fires when the user closes the Window.
         */
        this.close = new EventEmitter();
        /**
         * Fires when the `width` property of the component was updated. The event is triggered only after the resizing
         * has ended. The event data contains the new width. Allows a two-way binding of the `width` property.
         */
        this.widthChange = new EventEmitter();
        /**
         * Fires when the `height` property of the component was updated. The event is triggered only after the resizing
         * has ended. The event data contains the new height. Allows a two-way binding of the `height` property.
         */
        this.heightChange = new EventEmitter();
        /**
         * Fires when the `top` property of the component was updated. The event is triggered only after the dragging
         * and resizing have ended. The event data contains the new top offset. Allows a two-way binding of the `top` property.
         */
        this.topChange = new EventEmitter();
        /**
         * Fires when the `left` property of the component was updated. The event is triggered only after the dragging
         * and resizing have ended. The event data contains the new left offset. Allows a two-way binding of the `left` property.
         */
        this.leftChange = new EventEmitter();
        /**
         * Fires when the `state` property of the component was updated. The event data contains the new state. Allows a
         * two-way binding of the `state` property.
         */
        this.stateChange = new EventEmitter();
        /**
         * @hidden
         */
        this.messages = {};
        /**
         * @hidden
         */
        this.showLicenseWatermark = false;
        this.tabIndex = 0;
        this.role = 'dialog';
        this.hostClass = true;
        /**
         * @hidden
         */
        this.titleId = null;
        this._themeColor = null;
        this.draged = false;
        this.resized = false;
        this.windowSubscription = new Subscription();
        this.domSubs = new Subscription();
        const isValid = validatePackage(packageMetadata);
        this.showLicenseWatermark = shouldShowValidationUI(isValid);
        this.direction = this.localization.rtl ? 'rtl' : 'ltr';
        this.localizationChangeSubscription = this.localization.changes
            .subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
        this.resizeDirections = RESIZE_DIRECTIONS;
        this.subscribeEvents();
        this.titleId = this.generateTitleId();
    }
    /**
     * Specifies whether the user will be able to drag the component.
     * @default true
     */
    set draggable(value) {
        this.options.draggable = value;
    }
    get draggable() {
        return this.options.draggable;
    }
    /**
     * Specifies whether the user will be able to resize the component.
     * @default true
     */
    set resizable(value) {
        this.options.resizable = value;
    }
    get resizable() {
        return this.options.resizable;
    }
    /**
     * The Window allows you to specify predefined theme colors.
     * The theme color will be applied as a background and border color to the titlebar while also amending the text color accordingly.
     *
     * The possible values are:
     * * `primary`
     * * `dark`
     * * `light`
     */
    set themeColor(themeColor) {
        this.handleThemeColorClass(this.themeColor, themeColor);
        this._themeColor = themeColor;
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * @hidden
     */
    set cssClass(classes) {
        this.setServiceClasses(this._cssClass, classes);
        this._cssClass = classes;
    }
    get cssClass() {
        return this._cssClass;
    }
    /**
     * @hidden
     */
    set htmlAttributes(attributes) {
        setHTMLAttributes(attributes, this.renderer, this.el.nativeElement);
        const el = this.el.nativeElement;
        const dir = el.getAttribute('dir');
        const tIndex = el.getAttribute('tabindex');
        if (this.direction !== dir) {
            this.direction = dir;
        }
        if (this.tabIndex !== tIndex) {
            this.tabIndex = tIndex;
        }
        this._htmlAttributes = attributes;
    }
    get htmlAttributes() {
        return this._htmlAttributes;
    }
    /**
     * Specifies the initial state of the component.
     * If not specified, the value is set to `default`.
     *
     * The possible values are:
     * * `minimized`
     * * `maximized`
     * * `default`
     */
    set state(value) {
        this.options.state = value;
    }
    get state() {
        return this.options.state;
    }
    /**
     * Specifies the minimum width of the component.
     * The `minWidth` property has to be set in pixels.
     * @default 120
     */
    set minWidth(value) {
        this.setOption('minWidth', value);
    }
    get minWidth() {
        return this.options.minWidth;
    }
    /**
     * Specifies the minimum height of the Window.
     * The `minHeight` property has to be set in pixels.
     * @default 100
     */
    set minHeight(value) {
        this.setOption('minHeight', value);
    }
    get minHeight() {
        return this.options.minHeight;
    }
    /**
     * Specifies the width of the Window.
     * The `width` property has to be set in pixels.
     */
    set width(value) {
        this.setOption('width', value);
    }
    get width() {
        return this.options.width;
    }
    /**
     * Specifies the height of the Window.
     * The `height` property has to be set in pixels.
     */
    set height(value) {
        this.setOption('height', value);
    }
    get height() {
        return this.options.height;
    }
    /**
     * Specifies the initial top offset of the Window.
     * The `top` property has to be set in pixels.
     */
    set top(value) {
        this.setOption('top', value);
    }
    get top() {
        return this.options.top;
    }
    /**
     * Specifies the initial left offset of the Window.
     * Numeric values are treated as pixels.
     */
    set left(value) {
        this.setOption('left', value);
    }
    get left() {
        return this.options.left;
    }
    get closeButtonTitle() {
        if (this.messages && this.messages.closeTitle) {
            return this.messages.closeTitle;
        }
        return this.localization.get('closeTitle');
    }
    get restoreButtonTitle() {
        if (this.messages && this.messages.restoreTitle) {
            return this.messages.restoreTitle;
        }
        return this.localization.get('restoreTitle');
    }
    get maximizeButtonTitle() {
        if (this.messages && this.messages.maximizeTitle) {
            return this.messages.maximizeTitle;
        }
        return this.localization.get('maximizeTitle');
    }
    get minimizeButtonTitle() {
        if (this.messages && this.messages.minimizeTitle) {
            return this.messages.minimizeTitle;
        }
        return this.localization.get('minimizeTitle');
    }
    get dir() {
        return this.direction;
    }
    ngAfterViewInit() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.setNextZIndex();
        this.handleInitialFocus();
        this.ngZone.runOutsideAngular(() => Promise.resolve(null).then(() => this.setInitialOffset()));
        this.initDomEvents();
        if (this.titleBarView || this.titleBarContent) {
            this.renderer.setAttribute(this.el.nativeElement, 'aria-labelledby', this.titleId);
        }
        this.handleThemeColorClass(null, this.themeColor);
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.el.nativeElement, 'title');
        this.service.init(this.el);
    }
    ngOnChanges(changes) {
        OFFSET_STYLES.forEach((style) => {
            if (isChanged(style, changes)) {
                this.setStyle(style, this.options[style]);
            }
        });
        if (isChanged('draggable', changes)) {
            const titleBar = isPresent(this.titleBarContent) ? this.titleBarContent : this.titleBarView;
            if (isTruthy(changes['draggable'].currentValue)) {
                titleBar.subscribeDrag();
            }
            else {
                titleBar.unsubscribeDrag();
            }
        }
        if (isChanged('state', changes)) {
            if (isPresent(this.service.lastAction)) {
                this.service.lastAction = null;
            }
            else {
                this.service.applyManualState();
                this.updateAllOffset();
            }
        }
    }
    ngOnDestroy() {
        if (this.windowSubscription) {
            this.windowSubscription.unsubscribe();
        }
        if (this.domSubs) {
            this.domSubs.unsubscribe();
        }
        this.localizationChangeSubscription.unsubscribe();
    }
    /**
     * Focuses the wrapper of the Window component.
     */
    focus() {
        const wrapper = this.el.nativeElement;
        if (isPresent(wrapper)) {
            wrapper.focus();
        }
    }
    /**
     * Brings the current Window component on top of other Window components on the page.
     */
    bringToFront() {
        this.setNextZIndex();
    }
    /**
     * Manually updates the `width` or `height` option of the Window.
     * The required style will be applied to the Window wrapper element and the
     * corresponding property of the component instance will be updated.
     * This method is intended to be used for sizing dynamically created components using the
     * [`WindowService`]({% slug api_dialog_windowservice %})
     * @param {WindowDimensionSetting} dimension - The option that will be updated
     * @param {number} value - The value set in pixels
     */
    setDimension(dimension, value) {
        this.setOption(dimension, value);
        this.setStyle(dimension, value);
    }
    /**
     * Manually updates the `top` or `left` offset of the Window.
     * The required style will be applied to the Window wrapper element and the
     * corresponding property of the component instance will be updated.
     * This method is intended to be used for positioning dynamically created components using the
     * [`WindowService`]({% slug api_dialog_windowservice %})
     * @param {WindowOffsetSetting} offset - The option that will be updated
     * @param {number} value - The value set in pixels
     */
    setOffset(offset, value) {
        this.setOption(offset, value);
        this.setStyle(offset, value);
    }
    get showDefaultTitleBar() {
        return !isPresent(this.titleBarContent);
    }
    get styleMinWidth() {
        return this.minWidth + 'px';
    }
    get styleMinHeight() {
        return this.minHeight + 'px';
    }
    get stylePosition() {
        return this.options.position;
    }
    get wrapperMaximizedClass() {
        return this.state === 'maximized';
    }
    get wrapperMinimizedClass() {
        return this.state === 'minimized';
    }
    /**
     * @hidden
     */
    onComponentFocus() {
        this.renderer.addClass(this.el.nativeElement, 'k-focus');
        this.setNextZIndex();
    }
    /**
     * @hidden
     */
    onComponentBlur() {
        this.renderer.removeClass(this.el.nativeElement, 'k-focus');
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.windowSubscription.add(this.service.focus.subscribe(() => {
            this.el.nativeElement.focus();
        }));
        this.windowSubscription.add(this.service.dragStart.subscribe(() => {
            this.draged = true;
            this.ngZone.run(() => {
                this.dragStart.emit();
            });
        }));
        this.windowSubscription.add(this.service.dragEnd.subscribe(() => {
            if (this.draged) {
                this.draged = false;
                this.ngZone.run(() => {
                    this.dragEnd.emit();
                });
            }
        }));
        this.windowSubscription.add(this.service.close.subscribe(() => {
            this.close.emit();
        }));
        this.windowSubscription.add(this.service.resizeStart.subscribe(() => {
            this.resized = true;
            this.ngZone.run(() => {
                this.resizeStart.emit();
            });
        }));
        this.windowSubscription.add(this.service.resizeEnd.subscribe(() => {
            if (this.resized) {
                this.resized = false;
                this.ngZone.run(() => {
                    this.resizeEnd.emit();
                });
            }
        }));
        this.windowSubscription.add(this.service.change.subscribe((ev) => {
            OFFSET_STYLES.forEach((style) => {
                if (isPresent(ev[style])) {
                    this.setStyle(style, ev[style]);
                    if (this.state !== 'maximized') {
                        const emitter = this[style + 'Change'];
                        if (emitter.observers.length) {
                            this.ngZone.run(() => {
                                emitter.emit(ev[style]);
                            });
                        }
                    }
                }
            });
        }));
        this.windowSubscription.add(this.service.stateChange.subscribe((state) => {
            if (isPresent(this.service.lastAction)) {
                this.updateAllOffset();
                this.stateChange.emit(state);
            }
        }));
    }
    initDomEvents() {
        if (!this.el) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.domSubs.add(this.renderer.listen(this.el.nativeElement, 'keydown', (ev) => {
                this.onKeyDown(ev);
            }));
        });
    }
    onKeyDown(event) {
        if (hasClasses(event.target, WINDOW_CLASSES)) {
            this.navigation.process(event);
        }
    }
    setServiceClasses(prevValue, value) {
        const el = this.el.nativeElement;
        if (prevValue) {
            parseCSSClassNames(prevValue).forEach(className => {
                this.renderer.removeClass(el, className);
            });
        }
        if (value) {
            parseCSSClassNames(value).forEach(className => {
                this.renderer.addClass(el, className);
            });
        }
    }
    setNextZIndex() {
        const currentZIndex = this.el.nativeElement.style['z-index'];
        const nextPossibleZIndex = this.service.nextPossibleZIndex;
        if (!currentZIndex || (nextPossibleZIndex - currentZIndex > 1)) {
            this.renderer.setStyle(this.el.nativeElement, "z-index", this.service.nextZIndex);
        }
    }
    setInitialOffset() {
        if (this.state !== 'maximized') {
            this.updateAllOffset();
            if (!isPresent(this.left) || !isPresent(this.top)) {
                this.service.center();
            }
        }
        else {
            const viewPort = this.service.windowViewPort;
            this.setStyle('width', viewPort.width);
            this.setStyle('height', viewPort.height);
            this.setStyle('top', 0);
            this.setStyle('left', 0);
        }
    }
    updateAllOffset() {
        OFFSET_STYLES.forEach((style) => {
            if (isPresent(this[style])) {
                this.setStyle(style, this[style]);
            }
            else {
                this.removeStyle(style);
            }
        });
    }
    setStyle(style, value) {
        this.renderer.setStyle(this.el.nativeElement, style, value + 'px');
    }
    removeStyle(style) {
        this.renderer.removeStyle(this.el.nativeElement, style);
    }
    get options() {
        return this.service.options;
    }
    setOption(style, value) {
        if (typeof value !== 'number' && typeof value !== 'string') {
            return;
        }
        const parsedValue = (typeof value === 'number') ? value : parseInt(value, 10);
        this.options[style] = parsedValue;
        this.service.setRestoreOption(style, parsedValue);
    }
    handleInitialFocus() {
        const wrapper = this.el.nativeElement;
        if (this.autoFocusedElement) {
            const initiallyFocusedElement = wrapper.querySelector(this.autoFocusedElement);
            if (initiallyFocusedElement) {
                initiallyFocusedElement.focus();
            }
        }
        else {
            this.focus();
        }
    }
    /**
     * @hidden
     */
    generateTitleId() {
        return 'kendo-window-title-' + Math.ceil(Math.random() * 1000000).toString();
    }
    handleThemeColorClass(previousValue, currentValue) {
        const wrapper = this.el.nativeElement;
        if (previousValue) {
            const classToRemove = `k-window-${previousValue}`;
            this.renderer.removeClass(wrapper, classToRemove);
        }
        if (currentValue) {
            const classToAdd = `k-window-${currentValue}`;
            this.renderer.addClass(wrapper, classToAdd);
        }
    }
}
WindowComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowComponent, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: DragResizeService }, { token: NavigationService }, { token: i0.NgZone }, { token: i1.LocalizationService }], target: i0.ɵɵFactoryTarget.Component });
WindowComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: WindowComponent, selector: "kendo-window", inputs: { autoFocusedElement: "autoFocusedElement", title: "title", draggable: "draggable", resizable: "resizable", themeColor: "themeColor", keepContent: "keepContent", state: "state", minWidth: "minWidth", minHeight: "minHeight", width: "width", height: "height", top: "top", left: "left" }, outputs: { dragStart: "dragStart", dragEnd: "dragEnd", resizeStart: "resizeStart", resizeEnd: "resizeEnd", close: "close", widthChange: "widthChange", heightChange: "heightChange", topChange: "topChange", leftChange: "leftChange", stateChange: "stateChange" }, host: { listeners: { "focus": "onComponentFocus()", "blur": "onComponentBlur()" }, properties: { "attr.tabIndex": "this.tabIndex", "attr.role": "this.role", "class.k-window": "this.hostClass", "attr.dir": "this.dir", "style.minWidth": "this.styleMinWidth", "style.minHeight": "this.styleMinHeight", "style.position": "this.stylePosition", "class.k-window-maximized": "this.wrapperMaximizedClass", "class.k-window-minimized": "this.wrapperMinimizedClass" } }, providers: [
        DragResizeService,
        NavigationService,
        LocalizationService,
        {
            provide: L10N_PREFIX,
            useValue: 'kendo.window'
        }
    ], queries: [{ propertyName: "titleBarContent", first: true, predicate: WindowTitleBarComponent, descendants: true }], viewQueries: [{ propertyName: "titleBarView", first: true, predicate: WindowTitleBarComponent, descendants: true }, { propertyName: "resizeHandles", predicate: ResizeHandleDirective, descendants: true }], exportAs: ["kendoWindow"], usesOnChanges: true, ngImport: i0, template: `
        <ng-container kendoWindowLocalizedMessages
            i18n-closeTitle="kendo.window.closeTitle|The title of the close button"
            closeTitle="Close"

            i18n-restoreTitle="kendo.window.restoreTitle|The title of the restore button"
            restoreTitle="Restore"

            i18n-maximizeTitle="kendo.window.maximizeTitle|The title of the maximize button"
            maximizeTitle="Maximize"

            i18n-minimizeTitle="kendo.window.minimizeTitle|The title of the minimize button"
            minimizeTitle="Minimize"
        >
        <ng-container>

        <kendo-window-titlebar *ngIf="showDefaultTitleBar" [template]="titleBarTemplate" [id]="titleId">
            <span class="k-window-title">{{ title }}</span>
            <div class="k-window-titlebar-actions">
                <button kendoWindowMinimizeAction  [attr.title]="minimizeButtonTitle" [attr.aria-label]="minimizeButtonTitle"></button>
                <button kendoWindowMaximizeAction [attr.title]="maximizeButtonTitle" [attr.aria-label]="maximizeButtonTitle"></button>
                <button kendoWindowRestoreAction [attr.title]="restoreButtonTitle" [attr.aria-label]="restoreButtonTitle"></button>
                <button kendoWindowCloseAction [attr.title]="closeButtonTitle" [attr.aria-label]="closeButtonTitle"></button>
            </div>
        </kendo-window-titlebar>
        <ng-content select="kendo-window-titlebar" *ngIf="!showDefaultTitleBar"></ng-content>

        <div *ngIf="state !== 'minimized' || keepContent"
            [hidden]="state === 'minimized' && keepContent"
            class="k-window-content"
        >
            <ng-content *ngIf="!contentTemplate"></ng-content>
            <ng-template [ngTemplateOutlet]="contentTemplate" *ngIf="contentTemplate"></ng-template>
        </div>

        <ng-template [ngIf]='resizable'>
            <div *ngFor='let dir of resizeDirections'
                [direction]="dir"
                kendoWindowResizeHandle
                kendoDraggable>
            </div>
        </ng-template>

        <div kendoWatermarkOverlay *ngIf="showLicenseWatermark"></div>
    `, isInline: true, components: [{ type: WindowTitleBarComponent, selector: "kendo-window-titlebar", inputs: ["template", "id"] }, { type: WindowMinimizeActionDirective, selector: "button[kendoWindowMinimizeAction]", inputs: ["window"], exportAs: ["kendoWindowMinimizeAction"] }, { type: WindowMaximizeActionDirective, selector: "button[kendoWindowMaximizeAction]", inputs: ["window"], exportAs: ["kendoWindowMaximizeAction"] }, { type: WindowRestoreActionDirective, selector: "button[kendoWindowRestoreAction]", inputs: ["window"], exportAs: ["kendoWindowRestoreAction"] }, { type: WindowCloseActionDirective, selector: "button[kendoWindowCloseAction]", inputs: ["window"], exportAs: ["kendoWindowCloseAction"] }, { type: i1$1.WatermarkOverlayComponent, selector: "div[kendoWatermarkOverlay]" }], directives: [{ type: LocalizedMessagesDirective, selector: "\n    [kendoDialogLocalizedMessages],\n    [kendoWindowLocalizedMessages],\n    [kendoDialogTitleBarLocalizedMessages]\n  " }, { type: i4.NgIf, selector: "[ngIf]", inputs: ["ngIf", "ngIfThen", "ngIfElse"] }, { type: i4.NgTemplateOutlet, selector: "[ngTemplateOutlet]", inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"] }, { type: i4.NgForOf, selector: "[ngFor][ngForOf]", inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"] }, { type: ResizeHandleDirective, selector: "[kendoWindowResizeHandle]", inputs: ["direction"] }, { type: i1$1.DraggableDirective, selector: "[kendoDraggable]", inputs: ["enableDrag"], outputs: ["kendoPress", "kendoDrag", "kendoRelease"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowComponent, decorators: [{
            type: Component,
            args: [{
                    exportAs: 'kendoWindow',
                    providers: [
                        DragResizeService,
                        NavigationService,
                        LocalizationService,
                        {
                            provide: L10N_PREFIX,
                            useValue: 'kendo.window'
                        }
                    ],
                    selector: 'kendo-window',
                    template: `
        <ng-container kendoWindowLocalizedMessages
            i18n-closeTitle="kendo.window.closeTitle|The title of the close button"
            closeTitle="Close"

            i18n-restoreTitle="kendo.window.restoreTitle|The title of the restore button"
            restoreTitle="Restore"

            i18n-maximizeTitle="kendo.window.maximizeTitle|The title of the maximize button"
            maximizeTitle="Maximize"

            i18n-minimizeTitle="kendo.window.minimizeTitle|The title of the minimize button"
            minimizeTitle="Minimize"
        >
        <ng-container>

        <kendo-window-titlebar *ngIf="showDefaultTitleBar" [template]="titleBarTemplate" [id]="titleId">
            <span class="k-window-title">{{ title }}</span>
            <div class="k-window-titlebar-actions">
                <button kendoWindowMinimizeAction  [attr.title]="minimizeButtonTitle" [attr.aria-label]="minimizeButtonTitle"></button>
                <button kendoWindowMaximizeAction [attr.title]="maximizeButtonTitle" [attr.aria-label]="maximizeButtonTitle"></button>
                <button kendoWindowRestoreAction [attr.title]="restoreButtonTitle" [attr.aria-label]="restoreButtonTitle"></button>
                <button kendoWindowCloseAction [attr.title]="closeButtonTitle" [attr.aria-label]="closeButtonTitle"></button>
            </div>
        </kendo-window-titlebar>
        <ng-content select="kendo-window-titlebar" *ngIf="!showDefaultTitleBar"></ng-content>

        <div *ngIf="state !== 'minimized' || keepContent"
            [hidden]="state === 'minimized' && keepContent"
            class="k-window-content"
        >
            <ng-content *ngIf="!contentTemplate"></ng-content>
            <ng-template [ngTemplateOutlet]="contentTemplate" *ngIf="contentTemplate"></ng-template>
        </div>

        <ng-template [ngIf]='resizable'>
            <div *ngFor='let dir of resizeDirections'
                [direction]="dir"
                kendoWindowResizeHandle
                kendoDraggable>
            </div>
        </ng-template>

        <div kendoWatermarkOverlay *ngIf="showLicenseWatermark"></div>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: DragResizeService }, { type: NavigationService }, { type: i0.NgZone }, { type: i1.LocalizationService }]; }, propDecorators: { autoFocusedElement: [{
                type: Input
            }], title: [{
                type: Input
            }], draggable: [{
                type: Input
            }], resizable: [{
                type: Input
            }], themeColor: [{
                type: Input
            }], keepContent: [{
                type: Input
            }], state: [{
                type: Input
            }], minWidth: [{
                type: Input
            }], minHeight: [{
                type: Input
            }], width: [{
                type: Input
            }], height: [{
                type: Input
            }], top: [{
                type: Input
            }], left: [{
                type: Input
            }], dragStart: [{
                type: Output
            }], dragEnd: [{
                type: Output
            }], resizeStart: [{
                type: Output
            }], resizeEnd: [{
                type: Output
            }], close: [{
                type: Output
            }], widthChange: [{
                type: Output
            }], heightChange: [{
                type: Output
            }], topChange: [{
                type: Output
            }], leftChange: [{
                type: Output
            }], stateChange: [{
                type: Output
            }], tabIndex: [{
                type: HostBinding,
                args: ['attr.tabIndex']
            }], role: [{
                type: HostBinding,
                args: ['attr.role']
            }], hostClass: [{
                type: HostBinding,
                args: ['class.k-window']
            }], dir: [{
                type: HostBinding,
                args: ['attr.dir']
            }], titleBarView: [{
                type: ViewChild,
                args: [WindowTitleBarComponent, { static: false }]
            }], titleBarContent: [{
                type: ContentChild,
                args: [WindowTitleBarComponent, { static: false }]
            }], resizeHandles: [{
                type: ViewChildren,
                args: [ResizeHandleDirective]
            }], styleMinWidth: [{
                type: HostBinding,
                args: ['style.minWidth']
            }], styleMinHeight: [{
                type: HostBinding,
                args: ['style.minHeight']
            }], stylePosition: [{
                type: HostBinding,
                args: ['style.position']
            }], wrapperMaximizedClass: [{
                type: HostBinding,
                args: ['class.k-window-maximized']
            }], wrapperMinimizedClass: [{
                type: HostBinding,
                args: ['class.k-window-minimized']
            }], onComponentFocus: [{
                type: HostListener,
                args: ['focus']
            }], onComponentBlur: [{
                type: HostListener,
                args: ['blur']
            }] } });

/**
 * Indicates that the **Close** button of a Window that is opened through `WindowService` is clicked.
 */
class WindowCloseResult {
}

/**
 * Holds references to the object instance of the Window.
 * Controls the Windows that were opened through `WindowService`
 * ([see example]({% slug api_dialog_windowservice %}#toc-open)).
 */
class WindowRef {
}

/**
 * The settings for the Window actions when the Window is opened through `WindowService`
 * ([see example]({% slug api_dialog_windowservice %}#toc-open)).
 */
class WindowSettings {
}

/**
 * @hidden
 */
class WindowContainerService {
    set container(container) {
        WindowContainerService.container = container;
    }
    get container() {
        return WindowContainerService.container;
    }
}
WindowContainerService.container = null;
WindowContainerService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowContainerService, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
WindowContainerService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowContainerService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowContainerService, decorators: [{
            type: Injectable
        }] });

const isNotComponent = (component) => isString(component) || component instanceof TemplateRef;
class WindowInjector {
    constructor(getWindowRef, parentInjector) {
        this.getWindowRef = getWindowRef;
        this.parentInjector = parentInjector;
    }
    get(token, notFoundValue) {
        if (token === WindowRef) {
            return this.getWindowRef();
        }
        return this.parentInjector.get(token, notFoundValue);
    }
}
/**
 * A service for opening Windows dynamically
 * ([see example]({% slug service_window %})).
 */
class WindowService {
    constructor(
    /**
     * @hidden
     */
    resolver, containerService) {
        this.resolver = resolver;
        this.containerService = containerService;
    }
    /**
     * Opens a Window component.
     *
     * @param {WindowSettings} settings - The settings that define the Window.
     * @returns {WindowRef} - A reference to the Window object.
     *
     * @example
     *
     * ```ts-no-run
     * _@Component({
     *   selector: 'my-app',
     *   template: `
     *     <button kendoButton (click)="open()">Open window</button>
     *     <div kendoWindowContainer></div>
     *   `
     * })
     * export class AppComponent {
     *     constructor( private windowService: WindowService ) {}
     *
     *     public open() {
     *         var window = this.windowService.open({
     *           title: "My window",
     *           content: "My content!"
     *         });
     *
     *         window.result.subscribe((result) => {
     *           if (result instanceof WindowCloseResult) {
     *             console.log("Window was closed");
     *           }
     *         });
     *     }
     * }
     * ```
     *
     */
    open(settings) {
        const factory = this.resolver.resolveComponentFactory(WindowComponent);
        const container = settings.appendTo || this.containerService.container;
        if (!container) {
            throw new Error(`Cannot attach window to the page.
                Add an element that uses the kendoWindowContainer directive, or set the 'appendTo' property.
                See https://www.telerik.com/kendo-angular-ui/components/dialogs/window/service/
            `);
        }
        const windowRef = {
            close: () => { },
            content: null,
            result: null,
            window: null
        };
        const content = this.contentFrom(settings.content, container, windowRef);
        const window = container.createComponent(factory, undefined, undefined, content.nodes);
        windowRef.window = window;
        this.applyOptions(window.instance, settings);
        const apiClose = new Subject();
        const close = (e) => {
            apiClose.next(e || new WindowCloseResult());
            if (content.componentRef) {
                content.componentRef.destroy();
            }
            window.destroy();
        };
        const result = merge(apiClose, window.instance.close.pipe(map(e => (e ? e : new WindowCloseResult())), filter((e) => {
            if (settings.preventClose) {
                // add windowRef only when using component
                const windowRefParameter = isNotComponent(settings.content) ? undefined : windowRef;
                return !settings.preventClose(e, windowRefParameter);
            }
            return true;
        }))).pipe(take(1), 
        // Takes care for multiple subscriptions:
        // We subscribe internally and the user may subscribe to get a close result - window.result.subscribe().
        // This causes multiple subscriptions to the same source and thus multiple emissions. share() solves that.
        share());
        result.subscribe(close);
        windowRef.close = close;
        windowRef.result = result;
        window.changeDetectorRef.markForCheck();
        return windowRef;
    }
    applyOptions(instance, options) {
        if (isPresent(options.htmlAttributes)) {
            instance.htmlAttributes = options.htmlAttributes;
        }
        if (isPresent(options.cssClass)) {
            instance.cssClass = options.cssClass;
        }
        if (isPresent(options.title)) {
            instance.title = options.title;
        }
        if (isPresent(options.keepContent)) {
            instance.keepContent = options.keepContent;
        }
        if (isPresent(options.width)) {
            instance.width = options.width;
        }
        if (isPresent(options.minWidth)) {
            instance.minWidth = options.minWidth;
        }
        if (isPresent(options.height)) {
            instance.height = options.height;
        }
        if (isPresent(options.minHeight)) {
            instance.minHeight = options.minHeight;
        }
        if (isPresent(options.left)) {
            instance.left = options.left;
        }
        if (isPresent(options.top)) {
            instance.top = options.top;
        }
        if (isPresent(options.draggable)) {
            instance.draggable = options.draggable;
        }
        if (isPresent(options.resizable)) {
            instance.resizable = options.resizable;
        }
        if (isPresent(options.messages && options.messages.closeTitle)) {
            instance.messages.closeTitle = options.messages.closeTitle;
        }
        if (isPresent(options.messages && options.messages.restoreTitle)) {
            instance.messages.restoreTitle = options.messages.restoreTitle;
        }
        if (isPresent(options.messages && options.messages.maximizeTitle)) {
            instance.messages.maximizeTitle = options.messages.maximizeTitle;
        }
        if (isPresent(options.messages && options.messages.minimizeTitle)) {
            instance.messages.minimizeTitle = options.messages.minimizeTitle;
        }
        if (isPresent(options.autoFocusedElement)) {
            instance.autoFocusedElement = options.autoFocusedElement;
        }
        if (isPresent(options.state)) {
            instance.state = options.state;
            if (options.state === 'minimized') {
                instance.keepContent = true;
            }
        }
        if (options.content instanceof TemplateRef) {
            instance.contentTemplate = options.content;
        }
        if (options.titleBarContent instanceof TemplateRef) {
            instance.titleBarTemplate = options.titleBarContent;
        }
    }
    contentFrom(content, container, windowRef) {
        const renderer = container.injector.get(Renderer2);
        let nodes = [];
        let componentRef = null;
        if (typeof content === 'string') {
            nodes = [renderer.createText(content)];
        }
        else if (content && !(content instanceof TemplateRef)) {
            const injector = new WindowInjector(() => windowRef, container.injector);
            const factory = this.resolver.resolveComponentFactory(content);
            componentRef = container.createComponent(factory, undefined, injector);
            nodes = [componentRef.location.nativeElement];
            windowRef.content = componentRef;
        }
        return {
            componentRef,
            nodes: [
                [],
                nodes // Content
            ]
        };
    }
}
WindowService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowService, deps: [{ token: i0.ComponentFactoryResolver }, { token: WindowContainerService }], target: i0.ɵɵFactoryTarget.Injectable });
WindowService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: i0.ComponentFactoryResolver }, { type: WindowContainerService, decorators: [{
                    type: Inject,
                    args: [WindowContainerService]
                }] }]; } });

/**
 * Provides an insertion point for the Dialogs which are created through the
 * Dialog service ([see example]({% slug api_dialog_dialogservice %}#toc-open)).
 * Created Dialogs will be mounted after that element.
 *
 * @example
 * ```html-no-run
 * <div kendoDialogContainer></div>
 * ```
 */
class DialogContainerDirective {
    constructor(container, service) {
        service.container = container;
    }
}
DialogContainerDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContainerDirective, deps: [{ token: i0.ViewContainerRef }, { token: DialogContainerService }], target: i0.ɵɵFactoryTarget.Directive });
DialogContainerDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DialogContainerDirective, selector: "[kendoDialogContainer]", ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogContainerDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDialogContainer]'
                }]
        }], ctorParameters: function () { return [{ type: i0.ViewContainerRef }, { type: DialogContainerService }]; } });

/**
 * Custom component messages override default component messages
 * ([see example]({% slug globalization_dialogs %}#toc-localization)).
 */
class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
}
CustomMessagesComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: CustomMessagesComponent, deps: [{ token: i1.LocalizationService }], target: i0.ɵɵFactoryTarget.Directive });
CustomMessagesComponent.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: CustomMessagesComponent, selector: "kendo-dialog-messages, kendo-window-messages", providers: [
        {
            provide: Messages,
            useExisting: forwardRef(() => CustomMessagesComponent)
        }
    ], usesInheritance: true, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: CustomMessagesComponent, decorators: [{
            type: Directive,
            args: [{
                    providers: [
                        {
                            provide: Messages,
                            useExisting: forwardRef(() => CustomMessagesComponent)
                        }
                    ],
                    // eslint-disable-next-line @angular-eslint/directive-selector
                    selector: 'kendo-dialog-messages, kendo-window-messages'
                }]
        }], ctorParameters: function () { return [{ type: i1.LocalizationService }]; } });

/**
 * @hidden
 */
const SHARED_DIRECTIVES = [
    DialogActionsComponent,
    CustomMessagesComponent,
    LocalizedMessagesDirective
];
/**
 * @hidden
 */
class SharedModule {
}
SharedModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SharedModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
SharedModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SharedModule, declarations: [DialogActionsComponent,
        CustomMessagesComponent,
        LocalizedMessagesDirective], imports: [CommonModule, IconsModule, ButtonModule], exports: [DialogActionsComponent,
        CustomMessagesComponent,
        LocalizedMessagesDirective, CommonModule, IconsModule, ButtonModule] });
SharedModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SharedModule, imports: [[CommonModule, IconsModule, ButtonModule], CommonModule, IconsModule, ButtonModule] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: SharedModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [SHARED_DIRECTIVES],
                    exports: [SHARED_DIRECTIVES, CommonModule, IconsModule, ButtonModule],
                    imports: [CommonModule, IconsModule, ButtonModule]
                }]
        }] });

/**
 * @hidden
 */
const DIALOG_DIRECTIVES = [
    DialogComponent,
    DialogTitleBarComponent
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Dialog component that includes all Dialog components and directives.
 * Imports `DialogModule` into the [root module](link:site.data.urls.angular['ngmodules']#angular-modularity)
 * of your application or into any other sub-module that will use the Dialog component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { DialogModule } from '@progress/kendo-angular-dialog';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, DialogModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class DialogModule {
}
DialogModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
DialogModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogModule, declarations: [DialogComponent,
        DialogTitleBarComponent, DialogContainerDirective], imports: [SharedModule, WatermarkModule], exports: [DialogComponent,
        DialogTitleBarComponent, DialogActionsComponent, CustomMessagesComponent, LocalizedMessagesDirective, DialogContainerDirective] });
DialogModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogModule, providers: [DialogContainerService, DialogService], imports: [[SharedModule, WatermarkModule]] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [DIALOG_DIRECTIVES, DialogContainerDirective],
                    entryComponents: [DIALOG_DIRECTIVES],
                    exports: [DIALOG_DIRECTIVES, SHARED_DIRECTIVES, DialogContainerDirective],
                    imports: [SharedModule, WatermarkModule],
                    providers: [DialogContainerService, DialogService]
                }]
        }] });

/**
 * Provides an insertion point for the Windows which are created through the
 * Window service ([see example]({% slug api_dialog_windowservice %}#toc-open)).
 * Created Windows will be mounted after that element.
 *
 * @example
 * ```html-no-run
 * <div kendoWindowContainer></div>
 * ```
 */
class WindowContainerDirective {
    constructor(container, service) {
        service.container = container;
    }
}
WindowContainerDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowContainerDirective, deps: [{ token: i0.ViewContainerRef }, { token: WindowContainerService }], target: i0.ɵɵFactoryTarget.Directive });
WindowContainerDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: WindowContainerDirective, selector: "[kendoWindowContainer]", ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowContainerDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoWindowContainer]'
                }]
        }], ctorParameters: function () { return [{ type: i0.ViewContainerRef }, { type: WindowContainerService }]; } });

const WINDOW_DIRECTIVES = [
    ResizeHandleDirective,
    WindowComponent,
    WindowTitleBarComponent,
    WindowCloseActionDirective,
    WindowMinimizeActionDirective,
    WindowMaximizeActionDirective,
    WindowRestoreActionDirective
];
const ENTRY_COMPONENTS = [
    WindowComponent,
    WindowTitleBarComponent
];
const exportedModules = [
    WindowComponent,
    WindowCloseActionDirective,
    WindowMinimizeActionDirective,
    WindowMaximizeActionDirective,
    WindowRestoreActionDirective,
    WindowTitleBarComponent
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Window component. Imports `WindowModule` into the
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity)
 * of your application or into any other sub-module that will use the Window component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { WindowModule } from '@progress/kendo-angular-window';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, WindowModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class WindowModule {
}
WindowModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
WindowModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowModule, declarations: [ResizeHandleDirective,
        WindowComponent,
        WindowTitleBarComponent,
        WindowCloseActionDirective,
        WindowMinimizeActionDirective,
        WindowMaximizeActionDirective,
        WindowRestoreActionDirective, WindowContainerDirective], imports: [SharedModule, DraggableModule, WatermarkModule], exports: [WindowComponent,
        WindowCloseActionDirective,
        WindowMinimizeActionDirective,
        WindowMaximizeActionDirective,
        WindowRestoreActionDirective,
        WindowTitleBarComponent, DialogActionsComponent, CustomMessagesComponent, LocalizedMessagesDirective, WindowContainerDirective] });
WindowModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowModule, providers: [WindowContainerService, WindowService], imports: [[SharedModule, DraggableModule, WatermarkModule]] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: WindowModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [WINDOW_DIRECTIVES, WindowContainerDirective],
                    entryComponents: [ENTRY_COMPONENTS],
                    exports: [exportedModules, SHARED_DIRECTIVES, WindowContainerDirective],
                    imports: [SharedModule, DraggableModule, WatermarkModule],
                    providers: [WindowContainerService, WindowService]
                }]
        }] });

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Dialogs components.
 *
 * @example
 *
 * ```ts
 * import { DialogsModule } from '@progress/kendo-angular-dialog';
 *
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { NgModule } from '@angular/core';
 *
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, DialogsModule],
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class DialogsModule {
}
DialogsModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
DialogsModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogsModule, exports: [DialogModule, WindowModule] });
DialogsModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogsModule, imports: [DialogModule, WindowModule] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DialogsModule, decorators: [{
            type: NgModule,
            args: [{
                    exports: [DialogModule, WindowModule]
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { CustomMessagesComponent, DialogAction, DialogActionsComponent, DialogCloseResult, DialogComponent, DialogContainerDirective, DialogContainerService, DialogContentBase, DialogModule, DialogRef, DialogService, DialogSettings, DialogTitleBarComponent, DialogsModule, DragResizeService, LocalizedMessagesDirective, Messages, NavigationService, PreventableEvent, WindowCloseActionDirective, WindowCloseResult, WindowComponent, WindowContainerDirective, WindowMaximizeActionDirective, WindowMinimizeActionDirective, WindowModule, WindowRef, WindowRestoreActionDirective, WindowService, WindowSettings, WindowTitleBarComponent };

