import { assertInInjectionContext, Injector, inject, runInInjectionContext } from '@angular/core';

function assertInjector(fn, injector, runner) {
    !injector && assertInInjectionContext(fn);
    const assertedInjector = injector ?? inject(Injector);
    if (!runner)
        return assertedInjector;
    return runInInjectionContext(assertedInjector, runner);
}

/**
 * Generated bundle index. Do not edit.
 */

export { assertInjector };

