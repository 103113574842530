<kendo-dropdownlist
    [data]="data"
    (valueChange)="onChange($event)"
    [defaultItem]="defaultItem"
    [value]="selectedValue"
    [valuePrimitive]="true"
    [filterable]="filterable"
    [kendoDropDownFilter]="filterSettings"
    [textField]="textField"
    [valueField]="valueField"
>
</kendo-dropdownlist>
