import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public entity = CONSTANTS.entity.file;
  public entityFolder = CONSTANTS.entity.folder;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  loadData(): Observable<any> {
    let payload = {
      file: true,
      folder: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  loadFullImg(id: number): Observable<any> {
    return this.getAction(this.entity + '/' + id + '/loadImg');
  }

  addFile(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  editFile(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  deleteFile(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  addFolder(payload: any): Observable<any> {
    return this.postAction(this.entityFolder + CONSTANTS.api.add, payload);
  }

  editFolder(id: any, payload: any): Observable<any> {
    return this.postAction(this.entityFolder + '/' + id + CONSTANTS.api.edit, payload);
  }

  deleteFolder(payload: any): Observable<any> {
    return this.postAction(this.entityFolder + CONSTANTS.api.delete, payload);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        if (x.status === 400 && x.error?.errors['Data.Extension']) {
          return of({
            success: false,
            errorCode: x.status,
            errorDescription: x.error?.errors['Data.Extension'][0],
          });
        }
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
