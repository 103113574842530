import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormErrorHandler, FormErrorTranslatorComponent } from '@cue/admin-shared';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ImageErrorDirective } from '@cue/admin-core';

@Component({
  selector: 'app-localization-input',
  templateUrl: './localization-input.component.html',
  styleUrl: './localization-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormErrorTranslatorComponent, TabStripModule, ImageErrorDirective],
  standalone: true,
})
export class LocalizationInputComponent extends FormErrorHandler implements OnInit, OnChanges {
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) label: string;
  @Input() isRequired = true;
  @Input({ required: true }) userTranslations: { id: string; code: string }[] = [];
  @Input() placeholder: string = undefined;
  @Input() hideLabel = false;
  @Input() formSaveClicked = false;
  public parentForm: FormGroup;

  constructor(public controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnInit() {
    this.parentForm = this.controlContainer.control as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formSaveClicked?.currentValue) {
      this.parentForm.get(this.controlName).markAsTouched();
      this.parentForm.get(this.controlName).markAsDirty();
    }
  }

  public hasError(controlName: string): boolean {
    return this.parentForm.get(controlName).invalid;
  }
}
