import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, timeoutWith } from 'rxjs/operators';
import { CONSTANTS } from '@cue/admin-constants';

import { ConfigService } from './config.service';
import { ServersPageModel, ApiResponseModel } from '@cue/admin-shared';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ServersService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
  ) {}

  actionLoad(): Observable<ApiResponseModel<ServersPageModel>> {
    const url = `${this.config.value.apiURL + CONSTANTS.entity.server}`;
    return this.http.get<ApiResponseModel<ServersPageModel>>(url).pipe(
      timeoutWith(30000, throwError({ status: CONSTANTS.myError.timeoutError })),
      map((response: ApiResponseModel<ServersPageModel>) => {
        return response as ApiResponseModel<ServersPageModel>;
      }),
    );
  }
}
