/* src/app/shared/components/localization-grid-input/localization-grid-input.component.scss */
:host .flag {
  max-width: 20px;
  margin-right: 0.25rem;
}
:host ::ng-deep .k-tabstrip {
  height: 90px;
}
:host ::ng-deep .k-tabstrip-content {
  height: 90px;
  box-shadow: none;
  border: none;
  padding: 1px 4px;
  background-color: transparent;
}
/*# sourceMappingURL=localization-grid-input.component.css.map */
