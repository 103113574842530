/* src/app/shared/components/copyright/copyright.component.scss */
:host {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
:host > *:not(a) {
  font-weight: 500;
  opacity: 0.9;
}
/*# sourceMappingURL=copyright.component.css.map */
