import { AbstractControl, ValidatorFn } from '@angular/forms';

export class WhiteSpaceValidator {
  static whiteSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      if (value.startsWith(' ')) {
        return {
          trimErrorLeading: true,
        };
      }
      if (value.endsWith(' ')) {
        return {
          trimErrorTrailing: true,
        };
      }
      if (value.includes(' ')) {
        return {
          whiteSpaceError: true,
        };
      }
      return null;
    };
  }
}
