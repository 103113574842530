/* src/app/shared/components/localization-input/localization-input.component.scss */
:host .flag {
  max-width: 20px;
  margin-right: 0.25rem;
}
:host ::ng-deep .k-tabstrip {
  height: 90px;
}
:host ::ng-deep .k-tabstrip-content {
  height: 90px;
  box-shadow: none;
  border: none;
  padding: 1px 4px;
}
:host .tab-error {
  font-size: 15px;
  margin-left: 0.25rem;
}
/*# sourceMappingURL=localization-input.component.css.map */
