import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-dropdownlist-multi-filter',
  templateUrl: './dropdownlist-multi-filter.component.html',
  styleUrls: ['./dropdownlist-multi-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MultiSelectModule],
  standalone: true,
})
export class DropdownlistMultiFilterComponent extends BaseFilterCellComponent {
  public get selectedValue(): any {
    const filters = this.filtersByField(this.columnName);
    const multiValue = [];
    if (this.data) {
      filters.forEach((filter) => {
        const dataItem = this.data.find((x) => x.id === filter.value || x.id === filter.value.id);
        if (dataItem) {
          multiValue.push({ name: dataItem.name, id: dataItem.id });
        }
      });
    }
    return multiValue.length > 0 ? multiValue : null;
  }

  @Input() public filter: any;
  @Input() public data: any[];
  @Input() public columnName: string;
  @Input() public operator = 'contains';

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(values: any): void {
    const filter = this.removeFilter(this.columnName);
    const filters = filter ? filter.filters : [];
    if (values.length) {
      filters.push({
        field: this.columnName,
        logic: 'or',
        filters: [
          ...values.map((value) => ({
            field: this.columnName,
            operator: this.operator,
            value: value.id,
          })),
        ],
      });
    }
    this.applyFilter({
      logic: 'and',
      filters,
    });
  }
}
