/* src/app/shared/components/smart-switch/smart-switch.component.scss */
:host .switch {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 8px;
}
:host .switch ::ng-deep kendo-switch {
  margin: 0 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35) !important;
}
:host .switch ::ng-deep kendo-switch .k-switch-track .k-switch-label-off,
:host .switch ::ng-deep kendo-switch .k-switch-track .k-switch-label-on {
  display: none;
}
:host .delete-filters {
  color: #4e1289;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
/*# sourceMappingURL=smart-switch.component.css.map */
