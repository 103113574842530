import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root',
})
export class AppNotificationService {
  constructor(private notificationService: NotificationService) {}

  success(message: string) {
    this.notificationService.show({
      content: message,
      hideAfter: 4000,
      position: { horizontal: 'right', vertical: 'bottom' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'success', icon: true },
    });
  }

  error(message: string) {
    this.notificationService.show({
      content: message,
      hideAfter: 8000,
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'bottom' },
      type: { style: 'error', icon: true },
    });
  }
}
