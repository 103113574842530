/* src/app/shared/components/reveal/reveal.component.scss */
:host {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 0;
  opacity: 0.2;
}
:host:hover {
  opacity: 1;
}
/*# sourceMappingURL=reveal.component.css.map */
