import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class ConnectionAgentService {
  public entity = CONSTANTS.entity.connectionAgent;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  loadData(): Observable<any> {
    const payload = {
      connectionAgentType: true,
      assistUrl: true,
      connectionAgent: true,
      addinUrl: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      connectionAgentType: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  getDetailById(id: number) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/connectionAgent/grid?&' + query, {}).pipe(
      map(
        (resp: any) =>
          resp.data.map((ca) => ({
            ...ca,
          }))[0],
      ),
    );
  }

  getDetailByServerType(serverTypeId: number) {
    const filters = {
      filter: {
        filters: [
          {
            field: 'serverTypeId',
            value: serverTypeId,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/connectionAgent/grid?&' + query, {}).pipe(
      map((resp: any) =>
        resp.data.map((ca) => ({
          ...ca,
        })),
      ),
    );
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  copy(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + '/copy', payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  sendEmail(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.sendEmail, payload);
  }

  test(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test, payload);
  }

  testImport(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test + 'Import', payload);
  }

  testUnits(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test + 'Units', payload);
  }

  testApiOperations(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test + 'ApiOperations', payload);
  }

  synchronizeNow(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.remoteSync, payload);
  }

  getSyncStatus(): Observable<any> {
    return this.getAction(this.entity + '/getsyncstatus');
  }

  dashboardData(): Observable<any> {
    return this.getAction(CONSTANTS.entity.connectionAgent + CONSTANTS.api.dashboardData);
  }

  synchronizationSettings(): Observable<any> {
    return this.getAction(CONSTANTS.entity.connectionAgent + '/synchronizationSettings');
  }

  editSynchronizationSettings(payload: any): Observable<any> {
    return this.postAction(CONSTANTS.entity.connectionAgent + '/editSynchronizationSettings', payload);
  }

  deviceCode(payload: any, isAnonymous: boolean): Observable<any> {
    const urlPostfix = CONSTANTS.entity.connectionAgent + (isAnonymous ? '/deviceCodeAnonymous' : '/deviceCode');
    return this.postAction(urlPostfix, payload);
  }

  getDeviceCodeStatus(isAnonymous: boolean): Observable<any> {
    const urlPostfix = this.entity + (isAnonymous ? '/getDeviceCodeStatusAnonymous' : '/getDeviceCodeStatus');
    return this.getAction(urlPostfix);
  }

  cancelDeviceCodeFlow(isAnonymous: boolean): Observable<any> {
    const urlPostfix = this.entity + (isAnonymous ? '/cancelDeviceCodeFlowAnonymous' : '/cancelDeviceCodeFlow');
    return this.getAction(urlPostfix);
  }

  getGroups(payload: any): Observable<any> {
    return this.postAction(this.entity + '/getGroups', payload);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
      map((x) => x),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
