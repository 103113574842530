import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ServerOffice365Service {
  public entity = CONSTANTS.entity.serverOffice365;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  sendEmail(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.sendEmail, payload);
  }

  test(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test, payload);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
