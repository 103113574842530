export interface MultiEditSelection {
  id: any;
  sel: boolean;
  ind: boolean;
}

export interface EnumModel {
  id?: number;
  name?: string;
}

export enum URL_ACTION {
  MULTI_EDIT = 'multiEdit',
  DETAIL = 'detail',
  NEW = 'new',
}

export enum PRODUCT_MODEL_TYPE {
  PANEL = 1,
  OVERVIEW = 2,
  TOUCH_CUE_PANEL = 6,
  CONTROLLER = 7,
}
