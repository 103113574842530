import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface HasUnsavedChanges {
  hasUnsavedChanges: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {
  constructor(private translateService: TranslateService) {}
  canDeactivate(component: HasUnsavedChanges): boolean | Observable<boolean> | Promise<boolean> {
    if (component.hasUnsavedChanges()) {
      return confirm(this.translateService.instant('general.unsavedChanges'));
    }
    return true;
  }
}
