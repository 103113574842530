<div class="wrapper">
    <h3>{{ 'legend.legend' | translate }}</h3>
    <div class="type" *ngIf="legendTypeCheckbox">
        <div class="row-container">
            <input type="checkbox" class="k-checkbox" />
            <p>{{ 'legend.off' | translate }}</p>
        </div>
        <div class="row-container">
            <input type="checkbox" class="k-checkbox" checked />
            <p>{{ 'legend.on' | translate }}</p>
        </div>
        <div class="row-container">
            <input type="checkbox" class="k-checkbox" [indeterminate]="true" />
            <p>{{ 'legend.noChange' | translate }}</p>
        </div>
    </div>
</div>
