<kendo-dialog [width]="dialogWidth" (close)="cancelDialog.emit()">
    <kendo-dialog-titlebar>
        {{ 'qrCode.warningInfo' | translate }}
    </kendo-dialog-titlebar>
    <ng-container>
        <span class="success">{{ successInformation | translate }}</span>
        <div class="row">
            <h2 class="warning">{{ 'areas.deleteWarningTitle' | translate }}</h2>
        </div>
        <div class="col-md-12">
            <div class="row">
                {{ 'qrCode.codeLengthExceeded' | translate }}
            </div>
            <div class="row">
                <strong>{{ 'qrCode.reprint' | translate }}</strong>
            </div>
        </div>
    </ng-container>
    <kendo-dialog-actions>
        <button class="k-button" (click)="cancelDialog.emit()">
            {{ 'actions.close' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
