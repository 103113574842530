import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsRoot } from './projects-root';

export const root = createFeatureSelector<any, ProjectsRoot>('projects');

export const projectId = createSelector(root, (state: ProjectsRoot) => state.projectId);

export const instances = createSelector(root, (state: ProjectsRoot) => state.instances);

export const expandedInstanceIds = createSelector(root, (state: ProjectsRoot) => state.expandedInstanceIds);

export const permissions = createSelector(root, (state: ProjectsRoot) => state.permissions);
