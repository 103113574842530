import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class UserService implements ValidationService {
  public entity = CONSTANTS.entity.user;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  loadData(): Observable<any> {
    let payload = {
      NFCUniqueId: true,
      pin: true,
      connectionAgentDropdown: true,
      userRole: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      connectionAgentDropdown: true,
      userGroup: true,
      userRole: true,
      userOrigin: true,
      resourceType: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  getDetailById(ids: number[]) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/load', ids);
  }

  loadDataSendPin(): Observable<any> {
    let payload = {
      user: true,
      systemConfiguration: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  multiEdit(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.multiEdit, payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  import(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.import, payload);
  }

  refresh(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.refresh, payload);
  }

  sendPin(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.sendPin, payload);
  }

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/user/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  removePinCode(ids: number[]) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/removePinCode', ids);
  }

  removeNFCUniqueId(ids: number[]) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/removeNFCUniqueId', ids);
  }

  setNewPinCode(ids: number[]) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/setPinCodeByAdmin', ids);
  }

  forgottenPin(userEmail: string) {
    return this.http.get<any>(this.configService.value.apiURL + '/api/user/userForgottenPin/' + userEmail);
  }

  generatePin(payload) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/generatePin', payload);
  }

  setNewPin(payload) {
    return this.http.post<any>(this.configService.value.apiURL + '/api/user/setNewPin', payload);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
