import { DesignDataRequest } from '@cue/admin-design';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  public entity = '/design';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  get(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http.get(url).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  update(data: DesignDataRequest): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http.post(url, data).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  checkAndUpdateCueIcons(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon/checkAndUpdate';
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  getCueIconSettings(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon/settings';
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  setCueIconSettings(payload: { sync: boolean }): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon/settings';
    return this.http.post(url, payload).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  manualUpdate(file: File): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon/manualUpdate';
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }
}
