import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { appReducer, serversReducer } from '../shared/reducers';
import { ServersEffects } from './effects';

export const coreProviders = [
  provideStore(
    {
      app: appReducer,
      serversPage: serversReducer,
    },
    {
      runtimeChecks: {
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictStateSerializability: false,
      },
    },
  ),
  provideEffects([ServersEffects]),
  provideStoreDevtools({
    maxAge: 25,
    connectInZone: true,
  }),
];
