<ng-template #titleTemplate let-anchor>{{ tooltipTitle }}</ng-template>
<ng-template #tooltipTemplate let-anchor
    ><ng-container *ngIf="hasTextContent">
        {{ tooltipContent }}
        <div *ngIf="link != null" class="window-open">
            <a (click)="openWithSessionStorage(link)">{{ linkDesc ? linkDesc : link }}</a>
        </div>
    </ng-container>
    <ng-container *ngIf="hasTemplateContent" [ngTemplateOutlet]="tooltipContentTemplate"></ng-container
></ng-template>

<div
    kendoTooltip
    [titleTemplate]="tooltipTitle != null ? titleTemplate : null"
    [tooltipTemplate]="hasContent ? tooltipTemplate : null"
    [position]="position"
    [showOn]="showOn"
    [filter]="ownIcon == null ? '.svg-icon' : 'img'"
    tooltipClass="icon-tooltip"
>
    <!-- TODO -->
    <!-- <mat-icon [icon]="icon" *ngIf="ownIcon == null && svgIcon == null"></mat-icon> -->
    <img [src]="ownIcon" [style.width]="ownIconSize" *ngIf="ownIcon != null && svgIcon == null" />
    <div class="svg-icon" [inlineSVG]="svgIcon" [style.width]="ownIconSize" *ngIf="ownIcon == null && svgIcon != null" [class]="type"></div>
</div>
