import { AppModel } from '../models';
import { AppActionTypes } from '../actions/app.actions';
import { CONSTANTS } from '@cue/admin-constants';

const initialState: AppModel = {
  language: null,
};

export function appReducer(state: AppModel = initialState, action: any): AppModel {
  switch (action.type) {
    case AppActionTypes.Language:
      return {
        ...state,
        language: action.data,
      };
    default:
      return state;
  }
}
