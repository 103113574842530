import { Injectable } from '@angular/core';
import { ConfigService } from '@cue/admin-core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { DataModuleConfiguration } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private configurations: { [dataType: string]: DataModuleConfiguration } = {};

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  addConfigurations(configurations: DataModuleConfiguration[]) {
    configurations.forEach((conf) => {
      const foundConfiguration = this.configurations[conf.dataType];
      if (foundConfiguration) {
        for (const key in conf) {
          if (foundConfiguration[key]) {
            continue;
          }
          foundConfiguration[key] = conf[key];
        }
      } else {
        this.configurations = { ...this.configurations, [conf.dataType]: conf };
      }
    });
  }

  addData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.addUrl === 'function' ? config.addUrl() : config.addUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  importData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.importUrl === 'function' ? config.importUrl() : config.importUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  editData<T = any>(id: string, dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.editIdUrl === 'function' ? config.editIdUrl(id) : config.editIdUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  deleteData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.deleteUrl === 'function' ? config.deleteUrl() : config.deleteUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  fetchDataById<T = any>(dataType: string, id: string): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.loadIdUrl === 'function' ? config.loadIdUrl(id) : config.loadIdUrl;
    return this.http.get<any>(url + CONSTANTS.api.prefix + postfix);
  }

  fetchDataByType<T = any>(dataType: string): Observable<T> {
    const url = `${this.configService.value.apiURL}`;

    const config = this.getConfig(dataType);
    const postfix = typeof config.loadAllUrl === 'function' ? config.loadAllUrl() : config.loadAllUrl;
    return this.http.get<any>(url + CONSTANTS.api.prefix + postfix);
  }

  private getConfig(dataType: string) {
    const config = this.configurations[dataType];
    if (!config) {
      throw Error(`Configuration for data type ${dataType} not found`);
    }
    return config;
  }

  /* ***** Active Directory ***** */

  importADData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.importADUrl === 'function' ? config.importADUrl() : config.importADUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  refreshADData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.refreshADUrl === 'function' ? config.refreshADUrl() : config.refreshADUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  /* ***** Import ***** */

  importUsersData<T = any>(id: string, dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.importUsersUrl === 'function' ? config.importUsersUrl(id) : config.importUsersUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  deleteUsersData<T = any>(id: string, dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.deleteUsersUrl === 'function' ? config.deleteUsersUrl(id) : config.deleteUsersUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  /* ***** SendPin ***** */

  sendPinData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.sendPinUrl === 'function' ? config.sendPinUrl() : config.sendPinUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }

  /* ***** AccountEdit ***** */
  accountEditData<T = any>(dataType: string, payload: any): Observable<T> {
    const url = `${this.configService.value.apiURL}`;
    const config = this.getConfig(dataType);
    const postfix = typeof config.accountEditUrl === 'function' ? config.accountEditUrl() : config.accountEditUrl;
    return this.http.post<any>(url + CONSTANTS.api.prefix + postfix, {
      data: payload,
    });
  }
}
