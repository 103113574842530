import { Injectable } from '@angular/core';
import { ConfigService, ValidationService } from '@cue/admin-core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimeSettingsService implements ValidationService {
  public entity = '/timeSetting';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  add(payload: any): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity;
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  edit(id: number, payload: any): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  delete(ids: number[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/delete';
    return this.http.post(url, ids).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getById(id: number): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.get(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getTimeZones(): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + '/timeZone';
    return this.http.get(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }
}
