/**-----------------------------------------------------------------------------------------
* Copyright © 2023 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, HostBinding, Injectable, Component, Input, EventEmitter, isDevMode, Output, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { validatePackage } from '@progress/kendo-licensing';
import { dispatchDragAndDrop, getScrollableParent, autoScroll } from '@progress/kendo-draggable-common';
import { PreventableEvent, contains, isDocumentAvailable, parseCSSClassNames } from '@progress/kendo-angular-common';
import { fromEvent, merge } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-utils',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1703060490,
    version: '14.3.0',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Represents the Kendo UI DragHandle directive for Angular.
 * It is used to specify a concrete element within a drag target as a handle for dragging, instead the drag target itself.
 */
class DragHandleDirective {
    constructor(element) {
        this.element = element;
        this.cursorStyle = 'move';
    }
}
DragHandleDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragHandleDirective, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Directive });
DragHandleDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DragHandleDirective, selector: "[kendoDragHandle]", host: { properties: { "style.cursor": "this.cursorStyle" } }, exportAs: ["kendoDragHandle"], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragHandleDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDragHandle]',
                    exportAs: 'kendoDragHandle'
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }]; }, propDecorators: { cursorStyle: [{
                type: HostBinding,
                args: ['style.cursor']
            }] } });

/**
 * @hidden
 */
class DragStateService {
    constructor() {
        this.dragTarget = null;
        this.dropTarget = null;
        this.dragTargets = [];
        this.dropTargets = [];
        this.pressed = false;
        this.ignoreMouse = false;
        this.autoScroll = true;
        this.isScrolling = false;
        this.scrollableParent = null;
        this.autoScrollDirection = { horizontal: true, vertical: true };
        this.initialClientOffset = { x: 0, y: 0 };
        this.clientOffset = { x: 0, y: 0 };
        this.initialScrollOffset = { x: 0, y: 0 };
        this.scrollOffset = { x: 0, y: 0 };
        this.offset = { x: 0, y: 0 };
        this.pageOffset = { x: 0, y: 0 };
        this.velocity = { x: 0, y: 0 };
        this.dragIndex = null;
        this.dropIndex = null;
        this.callbacks = {};
        this.scrollInterval = null;
        this.setCallbacks();
    }
    handleDragAndDrop(action) {
        this.updateState();
        dispatchDragAndDrop(this.state, action, this.callbacks);
    }
    setPressed(pressed) {
        this.pressed = pressed;
    }
    setScrolling(isScrolling) {
        this.isScrolling = isScrolling;
        if (isScrolling) {
            const scrollableParent = getScrollableParent(document.elementFromPoint(this.clientOffset.x, this.clientOffset.y));
            window.clearInterval(this.scrollInterval);
            this.scrollInterval = window.setInterval(() => {
                autoScroll(scrollableParent, { x: this.velocity.x, y: this.velocity.y });
            }, 50);
        }
        else {
            if (this.scrollInterval) {
                window.clearInterval(this.scrollInterval);
                this.scrollInterval = null;
            }
        }
    }
    setVelocity(velocity) {
        this.velocity = velocity;
    }
    setOffset(offset) {
        this.offset = offset;
    }
    setClientOffset(clientOffset) {
        this.clientOffset = clientOffset;
    }
    setPageOffset(pageOffset) {
        this.pageOffset = pageOffset;
    }
    setInitialClientOffset(initialClientOffset) {
        this.initialClientOffset = initialClientOffset;
    }
    setScrollOffset(scrollOffset) {
        this.scrollOffset = scrollOffset;
    }
    setInitialScrollOffset(initialScrollOffset) {
        this.initialScrollOffset = initialScrollOffset;
    }
    updateState() {
        this.state = {
            drag: this.dragTarget,
            drop: this.dropTarget,
            drags: this.dragTargets,
            drops: this.dropTargets,
            pressed: this.pressed,
            ignoreMouse: this.ignoreMouse,
            autoScroll: this.autoScroll,
            isScrolling: this.isScrolling,
            scrollableParent: this.scrollableParent,
            autoScrollDirection: this.autoScrollDirection,
            initialClientOffset: this.initialClientOffset,
            clientOffset: this.clientOffset,
            initialScrollOffset: this.initialScrollOffset,
            scrollOffset: this.scrollOffset,
            offset: this.offset,
            pageOffset: this.pageOffset,
            velocity: this.velocity
        };
    }
    setCallbacks() {
        this.callbacks = {
            onVelocityChange: this.setVelocity.bind(this),
            onOffsetChange: this.setOffset.bind(this),
            onClientOffsetChange: this.setClientOffset.bind(this),
            onPageOffsetChange: this.setPageOffset.bind(this),
            onInitialClientOffsetChange: this.setInitialClientOffset.bind(this),
            onScrollOffsetChange: this.setScrollOffset.bind(this),
            onInitialScrollOffsetChange: this.setInitialScrollOffset.bind(this),
            onIsPressedChange: this.setPressed.bind(this),
            onIsScrollingChange: this.setScrolling.bind(this)
        };
    }
    ngOnDestroy() {
        if (this.scrollInterval) {
            window.clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    }
}
DragStateService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragStateService, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
DragStateService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragStateService, providedIn: 'root' });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragStateService, decorators: [{
            type: Injectable,
            args: [{
                    providedIn: 'root'
                }]
        }], ctorParameters: function () { return []; } });

function isDocumentNode(container) {
    return container.nodeType === 9;
}
/**
 * @hidden
 */
const getAction = (event, draggable) => {
    return {
        event: event,
        payload: draggable
    };
};
/**
 * @hidden
 */
const dragTargetTransition = 'transform .3s ease-in-out';
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
function closestBySelector(element, selector) {
    if (element.closest) {
        return element.closest(selector);
    }
    const matches = Element.prototype.matches ?
        (el, sel) => el.matches(sel)
        : (el, sel) => el.msMatchesSelector(sel);
    let node = element;
    while (node && !isDocumentNode(node)) {
        if (matches(node, selector)) {
            return node;
        }
        node = node.parentNode;
    }
}
/**
 * @hidden
 */
const intersect = (element, candidates) => {
    let max = 0;
    let result = null;
    candidates.forEach((candidate) => {
        if (candidate && element) {
            const ration = getRatio(element, candidate);
            if (ration > max) {
                max = ration;
                result = candidate;
            }
        }
    });
    return result;
};
const getRatio = (element, target) => {
    const elementRect = element.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const top = Math.max(targetRect.top, elementRect.top);
    const left = Math.max(targetRect.left, elementRect.left);
    const right = Math.min(targetRect.left + targetRect.width, elementRect.left + elementRect.width);
    const bottom = Math.min(targetRect.top + targetRect.height, elementRect.top + elementRect.height);
    const width = right - left;
    const height = bottom - top;
    if (left < right && top < bottom) {
        const targetArea = targetRect.width * targetRect.height;
        const entryArea = elementRect.width * elementRect.height;
        const intersectionArea = width * height;
        const intersectionRatio = intersectionArea / (targetArea + entryArea - intersectionArea);
        return Number(intersectionRatio.toFixed(4));
    }
    return 0;
};
/**
 * @hidden
 */
const setElementStyles = (renderer, elem, styles) => {
    const props = Object.keys(styles);
    props.forEach(p => {
        renderer.setStyle(elem, p, styles[p]);
    });
};
/**
 * @hidden
 */
const allPointerDownEvents = ['pointerdown', 'mousedown', 'touchstart'];
/**
 * @hidden
 */
const allPointerMoveEvents = ['pointermove', 'mousemove', 'touchmove'];
/**
 * @hidden
 */
const allPointerUpEvents = ['pointerup', 'pointercancel', 'mouseup', 'contextmenu', 'touchend', 'touchcancel'];

/**
 * @hidden
 */
class HintComponent {
    constructor(element) {
        this.element = element;
        this.pointerEvents = 'none';
    }
}
HintComponent.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: HintComponent, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Component });
HintComponent.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "12.0.0", version: "13.3.12", type: HintComponent, selector: "kendo-draghint", inputs: { template: "template", directive: "directive", targetIndex: "targetIndex", contextData: "contextData" }, host: { properties: { "style.pointer-events": "this.pointerEvents" } }, ngImport: i0, template: `
        <ng-container *ngTemplateOutlet="template; context: { $implicit: directive, index: targetIndex, data: contextData }">
        </ng-container>
    `, isInline: true, directives: [{ type: i1.NgTemplateOutlet, selector: "[ngTemplateOutlet]", inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"] }] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: HintComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'kendo-draghint',
                    template: `
        <ng-container *ngTemplateOutlet="template; context: { $implicit: directive, index: targetIndex, data: contextData }">
        </ng-container>
    `
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }]; }, propDecorators: { template: [{
                type: Input
            }], directive: [{
                type: Input
            }], targetIndex: [{
                type: Input
            }], contextData: [{
                type: Input
            }], pointerEvents: [{
                type: HostBinding,
                args: ['style.pointer-events']
            }] } });

/**
 * Arguments for the press event of the DragTarget and DragTargetContainer.
 */
class DragTargetPressEvent {
    /**
     * @hidden
     */
    constructor(args) {
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dragTarget;
    }
}

/**
 * Arguments for the dragReady event of the DragTarget and DragTargetContainer.
 */
class DragTargetDragReadyEvent {
    /**
     * @hidden
     */
    constructor(args) {
        Object.assign(this, args);
    }
}

/**
 * Arguments for the dragStart event of the DragTarget and DragTargetContainer.
 */
class DragTargetDragStartEvent extends PreventableEvent {
    /**
     * @hidden
     */
    constructor(args) {
        super();
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dragTarget;
    }
}

/**
 * Arguments for the dragEnd event of the DragTarget and DragTargetContainer.
 */
class DragTargetDragEndEvent {
    /**
     * @hidden
     */
    constructor(args) {
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dragTarget;
    }
}

/**
 * Arguments for the drag event of the DragTarget and DragTargetContainer.
 */
class DragTargetDragEvent extends PreventableEvent {
    /**
     * @hidden
     */
    constructor(args) {
        super();
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dragTarget;
    }
}

/**
 * Arguments for the release event of the DragTarget and DragTargetContainer.
 */
class DragTargetReleaseEvent {
    /**
     * @hidden
     */
    constructor(args) {
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DragTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dragTarget;
    }
}

let isDragStartPrevented$1 = false;
let isDragPrevented$1 = false;
/**
 * Represents the Kendo UI DragTarget directive for Angular.
 */
class DragTargetDirective {
    constructor(element, renderer, ngZone, service, viewContainer) {
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.service = service;
        this.viewContainer = viewContainer;
        /**
         * Defines whether a hint will be used for dragging. By default, the hint is a copy of the drag target. ([see example]({% slug drag_hint %})).
         *
         * @default false
         */
        this.hint = false;
        /**
         * The number of pixels the pointer moves in any direction before the dragging starts ([see example]({% slug minimum_distance %})). Applicable when `manualDrag` is set to `false`.
         *
         * @default 0
         */
        this.threshold = 0;
        /**
         * Defines the automatic container scrolling behavior when close to the edge ([see example]({% slug auto_scroll %})).
         *
         * @default true
         */
        this.autoScroll = true;
        /**
         * Defines the delay in milliseconds after which the drag will begin ([see example]({% slug drag_delay %})).
         *
         * @default 0
         */
        this.dragDelay = 0;
        /**
         * Specifies whether the default dragging behavior will be performed or the developer will manually handle the drag action.
         *
         * @default 'auto'
         */
        this.mode = 'auto';
        /**
         * Fires when the user presses the DragTarget element.
         */
        this.onPress = new EventEmitter();
        /**
         * Fires when the dragging of the DragTarget element begins.
         */
        this.onDragStart = new EventEmitter();
        /**
         * Fires while the user drags the DragTarget element.
         */
        this.onDrag = new EventEmitter();
        /**
         * Fires when the DragTarget's `dragDelay` has passed and the user is able to drag the element.
         */
        this.onDragReady = new EventEmitter();
        /**
         * Fires when the user releases the DragTarget element after being pressed.
         */
        this.onRelease = new EventEmitter();
        /**
         * Fires when the dragging of the DragTarget ends and the element is released.
         */
        this.onDragEnd = new EventEmitter();
        this.dragTarget = null;
        this.domSubscriptions = [];
        this.hintComponent = null;
        this.dragStarted = false;
        this.pressed = false;
        this.dragTimeout = null;
        this.initialPosition = { x: 0, y: 0 };
        this.position = { x: 0, y: 0 };
        this.scrollableParent = null;
        this.defaultHint = null;
        this._dragData = () => null;
        validatePackage(packageMetadata);
    }
    /**
     * Defines a callback function used for attaching custom data to the dragTarget.
     * The data will be available in the events of the respective [`DropTarget`]({% slug api_utils_droptargetdirective %}) or [`DropTargetContainer`]({% slug api_utils_droptargetcontainerdirective %}) directives.
     * The current DragTarget HTML element and its `dragTargetId` will be available as arguments.
     */
    set dragData(fn) {
        if (isDevMode && typeof fn !== 'function') {
            throw new Error(`dragData must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this._dragData = fn;
    }
    get dragData() {
        return this._dragData;
    }
    get hintTemplate() {
        return isPresent(this.hint) && typeof this.hint === 'object' ? this.hint.hintTemplate : null;
    }
    get nativeElement() {
        return this.element.nativeElement;
    }
    get hintElem() {
        return this.hintTemplate && isPresent(this.hintComponent) ? this.hintComponent.instance.element.nativeElement : this.defaultHint;
    }
    onPointerDown(event) {
        if (this.dragHandles.length && !this.isDragHandle(event.target)) {
            return;
        }
        event.preventDefault();
        const action = getAction(event, this.dragTarget);
        this.service.handleDragAndDrop(action);
        this.service.autoScroll = typeof this.autoScroll === 'object' ? this.autoScroll.enabled !== false : this.autoScroll;
        this.service.scrollableParent = this.getAutoScrollContainer();
        this.service.autoScrollDirection = typeof this.autoScroll === 'object' ? this.autoScroll.direction : { horizontal: true, vertical: true };
        this.attachDomHandlers();
    }
    onPointerMove(event) {
        event.preventDefault();
        const action = getAction(event, this.dragTarget);
        this.service.handleDragAndDrop(action);
    }
    onPointerUp(event) {
        event.preventDefault();
        const action = getAction(event, this.dragTarget);
        this.service.handleDragAndDrop(action);
        this.attachDomHandlers();
    }
    ngOnInit() {
        this.initializeDragTarget();
    }
    ngAfterContentInit() {
        if (isPresent(this.element) || isPresent(this.dragTarget)) {
            this.attachDomHandlers();
            if (!this.dragHandles.length) {
                this.renderer.setStyle(this.nativeElement, 'cursor', 'move');
            }
        }
        this.service.dragTargets.push(this.dragTarget);
    }
    ngOnDestroy() {
        this.domSubscriptions.forEach(subscription => subscription());
    }
    handlePress(event) {
        if (this.dragDelay > 0) {
            this.dragTimeout = window.setTimeout(() => {
                this.pressed = true;
                this.emitZoneAwareEvent('onDragReady', event);
            }, this.dragDelay);
        }
        else {
            this.pressed = true;
        }
        this.scrollableParent = this.dragTarget.element ? getScrollableParent(this.dragTarget.element) : null;
        this.emitZoneAwareEvent('onPress', event);
    }
    handleDragStart(event) {
        if (!this.pressed) {
            if (this.dragTimeout) {
                window.clearTimeout(this.dragTimeout);
                this.dragTimeout = null;
            }
            return;
        }
        isDragStartPrevented$1 = this.emitZoneAwareEvent('onDragStart', event).isDefaultPrevented();
        if (isDragStartPrevented$1) {
            return;
        }
        if (this.hint) {
            this.createHint();
            if (this.mode === 'auto') {
                this.renderer.setStyle(this.nativeElement, 'opacity', '0.7');
            }
            this.initialPosition = { x: event.offsetX, y: event.offsetY };
        }
        else {
            this.initialPosition = { x: event.clientX - this.position.x, y: event.clientY - this.position.y };
        }
        this.dragStarted = this.threshold === 0;
        this.service.dragTarget = this.dragTarget;
        this.service.dragTargetDirective = this;
        this.service.dragData = this.dragData({ dragTarget: this.dragTarget.element, dragTargetId: this.dragTargetIdResult, dragTargetIndex: null });
    }
    handleDrag(event) {
        if (!this.pressed || isDragStartPrevented$1) {
            return;
        }
        const elem = this.hint ? this.hintElem : this.nativeElement;
        this.position = this.calculatePosition(elem, event);
        const thresholdNotReached = Math.abs(this.position.x) < this.threshold && Math.abs(this.position.y) < this.threshold;
        if (!this.dragStarted && thresholdNotReached) {
            return;
        }
        if (!this.dragStarted && this.threshold > 0) {
            this.dragStarted = true;
        }
        isDragPrevented$1 = this.emitZoneAwareEvent('onDrag', event).isDefaultPrevented();
        if (isDragPrevented$1) {
            return;
        }
        if (this.mode === 'auto') {
            this.performDrag();
        }
        else {
            this.dragStarted = true;
        }
    }
    handleRelease(event) {
        if (this.dragTimeout) {
            clearTimeout(this.dragTimeout);
            this.dragTimeout = null;
            this.pressed = false;
        }
        this.emitZoneAwareEvent('onRelease', event);
    }
    handleDragEnd(event) {
        if (this.mode === 'auto') {
            const isDroppedOverParentTarget = isPresent(this.service.dropTarget) && !contains(this.service.dropTarget?.element, this.service.dragTarget?.element, true);
            const elem = this.hint ? this.hintElem : this.nativeElement;
            if (isDroppedOverParentTarget || this.service.dropTargets.length > 0 && isPresent(elem)) {
                this.renderer.removeStyle(elem, 'transform');
                setElementStyles(this.renderer, elem, {
                    transition: dragTargetTransition
                });
                this.position = { x: 0, y: 0 };
            }
        }
        if (this.hint && isPresent(this.hintElem)) {
            this.destroyHint();
            if (this.mode === 'auto') {
                this.renderer.removeStyle(this.nativeElement, 'opacity');
            }
        }
        this.service.dragTarget = null;
        this.service.dragTargetDirective = null;
        if (!this.dragStarted || isDragStartPrevented$1 || isDragPrevented$1) {
            return;
        }
        this.emitZoneAwareEvent('onDragEnd', event);
        this.dragStarted = false;
    }
    initializeDragTarget() {
        this.dragTarget = {
            element: this.nativeElement,
            hint: null,
            onPress: this.handlePress.bind(this),
            onRelease: this.handleRelease.bind(this),
            onDragStart: this.handleDragStart.bind(this),
            onDrag: this.handleDrag.bind(this),
            onDragEnd: this.handleDragEnd.bind(this)
        };
    }
    attachDomHandlers() {
        this.ngZone.runOutsideAngular(() => {
            if (this.domSubscriptions.length > 0) {
                this.domSubscriptions.forEach(subscription => subscription());
            }
            if (!(isDocumentAvailable() && isPresent(this.element))) {
                return;
            }
            if (this.service.pressed) {
                this.onPointerMove = this.onPointerMove.bind(this);
                this.onPointerUp = this.onPointerUp.bind(this);
                this.domSubscriptions = [
                    this.renderer.listen(document, 'pointermove', this.onPointerMove),
                    this.renderer.listen(document, 'mousemove', this.onPointerMove),
                    this.renderer.listen(document, 'touchmove', this.onPointerMove),
                    this.renderer.listen(document, 'pointerup', this.onPointerUp),
                    this.renderer.listen(document, 'pointercancel', this.onPointerUp),
                    this.renderer.listen(document, 'mouseup', this.onPointerUp),
                    this.renderer.listen(document, 'contextmenu', this.onPointerUp),
                    this.renderer.listen(document, 'touchend', this.onPointerUp),
                    this.renderer.listen(document, 'touchcancel', this.onPointerUp)
                ];
                if (isPresent(this.scrollableParent)) {
                    if (this.scrollableParent === document.getElementsByTagName('html')[0]) {
                        this.scrollableParent = window;
                    }
                    this.domSubscriptions.push(this.renderer.listen(this.scrollableParent, 'scroll', this.onPointerMove));
                }
            }
            else {
                this.onPointerDown = this.onPointerDown.bind(this);
                const element = this.nativeElement;
                this.domSubscriptions = [
                    this.renderer.listen(element, 'pointerdown', this.onPointerDown),
                    this.renderer.listen(element, 'mousedown', this.onPointerDown),
                    this.renderer.listen(element, 'touchstart', this.onPointerDown)
                ];
            }
        });
    }
    isDragHandle(el) {
        return this.dragHandles.toArray().some(dh => contains(dh.element.nativeElement, el, true));
    }
    getAutoScrollContainer() {
        return typeof this.autoScroll === 'object' &&
            this.autoScroll.boundaryElementRef &&
            this.autoScroll.boundaryElementRef.nativeElement ?
            this.autoScroll.boundaryElementRef.nativeElement : null;
    }
    createHint() {
        if (!(isDocumentAvailable() && isPresent(this.element))) {
            return;
        }
        if (isPresent(this.hint) && typeof this.hint === 'object') {
            if (isPresent(this.hint.hintTemplate)) {
                this.createCustomHint();
            }
            else {
                this.createDefaultHint();
            }
        }
        else {
            this.createDefaultHint();
        }
        this.dragTarget.hint = this.hintElem;
        if (typeof this.hint === 'object' && isPresent(this.hint.appendTo)) {
            this.hint.appendTo.element.nativeElement.appendChild(this.hintElem);
        }
        else {
            document.body.appendChild(this.hintElem);
        }
    }
    createDefaultHint() {
        this.defaultHint = this.nativeElement.cloneNode(true);
        if (typeof this.hint === 'object') {
            if (isPresent(this.hint.hintClass)) {
                const hintClasses = parseCSSClassNames(this.hint.hintClass);
                hintClasses.forEach(className => this.renderer.addClass(this.defaultHint, className));
            }
        }
    }
    createCustomHint() {
        if (isPresent(this.hint.appendTo)) {
            this.hintComponent = this.hint.appendTo.createComponent(HintComponent);
        }
        else {
            this.hintComponent = this.viewContainer.createComponent(HintComponent);
        }
        this.hintComponent.instance.template = this.hintTemplate;
        this.hintComponent.instance.directive = this;
        this.hintComponent.changeDetectorRef.detectChanges();
    }
    destroyHint() {
        if (isPresent(this.hintTemplate)) {
            this.hintComponent.destroy();
            this.hintComponent.changeDetectorRef.detectChanges();
            this.hintComponent = null;
        }
        else {
            if (typeof this.hint === 'object' && isPresent(this.hint.appendTo)) {
                this.hint.appendTo.element.nativeElement.removeChild(this.defaultHint);
            }
            else {
                document.body.removeChild(this.defaultHint);
            }
            this.defaultHint = null;
        }
        this.dragTarget.hint = null;
    }
    emitZoneAwareEvent(event, normalizedEvent) {
        const eventProps = {
            dragTarget: this.nativeElement,
            dragEvent: normalizedEvent
        };
        if (this.hint && isPresent(this.hintElem)) {
            eventProps.hintElement = this.hintElem;
        }
        if (this.dragTargetId && this.dragTargetId !== '') {
            eventProps.dragTargetId = this.dragTargetIdResult;
        }
        let eventArgs;
        switch (event) {
            case 'onDragReady':
                eventArgs = new DragTargetDragReadyEvent(eventProps);
                break;
            case 'onPress':
                eventArgs = new DragTargetPressEvent(eventProps);
                break;
            case 'onDragStart':
                eventArgs = new DragTargetDragStartEvent(eventProps);
                break;
            case 'onDrag':
                eventArgs = new DragTargetDragEvent(eventProps);
                break;
            case 'onRelease':
                eventArgs = new DragTargetReleaseEvent(eventProps);
                break;
            case 'onDragEnd':
                eventArgs = new DragTargetDragEndEvent(eventProps);
                break;
            default:
                break;
        }
        this.ngZone.run(() => {
            this[event].emit(eventArgs);
        });
        return eventArgs;
    }
    get dragTargetIdResult() {
        if (this.dragTargetId && this.dragTargetId !== '') {
            return typeof this.dragTargetId === 'string' ? this.dragTargetId : this.dragTargetId({ dragTarget: this.dragTarget.element, dragTargetIndex: null });
        }
    }
    performDrag() {
        const elem = this.hint ? this.hintElem : this.nativeElement;
        if (elem) {
            const styles = this.getStylesPerElement(elem);
            setElementStyles(this.renderer, elem, styles);
        }
    }
    calculatePosition(element, event) {
        let position = null;
        if (element === this.hintElem) {
            position = { x: event.clientX, y: event.clientY };
        }
        else {
            position = { x: event.clientX - this.initialPosition.x + event.scrollX, y: event.clientY - this.initialPosition.y + event.scrollY };
        }
        if (this.restrictByAxis === 'horizontal') {
            position.y = 0;
        }
        else if (this.restrictByAxis === 'vertical') {
            position.x = 0;
        }
        return position;
    }
    getStylesPerElement(element) {
        if (element === this.hintElem) {
            const hintCoordinates = { x: this.position.x - this.initialPosition.x, y: this.position.y - this.initialPosition.y };
            return {
                top: `${hintCoordinates.y}px`,
                left: `${hintCoordinates.x}px`,
                transition: 'none',
                position: 'absolute',
                zIndex: 1999
            };
        }
        else {
            const transform = `translate(${this.position.x}px, ${this.position.y}px)`;
            return {
                transform: transform,
                transition: 'none'
            };
        }
    }
}
DragTargetDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragTargetDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: i0.NgZone }, { token: DragStateService }, { token: i0.ViewContainerRef }], target: i0.ɵɵFactoryTarget.Directive });
DragTargetDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DragTargetDirective, selector: "[kendoDragTarget]", inputs: { hint: "hint", threshold: "threshold", autoScroll: "autoScroll", dragTargetId: "dragTargetId", dragDelay: "dragDelay", restrictByAxis: "restrictByAxis", mode: "mode", dragData: "dragData" }, outputs: { onPress: "onPress", onDragStart: "onDragStart", onDrag: "onDrag", onDragReady: "onDragReady", onRelease: "onRelease", onDragEnd: "onDragEnd" }, queries: [{ propertyName: "dragHandles", predicate: DragHandleDirective }], exportAs: ["kendoDragTarget"], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragTargetDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDragTarget]',
                    exportAs: 'kendoDragTarget'
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: i0.NgZone }, { type: DragStateService }, { type: i0.ViewContainerRef }]; }, propDecorators: { hint: [{
                type: Input
            }], threshold: [{
                type: Input
            }], autoScroll: [{
                type: Input
            }], dragTargetId: [{
                type: Input
            }], dragDelay: [{
                type: Input
            }], restrictByAxis: [{
                type: Input
            }], mode: [{
                type: Input
            }], dragData: [{
                type: Input
            }], onPress: [{
                type: Output
            }], onDragStart: [{
                type: Output
            }], onDrag: [{
                type: Output
            }], onDragReady: [{
                type: Output
            }], onRelease: [{
                type: Output
            }], onDragEnd: [{
                type: Output
            }], dragHandles: [{
                type: ContentChildren,
                args: [DragHandleDirective]
            }] } });

/**
 * Arguments for the drag events of the DropTarget and DropTargetContainer directives.
 */
class DropTargetEvent {
    /**
     * @hidden
     */
    constructor(args) {
        Object.assign(this, args);
    }
    /**
     * Left for backward compatibility for the DropTarget deprecated events.
     * @hidden
     */
    get normalizedEvent() {
        return this.dragEvent;
    }
    /**
     * Left for backward compatibility for the DropTarget deprecated events.
     * @hidden
     */
    get hostElement() {
        return this.dropTarget;
    }
}

/**
 * Represents the Kendo UI DropTarget directive for Angular.
 */
class DropTargetDirective {
    constructor(service, element, ngZone) {
        this.service = service;
        this.element = element;
        this.ngZone = ngZone;
        /**
         * Fires when a DragTarget element enters the DropTarget.
         */
        this.onDragEnter = new EventEmitter();
        /**
         * Fires when a DragTarget element is being dragged over the DropTarget.
         */
        this.onDragOver = new EventEmitter();
        /**
         * Fires when a DragTarget element leaves the DropTarget.
         */
        this.onDragLeave = new EventEmitter();
        /**
         * Fires when a DragTarget element is dropped over the DropTarget.
         */
        this.onDrop = new EventEmitter();
        validatePackage(packageMetadata);
    }
    ngOnInit() {
        this.initializeDropTarget();
        this.service.dropTargets.push(this.dropTarget);
    }
    /**
     * @hidden
     */
    handleDragEnter(event) {
        if (!this.service.dragTarget) {
            return;
        }
        this.service.dropTarget = this.dropTarget;
        this.emitZoneAwareEvent('onDragEnter', event);
    }
    /**
     * @hidden
     */
    handleDragLeave(event) {
        this.service.dropTarget = null;
        if (!this.service.dragTarget) {
            return;
        }
        this.emitZoneAwareEvent('onDragLeave', event);
    }
    /**
     * @hidden
     */
    handleDragOver(event) {
        if (!this.service.dragTarget) {
            return;
        }
        this.emitZoneAwareEvent('onDragOver', event);
    }
    /**
     * @hidden
     */
    handleDrop(event) {
        this.emitZoneAwareEvent('onDrop', event);
        this.service.dropTarget = null;
    }
    initializeDropTarget() {
        this.dropTarget = {
            element: this.element.nativeElement,
            onDragEnter: this.handleDragEnter.bind(this),
            onDragLeave: this.handleDragLeave.bind(this),
            onDragOver: this.handleDragOver.bind(this),
            onDrop: this.handleDrop.bind(this)
        };
    }
    emitZoneAwareEvent(event, normalizedEvent) {
        const eventProps = {
            dropTarget: this.element.nativeElement,
            dragTarget: this.service.dragTarget?.element,
            dragEvent: normalizedEvent,
            dragData: this.service.dragData
        };
        if (isPresent(this.service.dragTarget?.hint)) {
            eventProps.hintElement = this.service.dragTarget.element;
        }
        const eventArgs = new DropTargetEvent(eventProps);
        this.ngZone.run(() => {
            this[event].emit(eventArgs);
        });
    }
}
DropTargetDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DropTargetDirective, deps: [{ token: DragStateService }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Directive });
DropTargetDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DropTargetDirective, selector: "[kendoDropTarget]", outputs: { onDragEnter: "onDragEnter", onDragOver: "onDragOver", onDragLeave: "onDragLeave", onDrop: "onDrop" }, exportAs: ["kendoDropTarget"], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DropTargetDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDropTarget]',
                    exportAs: 'kendoDropTarget'
                }]
        }], ctorParameters: function () { return [{ type: DragStateService }, { type: i0.ElementRef }, { type: i0.NgZone }]; }, propDecorators: { onDragEnter: [{
                type: Output
            }], onDragOver: [{
                type: Output
            }], onDragLeave: [{
                type: Output
            }], onDrop: [{
                type: Output
            }] } });

let isDragStartPrevented = false;
let isDragPrevented = false;
/**
 * Represents the [Kendo UI DragTargetContainer directive for Angular]({% slug api_utils_dragtargetcontainerdirective %}).
 * Used to configure multiple elements as draggable.
 *
 * @example
 * ```ts-no-run
 * <ul kendoDragTargetContainer dragTargetFilter=".my-draggable">
 *    <li class="my-draggable">foo</li>
 * </ul>
 * ```
 */
class DragTargetContainerDirective {
    constructor(wrapper, ngZone, renderer, service, viewContainer, cdr) {
        this.wrapper = wrapper;
        this.ngZone = ngZone;
        this.renderer = renderer;
        this.service = service;
        this.viewContainer = viewContainer;
        this.cdr = cdr;
        /**
         * Defines whether a hint will be used for dragging. By default, the hint is a copy of the current drag target. ([see example]({% slug drag_hint %})).
         *
         * @default false
         */
        this.hint = false;
        /**
         * Defines the delay in milliseconds after which the drag will begin ([see example](slug drag_target_container#toc-events)).
         *
         * @default 0
         */
        this.dragDelay = 0;
        /**
         * The number of pixels the pointer moves in any direction before the dragging starts ([see example]({% slug minimum_distance %})).
         *
         * @default 0
         */
        this.threshold = 0;
        /**
         * Specifies whether the default dragging behavior will be performed or the developer will manually handle the drag action.
         *
         * @default 'auto'
         */
        this.mode = 'auto';
        /**
         * Fires when a DragTarget's `dragDelay` has passed and the user is able to drag the element.
         */
        this.onDragReady = new EventEmitter();
        /**
         * Fires when the user presses a DragTarget element.
         */
        this.onPress = new EventEmitter();
        /**
         * Fires when the dragging of a DragTarget element begins.
        */
        this.onDragStart = new EventEmitter();
        /**
         * Fires while the user drags a DragTarget element.
         */
        this.onDrag = new EventEmitter();
        /**
         * Fires when the user releases a DragTarget element after being pressed.
         */
        this.onRelease = new EventEmitter();
        /**
         * Fires when the dragging of a DragTarget ends and the element is released.
         */
        this.onDragEnd = new EventEmitter();
        this.currentDragTarget = null;
        this.dragTimeout = null;
        this.pressed = false;
        this.dragStarted = false;
        this.hintComponent = null;
        this.defaultHint = null;
        this.currentDragTargetElement = null;
        this.scrollableParent = null;
        this.previousDragTargets = [];
        this.initialPosition = { x: 0, y: 0 };
        this.position = { x: 0, y: 0 };
        this.positionsMap = new Map();
        this._dragTargetFilter = null;
        this._dragDisabled = false;
        this._dragData = () => null;
        this._dragTargetId = () => null;
        validatePackage(packageMetadata);
    }
    /**
     * Specifies a selector for elements within a container which will be configured as draggable
     * ([see example]({% slug drag_target_container %})). The possible values include any
     * DOM `selector`.
     */
    set dragTargetFilter(value) {
        this._dragTargetFilter = value;
        if (!this.dragDisabled) {
            this.initializeDragTargets();
        }
    }
    get dragTargetFilter() {
        return this._dragTargetFilter;
    }
    /**
     * Defines a unique identifier for each drag target.
     * It exposes the current DragTarget HTML element and its index in the collection of drag targets as arguments.
     */
    set dragTargetId(fn) {
        if (isDevMode && typeof fn !== 'function') {
            throw new Error(`dragTargetId must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this._dragTargetId = fn;
    }
    get dragTargetId() {
        return this._dragTargetId;
    }
    /**
     * Defines a callback function which returns custom data passed to the DropTarget events.
     * It exposes the current DragTarget HTML element, its `dragTargetId` and its index in the collection of drag targets as arguments.
     */
    set dragData(fn) {
        if (isDevMode && typeof fn !== 'function') {
            throw new Error(`dragData must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this._dragData = fn;
    }
    get dragData() {
        return this._dragData;
    }
    /**
     * If set to true, the dragging of DragTargets within the container will be disabled.
     *
     * @default false
     */
    set dragDisabled(value) {
        this._dragDisabled = value;
        if (value) {
            this.clearPreviousTargets();
            this.unsubscribe();
            if (isPresent(this.hintElem)) {
                this.destroyHint();
            }
        }
        else {
            if (isPresent(this.wrapper) || isPresent(this.currentDragTarget)) {
                this.subscribe();
            }
            this.initializeDragTargets();
        }
    }
    get dragDisabled() {
        return this._dragDisabled;
    }
    /**
     * Used for notifying the DragTargetContainer that its content has changed.
     */
    notify() {
        this.cdr.detectChanges();
        this.initializeDragTargets();
    }
    get allDragTargets() {
        return this.queryHost(this.dragTargetFilter);
    }
    get dragHandles() {
        return this.isHandleSelectorValid ? this.queryHost(this.dragHandle) : null;
    }
    get hintTemplate() {
        return isPresent(this.hint) && typeof this.hint === 'object' ? this.hint.hintTemplate : null;
    }
    ngAfterViewInit() {
        const isTargetPresent = isPresent(this.wrapper) || isPresent(this.currentDragTarget);
        if (!this.dragDisabled && isTargetPresent) {
            this.subscribe();
        }
        !this.dragDisabled && this.initializeDragTargets();
    }
    onPointerDown(event) {
        if (isPresent(this.dragHandles) && !this.isDragHandle(event.target)) {
            return;
        }
        const action = getAction(event, this.currentDragTarget);
        this.service.handleDragAndDrop(action);
        this.subscribe();
    }
    onPointerMove(event) {
        event.preventDefault();
        const action = getAction(event, this.currentDragTarget);
        this.service.handleDragAndDrop(action);
    }
    onPointerUp(event) {
        event.preventDefault();
        const action = getAction(event, this.currentDragTarget);
        this.service.handleDragAndDrop(action);
        this.ngZone.runOutsideAngular(() => {
            this.subscribe();
        });
    }
    handlePress(event) {
        if (this.dragDelay > 0) {
            this.dragTimeout = window.setTimeout(() => {
                this.pressed = true;
                this.emitZoneAwareEvent('onDragReady', event);
            }, this.dragDelay);
        }
        else {
            this.pressed = true;
        }
        const eventTarget = event.originalEvent.target;
        this.currentDragTargetElement = closestBySelector(eventTarget, this.dragTargetFilter);
        this.currentDragTarget.element = this.currentDragTargetElement;
        this.service.dragIndex = this.getDragIndex();
        this.scrollableParent = this.hintTemplate ? document.body : this.currentDragTargetElement ? getScrollableParent(this.currentDragTargetElement) : null;
        this.emitZoneAwareEvent('onPress', event);
    }
    handleDragStart(event) {
        if (!this.pressed) {
            if (this.dragTimeout) {
                window.clearTimeout(this.dragTimeout);
                this.dragTimeout = null;
            }
            return;
        }
        isDragStartPrevented = this.emitZoneAwareEvent('onDragStart', event).isDefaultPrevented();
        if (isDragStartPrevented) {
            return;
        }
        this.position = this.positionsMap.has(this.currentDragTargetElement) ? this.positionsMap.get(this.currentDragTargetElement) : { x: 0, y: 0 };
        if (this.hint) {
            this.createHint();
            if (this.mode === 'auto') {
                this.renderer.setStyle(this.currentDragTargetElement, 'opacity', '0.7');
            }
        }
        else {
            this.initialPosition = { x: event.clientX - this.position.x, y: event.clientY - this.position.y };
        }
        this.dragStarted = this.threshold === 0;
        this.service.dragTarget = this.currentDragTarget;
        const targetIdArgs = { dragTarget: this.currentDragTargetElement, dragTargetIndex: this.service.dragIndex };
        this.service.dragTargetId = this.dragTargetId(targetIdArgs);
        const targetDataArgs = Object.assign({ dragTargetId: this.service.dragTargetId }, targetIdArgs);
        this.service.dragData = this.dragData(targetDataArgs);
    }
    handleDrag(event) {
        if (!this.pressed || isDragStartPrevented) {
            return;
        }
        const elem = this.hint ? this.hintElem : this.currentDragTargetElement;
        this.position = this.calculatePosition(elem, event);
        const thresholdNotReached = Math.abs(this.position.x) < this.threshold && Math.abs(this.position.y) < this.threshold;
        if (!this.dragStarted && thresholdNotReached) {
            return;
        }
        if (!this.dragStarted && this.threshold > 0) {
            this.dragStarted = true;
        }
        isDragPrevented = this.emitZoneAwareEvent('onDrag', event).isDefaultPrevented();
        if (isDragPrevented) {
            return;
        }
        if (this.mode === 'auto') {
            this.performDrag();
        }
        else {
            this.dragStarted = true;
        }
    }
    handleRelease(event) {
        if (this.dragStarted) {
            this.positionsMap.set(this.currentDragTargetElement, this.position);
        }
        if (this.dragTimeout) {
            clearTimeout(this.dragTimeout);
            this.dragTimeout = null;
            this.pressed = false;
        }
        this.emitZoneAwareEvent('onRelease', event);
    }
    handleDragEnd(event) {
        if (!this.dragStarted) {
            return;
        }
        if (this.mode === 'auto') {
            const isDroppedOverParentTarget = isPresent(this.service.dropTarget) && !contains(this.service.dropTarget?.element, this.service.dragTarget?.element, true);
            const elem = this.hint ? this.hintElem : this.currentDragTargetElement;
            if (isDroppedOverParentTarget || this.service.dropTargets.length > 0 && isPresent(elem)) {
                this.renderer.removeStyle(elem, 'transform');
                setElementStyles(this.renderer, elem, {
                    transition: dragTargetTransition
                });
                this.positionsMap.delete(this.currentDragTargetElement);
            }
        }
        if (this.hint && isPresent(this.hintElem)) {
            this.destroyHint();
            if (this.mode === 'auto') {
                this.renderer.removeStyle(this.currentDragTargetElement, 'opacity');
            }
        }
        this.service.dragTarget = null;
        this.service.dragIndex = null;
        this.currentDragTarget.element = null;
        this.emitZoneAwareEvent('onDragEnd', event);
        if (isDragStartPrevented || isDragPrevented) {
            return;
        }
        this.dragStarted = false;
    }
    get nativeElement() {
        return this.wrapper.nativeElement;
    }
    get hintElem() {
        return this.hintTemplate && isPresent(this.hintComponent) ? this.hintComponent.instance.element.nativeElement : this.defaultHint;
    }
    unsubscribe() {
        if (this.pointerDownSubscription) {
            this.pointerDownSubscription.unsubscribe();
        }
        if (this.pointerMoveSubscription) {
            this.pointerMoveSubscription.unsubscribe();
        }
        if (this.pointerUpSubscription) {
            this.pointerUpSubscription.unsubscribe();
        }
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }
    subscribe() {
        this.ngZone.runOutsideAngular(() => {
            this.unsubscribe();
            if (!(isDocumentAvailable() && isPresent(this.wrapper))) {
                return;
            }
            const element = this.nativeElement;
            if (this.service.pressed) {
                const pointerMoveStreams = allPointerMoveEvents.map((ev) => fromEvent(document, ev));
                const pointerUpStreams = allPointerUpEvents.map((ev) => fromEvent(document, ev));
                this.pointerMoveSubscription = merge(...pointerMoveStreams)
                    .pipe(filter(() => this.dragTargetFilter !== ''))
                    .subscribe(e => this.onPointerMove(e));
                this.pointerUpSubscription = merge(...pointerUpStreams)
                    .subscribe(e => this.onPointerUp(e));
                if (isPresent(this.scrollableParent)) {
                    this.scrollSubscription = fromEvent(this.scrollableParent, 'scroll')
                        .subscribe(e => this.onPointerMove(e));
                }
            }
            else {
                const pointerDownStreams = allPointerDownEvents.map((ev) => fromEvent(element, ev));
                this.pointerDownSubscription = merge(...pointerDownStreams)
                    .pipe(filter(() => this.dragTargetFilter !== ''))
                    .subscribe((e) => {
                    const filterElement = closestBySelector(e.target, this.isHandleSelectorValid ? this.dragHandle : this.dragTargetFilter);
                    if (filterElement) {
                        this.onPointerDown(e);
                    }
                });
            }
        });
    }
    emitZoneAwareEvent(event, normalizedEvent) {
        const targetIdArgs = { dragTarget: this.currentDragTargetElement, dragTargetIndex: this.service.dragIndex };
        const eventProps = {
            dragTarget: this.currentDragTargetElement,
            dragEvent: normalizedEvent,
            dragTargetIndex: this.service.dragIndex,
            dragTargetId: this.dragTargetId(targetIdArgs)
        };
        if (this.hint && isPresent(this.hintElem)) {
            eventProps.hintElement = this.hintElem;
        }
        let eventArgs;
        switch (event) {
            case 'onDragReady':
                eventArgs = new DragTargetDragReadyEvent(eventProps);
                break;
            case 'onPress':
                eventArgs = new DragTargetPressEvent(eventProps);
                break;
            case 'onDragStart':
                eventArgs = new DragTargetDragStartEvent(eventProps);
                break;
            case 'onDrag':
                eventArgs = new DragTargetDragEvent(eventProps);
                break;
            case 'onRelease':
                eventArgs = new DragTargetReleaseEvent(eventProps);
                break;
            case 'onDragEnd':
                eventArgs = new DragTargetDragEndEvent(eventProps);
                break;
            default:
                break;
        }
        this.ngZone.run(() => {
            this[event].emit(eventArgs);
        });
        return eventArgs;
    }
    createHint() {
        if (!(isDocumentAvailable() && isPresent(this.wrapper))) {
            return;
        }
        if (isPresent(this.hint) && typeof this.hint === 'object') {
            if (isPresent(this.hint.hintTemplate)) {
                this.createCustomHint();
            }
            else {
                this.createDefaultHint();
            }
        }
        else {
            this.createDefaultHint();
        }
        this.currentDragTarget.hint = this.hintElem;
        if (typeof this.hint === 'object' && isPresent(this.hint.appendTo)) {
            this.hint.appendTo.element.nativeElement.appendChild(this.hintElem);
        }
        else {
            document.body.appendChild(this.hintElem);
        }
    }
    createDefaultHint() {
        this.defaultHint = this.currentDragTargetElement.cloneNode(true);
        if (typeof this.hint === 'object') {
            if (isPresent(this.hint.hintClass)) {
                const hintClasses = parseCSSClassNames(this.hint.hintClass);
                hintClasses.forEach(className => this.renderer.addClass(this.defaultHint, className));
            }
        }
    }
    createCustomHint() {
        if (isPresent(this.hint.appendTo)) {
            this.hintComponent = this.hint.appendTo.createComponent(HintComponent);
        }
        else {
            this.hintComponent = this.viewContainer.createComponent(HintComponent);
        }
        this.hintComponent.instance.template = this.hintTemplate;
        this.hintComponent.instance.directive = this;
        this.hintComponent.instance.targetIndex = this.service.dragIndex;
        const targetDataArgs = { dragTarget: this.currentDragTargetElement, dragTargetId: this.service.dragTargetId, dragTargetIndex: this.service.dragIndex };
        this.hintComponent.instance.contextData = this.dragData(targetDataArgs);
        this.hintComponent.changeDetectorRef.detectChanges();
    }
    destroyHint() {
        if (isPresent(this.hintTemplate)) {
            this.hintComponent.destroy();
            this.hintComponent.changeDetectorRef.detectChanges();
            this.hintComponent = null;
        }
        else {
            document.body.removeChild(this.defaultHint);
            this.defaultHint = null;
        }
        this.currentDragTarget.hint = null;
    }
    getDragIndex() {
        return this.allDragTargets.indexOf(this.currentDragTargetElement);
    }
    initializeDragTargets() {
        if (!isPresent(this.allDragTargets)) {
            if (this.previousDragTargets.length > 0) {
                this.clearPreviousTargets();
            }
            return;
        }
        this.allDragTargets.forEach(dragTargetEl => {
            const isDragTargetInitialized = this.service.dragTargets.find(dt => dt.element === dragTargetEl);
            if (!isDragTargetInitialized) {
                this.service.dragTargets.push({
                    element: dragTargetEl,
                    hint: null,
                    onPress: this.handlePress.bind(this),
                    onRelease: this.handleRelease.bind(this),
                    onDragStart: this.handleDragStart.bind(this),
                    onDrag: this.handleDrag.bind(this),
                    onDragEnd: this.handleDragEnd.bind(this)
                });
            }
        });
        if (this.previousDragTargets.length > 0) {
            const dragTargetsToRemove = this.previousDragTargets.filter(dt => !this.allDragTargets.includes(dt));
            dragTargetsToRemove.forEach(dragTarget => {
                const idx = this.service.dragTargets.findIndex(serviceDragTarget => serviceDragTarget.element === dragTarget);
                if (idx > -1) {
                    this.service.dragTargets.splice(idx, 1);
                }
            });
        }
        this.previousDragTargets = this.allDragTargets;
        this.currentDragTarget = {
            element: null,
            hint: null,
            onPress: this.handlePress.bind(this),
            onRelease: this.handleRelease.bind(this),
            onDragStart: this.handleDragStart.bind(this),
            onDrag: this.handleDrag.bind(this),
            onDragEnd: this.handleDragEnd.bind(this)
        };
        this.setCursorStyle();
    }
    isDragHandle(el) {
        return this.dragHandles.some(dh => contains(dh, el, true));
    }
    get isHandleSelectorValid() {
        return isPresent(this.dragHandle) && this.dragHandle !== '';
    }
    setCursorStyle() {
        if (!isDocumentAvailable()) {
            return;
        }
        if (isPresent(this.dragHandle) && this.dragHandle !== '') {
            if (isPresent(this.dragHandles) && this.dragHandles.length > 0) {
                this.dragHandles.forEach(handle => {
                    this.renderer.setStyle(handle, 'cursor', 'move');
                });
            }
        }
        else {
            this.allDragTargets.forEach(target => {
                this.renderer.setStyle(target, 'cursor', 'move');
            });
        }
    }
    queryHost(selector) {
        if (isPresent(selector) && selector !== "") {
            return Array.from(this.nativeElement.querySelectorAll(selector));
        }
    }
    clearPreviousTargets() {
        this.previousDragTargets.forEach(dragTarget => {
            const idx = this.service.dragTargets.findIndex(serviceDragTarget => serviceDragTarget.element === dragTarget);
            if (idx > -1) {
                this.service.dragTargets.splice(idx, 1);
            }
        });
        this.previousDragTargets = [];
    }
    performDrag() {
        const elem = this.hint ? this.hintElem : this.currentDragTargetElement;
        if (elem) {
            const styles = this.getStylesPerElement(elem);
            setElementStyles(this.renderer, elem, styles);
        }
    }
    calculatePosition(element, event) {
        let position = null;
        if (element === this.hintElem) {
            position = { x: event.clientX, y: event.clientY };
        }
        else {
            position = { x: event.clientX - this.initialPosition.x + event.scrollX, y: event.clientY - this.initialPosition.y + event.scrollY };
        }
        return position;
    }
    getStylesPerElement(element) {
        if (element === this.hintElem) {
            const hintCoordinates = { x: this.position.x - this.initialPosition.x, y: this.position.y - this.initialPosition.y };
            return {
                top: `${hintCoordinates.y}px`,
                left: `${hintCoordinates.x}px`,
                transition: 'none',
                position: 'absolute',
                zIndex: 1999
            };
        }
        else {
            const transform = `translate(${this.position.x}px, ${this.position.y}px)`;
            return {
                transform: transform,
                transition: 'none'
            };
        }
    }
}
DragTargetContainerDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragTargetContainerDirective, deps: [{ token: i0.ElementRef }, { token: i0.NgZone }, { token: i0.Renderer2 }, { token: DragStateService }, { token: i0.ViewContainerRef }, { token: i0.ChangeDetectorRef }], target: i0.ɵɵFactoryTarget.Directive });
DragTargetContainerDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DragTargetContainerDirective, selector: "[kendoDragTargetContainer]", inputs: { hint: "hint", dragTargetFilter: "dragTargetFilter", dragHandle: "dragHandle", dragDelay: "dragDelay", threshold: "threshold", dragTargetId: "dragTargetId", dragData: "dragData", dragDisabled: "dragDisabled", mode: "mode" }, outputs: { onDragReady: "onDragReady", onPress: "onPress", onDragStart: "onDragStart", onDrag: "onDrag", onRelease: "onRelease", onDragEnd: "onDragEnd" }, exportAs: ["kendoDragTargetContainer"], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragTargetContainerDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDragTargetContainer]',
                    exportAs: 'kendoDragTargetContainer'
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i0.Renderer2 }, { type: DragStateService }, { type: i0.ViewContainerRef }, { type: i0.ChangeDetectorRef }]; }, propDecorators: { hint: [{
                type: Input
            }], dragTargetFilter: [{
                type: Input
            }], dragHandle: [{
                type: Input
            }], dragDelay: [{
                type: Input
            }], threshold: [{
                type: Input
            }], dragTargetId: [{
                type: Input
            }], dragData: [{
                type: Input
            }], dragDisabled: [{
                type: Input
            }], mode: [{
                type: Input
            }], onDragReady: [{
                type: Output
            }], onPress: [{
                type: Output
            }], onDragStart: [{
                type: Output
            }], onDrag: [{
                type: Output
            }], onRelease: [{
                type: Output
            }], onDragEnd: [{
                type: Output
            }] } });

/**
 * Represents the [Kendo UI DropTargetContainer directive for Angular]({% slug api_utils_droptargetcontainerdirective %}).
 * Used to configure multiple elements as drop targets.
 *
 * @example
 * ```ts-no-run
 * <div kendoDropTargetContainer dropTargetFilter=".my-droptarget">
 *    <div class="my-droptarget">foo</div>
 * </div>
 * ```
 */
class DropTargetContainerDirective {
    constructor(service, element, ngZone, cdr) {
        this.service = service;
        this.element = element;
        this.ngZone = ngZone;
        this.cdr = cdr;
        /**
         * Fires when a DragTarget element enters the DropTarget.
         */
        this.onDragEnter = new EventEmitter();
        /**
         * Fires when a DragTarget element is being dragged over the DropTarget.
         */
        this.onDragOver = new EventEmitter();
        /**
         * Fires when a DragTarget element leaves the DropTarget.
         */
        this.onDragLeave = new EventEmitter();
        /**
         * Fires when a DragTarget element is dropped over the DropTarget.
         */
        this.onDrop = new EventEmitter();
        this.currentDropTarget = null;
        this.currentDropTargetElement = null;
        this.previousDropTargets = [];
        this._dropTargetFilter = null;
        this._dropDisabled = false;
        validatePackage(packageMetadata);
    }
    /**
     * Specifies a selector for elements within a container which will be configured as drop targets
     * ([see example]({% slug drop_target_container %})). The possible values include any
     * DOM `selector`.
     */
    set dropTargetFilter(value) {
        this._dropTargetFilter = value;
        if (!this.dropDisabled) {
            this.initializeDropTargets();
        }
    }
    get dropTargetFilter() {
        return this._dropTargetFilter;
    }
    /**
     * Specifies whether the Drop Targets within the container will emit the corresponding events upon interaction with a Drag Target.
     */
    set dropDisabled(value) {
        this._dropDisabled = value;
        if (value) {
            this.clearPreviousTargets();
        }
        else {
            this.initializeDropTargets();
        }
    }
    get dropDisabled() {
        return this._dropDisabled;
    }
    /**
     * Used for notifying the DropTargetContainer that its content has changed.
     */
    notify() {
        this.cdr.detectChanges();
        this.initializeDropTargets();
    }
    get nativeElement() {
        return this.element.nativeElement;
    }
    ngAfterViewInit() {
        !this.dropDisabled && this.initializeDropTargets();
    }
    get allDropTargets() {
        if (isPresent(this.dropTargetFilter) && this.dropTargetFilter !== '') {
            return Array.from(this.nativeElement.querySelectorAll(this.dropTargetFilter));
        }
    }
    /**
     * @hidden
     */
    handleDragEnter(event) {
        if (!this.service.dragTarget) {
            return;
        }
        const eventTarget = event.originalEvent.target;
        this.currentDropTargetElement = intersect(eventTarget, this.allDropTargets);
        this.currentDropTarget = this.service.dropTargets.find(dt => dt.element === this.currentDropTargetElement);
        this.service.dropTarget = this.currentDropTarget;
        this.service.dropIndex = this.getDropIndex();
        this.emitZoneAwareEvent('onDragEnter', event);
    }
    /**
     * @hidden
     */
    handleDragLeave(event) {
        const containsEventTarget = isPresent(this.service.dropTarget) && contains(this.service.dropTarget?.element, event.originalEvent.target, false);
        if (containsEventTarget) {
            return;
        }
        this.service.dropTarget = null;
        if (!this.service.dragTarget) {
            return;
        }
        this.emitZoneAwareEvent('onDragLeave', event);
    }
    /**
     * @hidden
     */
    handleDragOver(event) {
        if (!this.service.dragTarget) {
            return;
        }
        this.emitZoneAwareEvent('onDragOver', event);
    }
    /**
     * @hidden
     */
    handleDrop(event) {
        if (!this.service.dragTarget) {
            return;
        }
        this.emitZoneAwareEvent('onDrop', event);
        this.currentDropTarget = null;
        this.currentDropTargetElement = null;
        this.service.dropIndex = null;
    }
    initializeDropTargets() {
        if (!isPresent(this.allDropTargets)) {
            if (this.previousDropTargets.length > 0) {
                this.clearPreviousTargets();
            }
            return;
        }
        this.allDropTargets.forEach(dropTargetEl => {
            const isDropTargetInitialized = this.service.dropTargets.find(dt => dt.element === dropTargetEl);
            if (!isDropTargetInitialized) {
                this.service.dropTargets.push({
                    element: dropTargetEl,
                    onDragEnter: this.handleDragEnter.bind(this),
                    onDragLeave: this.handleDragLeave.bind(this),
                    onDragOver: this.handleDragOver.bind(this),
                    onDrop: this.handleDrop.bind(this)
                });
            }
        });
        if (this.previousDropTargets.length > 0) {
            const dropTargetsToRemove = this.previousDropTargets.filter(dt => !this.allDropTargets.includes(dt));
            dropTargetsToRemove.forEach(dropTarget => {
                const idx = this.service.dropTargets.findIndex(serviceDropTarget => serviceDropTarget.element === dropTarget);
                if (idx > -1) {
                    this.service.dropTargets.splice(idx, 1);
                }
            });
        }
        this.previousDropTargets = this.allDropTargets;
    }
    emitZoneAwareEvent(event, normalizedEvent) {
        const eventProps = {
            dragTarget: this.service.dragTarget?.element,
            dropTarget: this.currentDropTargetElement,
            dragData: this.service.dragData,
            dragEvent: normalizedEvent,
            dropTargetIndex: this.service.dropIndex
        };
        if (isPresent(this.service.dragTarget?.hint)) {
            eventProps.hintElement = this.service.dragTarget.hint;
        }
        const eventArgs = new DropTargetEvent(eventProps);
        this.ngZone.run(() => {
            this[event].emit(eventArgs);
        });
    }
    getDropIndex() {
        const allTargets = this.nativeElement.querySelectorAll(this.dropTargetFilter);
        return Array.from(allTargets).indexOf(this.currentDropTargetElement);
    }
    clearPreviousTargets() {
        this.previousDropTargets.forEach(dropTarget => {
            const idx = this.service.dropTargets.findIndex(serviceDropTarget => serviceDropTarget.element === dropTarget);
            if (idx > -1) {
                this.service.dropTargets.splice(idx, 1);
            }
        });
        this.previousDropTargets = [];
    }
}
DropTargetContainerDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DropTargetContainerDirective, deps: [{ token: DragStateService }, { token: i0.ElementRef }, { token: i0.NgZone }, { token: i0.ChangeDetectorRef }], target: i0.ɵɵFactoryTarget.Directive });
DropTargetContainerDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.3.12", type: DropTargetContainerDirective, selector: "[kendoDropTargetContainer]", inputs: { dropTargetFilter: "dropTargetFilter", dropDisabled: "dropDisabled" }, outputs: { onDragEnter: "onDragEnter", onDragOver: "onDragOver", onDragLeave: "onDragLeave", onDrop: "onDrop" }, exportAs: ["kendoDropTargetContainer"], ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DropTargetContainerDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[kendoDropTargetContainer]',
                    exportAs: 'kendoDropTargetContainer'
                }]
        }], ctorParameters: function () { return [{ type: DragStateService }, { type: i0.ElementRef }, { type: i0.NgZone }, { type: i0.ChangeDetectorRef }]; }, propDecorators: { dropTargetFilter: [{
                type: Input
            }], dropDisabled: [{
                type: Input
            }], onDragEnter: [{
                type: Output
            }], onDragOver: [{
                type: Output
            }], onDragLeave: [{
                type: Output
            }], onDrop: [{
                type: Output
            }] } });

const EXPORTS = [
    DragTargetDirective,
    DragHandleDirective,
    DropTargetDirective,
    DragTargetContainerDirective,
    DropTargetContainerDirective,
    HintComponent
];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmodules'])
 * definition for the Drag and Drop directives.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the DragAndDrop module
 * import { DragAndDropModule } from '@progress/kendo-angular-utils';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, DragAndDropModule], // import DragAndDropModule module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class DragAndDropModule {
}
DragAndDropModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragAndDropModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
DragAndDropModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragAndDropModule, declarations: [DragTargetDirective,
        DragHandleDirective,
        DropTargetDirective,
        DragTargetContainerDirective,
        DropTargetContainerDirective,
        HintComponent], imports: [CommonModule], exports: [DragTargetDirective,
        DragHandleDirective,
        DropTargetDirective,
        DragTargetContainerDirective,
        DropTargetContainerDirective,
        HintComponent] });
DragAndDropModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragAndDropModule, imports: [[CommonModule]] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: DragAndDropModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [...EXPORTS],
                    exports: [...EXPORTS],
                    imports: [CommonModule],
                    entryComponents: [HintComponent]
                }]
        }] });

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmodules'])
 * definition for the Utils components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Utils module
 * import { UtilsModule } from '@progress/kendo-angular-utils';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, UtilsModule], // import Utils module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class UtilsModule {
}
UtilsModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: UtilsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
UtilsModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: UtilsModule, exports: [DragAndDropModule] });
UtilsModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: UtilsModule, imports: [DragAndDropModule] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.3.12", ngImport: i0, type: UtilsModule, decorators: [{
            type: NgModule,
            args: [{
                    exports: [DragAndDropModule]
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { DragAndDropModule, DragHandleDirective, DragTargetContainerDirective, DragTargetDirective, DragTargetDragEndEvent, DragTargetDragEvent, DragTargetDragReadyEvent, DragTargetDragStartEvent, DragTargetPressEvent, DragTargetReleaseEvent, DropTargetContainerDirective, DropTargetDirective, DropTargetEvent, HintComponent, UtilsModule };

