import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getGuid } from '@ngrx/data';
import { Observable } from 'rxjs';
import { DataState, getDataByIdAndType, getAllDataByType } from '../reducers/data.reducers';
import {
  LoadDataByIdAction,
  LoadAllDataAction,
  AddDataAction,
  AddDataSuccessAction,
  AddDataFailedAction,
  EditDataAction,
  EditDataSuccessAction,
  EditDataFailedAction,
  DeleteDataAction,
  DeleteDataSuccessAction,
  DeleteDataFailedAction,
  ImportADDataSuccessAction,
  ImportADDataFailedAction,
  ImportADDataAction,
  RefreshADDataAction,
  RefreshADDataSuccessAction,
  RefreshADDataFailedAction,
  ImportDataAction,
  ImportDataSuccessAction,
  ImportDataFailedAction,
  ImportUsersDataFailedAction,
  ImportUsersDataSuccessAction,
  ImportUsersDataAction,
  DeleteUsersDataAction,
  DeleteUsersDataSuccessAction,
  DeleteUsersDataFailedAction,
  SendPinDataSuccessAction,
  SendPinDataFailedAction,
  SendPinDataAction,
  AccountEditSuccessAction,
  AccountEditDataFailedAction,
  AccountEditDataAction,
} from '../actions/data.actions';
import { Actions, ofType } from '@ngrx/effects';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataFacadeService {
  constructor(
    private store: Store<DataState>,
    private actions$: Actions,
  ) {}

  addData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
    data?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      AddDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(AddDataSuccessAction, AddDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
        data: (x as any).data,
      })),
    );
  }

  importData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      ImportDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(ImportDataSuccessAction, ImportDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  editData(
    id: string,
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
    data?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      EditDataAction({
        id,
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(EditDataSuccessAction, EditDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
        data: (x as any).data,
      })),
    );
  }

  deleteData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      DeleteDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(DeleteDataSuccessAction, DeleteDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  loadAndReturnDataById(id: string, dataType: string): Observable<any> {
    this.store.dispatch(
      LoadDataByIdAction({
        id,
        dataType,
      }),
    );
    return this.store.select(getDataByIdAndType(id, dataType));
  }

  loadDataById(id: string, dataType: string): void {
    this.store.dispatch(
      LoadDataByIdAction({
        id,
        dataType,
      }),
    );
  }

  loadAndReturnAllData(dataType: string): Observable<{
    loading: boolean;
    data: any[];
  }> {
    this.store.dispatch(
      LoadAllDataAction({
        dataType,
      }),
    );
    return this.store.select(getAllDataByType(dataType));
  }

  returnAllData(dataType: string): Observable<{
    loading: boolean;
    data: any[];
  }> {
    return this.store.select(getAllDataByType(dataType));
  }

  /* ***** Active Directory ***** */
  importADData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      ImportADDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(ImportADDataSuccessAction, ImportADDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  refreshADData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      RefreshADDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(RefreshADDataSuccessAction, RefreshADDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  /* ***** Import users ***** */
  importUsersData(
    id: string,
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      ImportUsersDataAction({
        id,
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(ImportUsersDataSuccessAction, ImportUsersDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  /* ***** Delete users ***** */
  deleteUsersData(
    id: string,
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      DeleteUsersDataAction({
        id,
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(DeleteUsersDataSuccessAction, DeleteUsersDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  /* ***** Send pin ***** */
  sendPinData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      SendPinDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(SendPinDataSuccessAction, SendPinDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }

  /* ***** AccountEdit ***** */
  accountEditData(
    dataType: string,
    payload: any,
  ): Observable<{
    success: boolean;
    error?: any;
    id?: any;
  }> {
    const commandId = getGuid();
    this.store.dispatch(
      AccountEditDataAction({
        commandId,
        payload,
        dataType,
      }),
    );

    return this.actions$.pipe(
      ofType(AccountEditSuccessAction, AccountEditDataFailedAction),
      take(1),
      map((x) => ({
        success: x.success,
        error: x.success ? null : (x as any).error,
        id: x.success ? (x as any).id : null,
      })),
    );
  }
}
