import { AddEditDigitalSignage } from '@cue/admin-digital-signage';
import { Observable, of, catchError, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class DigitalSignageService implements ValidationService {
  public entity = '/digitalSignage';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getMedia(): Observable<unknown> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/getMedia';
    return this.http.get(url).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  getDetailById(id: string): Observable<any> {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/digitalSignage/grid?&' + query, {}).pipe(
      map(
        (resp: any) =>
          resp.data.map((item) => ({
            ...item,
          }))[0],
      ),
    );
  }

  add(payload: AddEditDigitalSignage): Observable<unknown> {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('useAlsoForUnits', payload.useAlsoForUnits.toString());
    let index = 0;
    payload.newMedia.forEach((media) => {
      const keyPrefix = 'newMedia[' + index.toString() + '].';
      formData.append(keyPrefix + 'name', media.name);
      formData.append(keyPrefix + 'extension', media.extension);
      formData.append(keyPrefix + 'file', media.file);
      formData.append(keyPrefix + 'position', media.position.toString());
      formData.append(keyPrefix + 'showTime', media.showTime.toString());
      formData.append(keyPrefix + 'preferCover', media.preferCover.toString());
      formData.append(keyPrefix + 'size', media.size.toString());
      formData.append(keyPrefix + 'width', media.width.toString());
      formData.append(keyPrefix + 'height', media.height.toString());
      formData.append(keyPrefix + 'type', media.type);
      ++index;
    });
    index = 0;
    payload.existingMedia.forEach((media) => {
      const keyPrefix = 'existingMedia[' + index.toString() + '].';
      formData.append(keyPrefix + 'id', media.id);
      formData.append(keyPrefix + 'position', media.position.toString());
      formData.append(keyPrefix + 'showTime', media.showTime.toString());
      formData.append(keyPrefix + 'preferCover', media.preferCover.toString());
      ++index;
    });

    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http.post(url, formData).pipe(
      map((r) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          tate: 'failed',
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  update(id: string, payload: AddEditDigitalSignage): Observable<unknown> {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('useAlsoForUnits', payload.useAlsoForUnits.toString());
    let index = 0;
    payload.newMedia.forEach((media) => {
      const keyPrefix = 'newMedia[' + index.toString() + '].';
      formData.append(keyPrefix + 'name', media.name);
      formData.append(keyPrefix + 'extension', media.extension);
      formData.append(keyPrefix + 'file', media.file);
      formData.append(keyPrefix + 'position', media.position.toString());
      formData.append(keyPrefix + 'showTime', media.showTime.toString());
      formData.append(keyPrefix + 'preferCover', media.preferCover.toString());
      formData.append(keyPrefix + 'size', media.size.toString());
      formData.append(keyPrefix + 'width', media.width.toString());
      formData.append(keyPrefix + 'height', media.height.toString());
      formData.append(keyPrefix + 'type', media.type);
      ++index;
    });
    index = 0;
    payload.existingMedia.forEach((media) => {
      const keyPrefix = 'existingMedia[' + index.toString() + '].';
      formData.append(keyPrefix + 'id', media.id);
      formData.append(keyPrefix + 'position', media.position.toString());
      formData.append(keyPrefix + 'showTime', media.showTime.toString());
      formData.append(keyPrefix + 'preferCover', media.preferCover.toString());
      ++index;
    });

    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.put(url, formData).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  getMediaForDownload(id: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/mediaDownload/' + id;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  isUniqueName(name: string, id: string): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/digitalSignage/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  delete(ids: string[]): Observable<unknown> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/delete';
    return this.http.post(url, ids).pipe(
      map((r) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }
}
