import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgIf],
  standalone: true,
})
export class LegendComponent implements OnInit {
  @Input() legendTypeCheckbox = false;

  constructor() {}

  ngOnInit(): void {}
}
