import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ImageErrorDirective } from '@cue/admin-core';

@Component({
  selector: 'app-localization-grid-input',
  standalone: true,
  imports: [CommonModule, TranslateModule, TabStripModule, ImageErrorDirective],
  templateUrl: './localization-grid-input.component.html',
  styleUrl: './localization-grid-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizationGridInputComponent {
  @Input() placeholder: string = undefined;
  @Input({ required: true }) userTranslations: { id: string; code: string }[] = [];
  @Input({ required: true }) initValue: any;
  @Input({ required: true }) isDisabled: boolean;
  @Output() valueChange: EventEmitter<{ value: string; languageCode: string }> = new EventEmitter<{
    value: string;
    languageCode: string;
  }>();

  getInitValue(languageCode: string): string {
    if (this.initValue && this.initValue[languageCode]) {
      return this.initValue[languageCode];
    }
    return null;
  }
}
