import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  loadInfo(): Observable<any> {
    return this.getAction('/system/info').pipe(
      switchMap((resp) => {
        const assistVersion = this.http
          .get<{
            version: string;
            branch: string;
          }>(resp.data.assistUrl + '/version.json')
          .pipe(catchError((err) => of({ version: 'n/a', branch: '' })));
        const navigationVersion = this.http
          .get<{
            version: string;
            branch: string;
          }>(resp.data.navigationUrl + '/version.json')
          .pipe(catchError((err) => of({ version: 'n/a', branch: '' })));

        const addinVersion = this.http
          .get<{
            version: string;
            branch: string;
          }>(resp.data.addinUrl + '/version.json')
          .pipe(catchError((err) => of({ version: 'n/a', branch: '' })));

        return forkJoin([assistVersion, navigationVersion, addinVersion]).pipe(
          map(([assist, navigation, addin]) => {
            return {
              ...resp,
              data: {
                ...resp.data,
                assistVersion: assist.version,
                assistBranch: assist.branch,
                navigationVersion: navigation.version,
                navigationBranch: navigation.branch,
                addinVersion: addin.version,
                addinBranch: addin.branch,
              },
            };
          }),
        );
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.get<any>(url).pipe(
      map((x) => {
        return x;
      }),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}
