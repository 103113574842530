/* src/app/shared/components/loader/loader.component.scss */
svg {
  width: 20px;
  height: 20px;
}
.colorText {
  color: #000000;
}
.colorPrimary {
  color: #4e1289;
}
.colorWhite {
  color: white;
}
/*# sourceMappingURL=loader.component.css.map */
