<div class="switch">
    {{ valueBefore }}
    <div>
        <kendo-switch size="small" [checked]="checked" (valueChange)="valueChange.emit($event)"></kendo-switch>
    </div>
    {{ valueAfter }} {{ selectedCount }}
</div>
<p
    (click)="clearFiltersClicked.emit()"
    class="delete-filters"
    [title]="'resources.clearSelection' | translate"
    [class.disabled]="selectedCount < 1"
>
    {{ 'resources.clear' | translate }}
</p>
