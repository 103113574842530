<app-dialog-wrapper
    [title]="'qrCategories.setRank' | translate"
    [activeButtonLabel]="'actions.save' | translate"
    (activeDialog)="onSetRank()"
    (cancelDialog)="cancelDialog.emit()"
    [activeButtonDisabled]="setRankClicked"
>
    <ng-container>
        <div class="col-md-4" [style.max-height.px]="maxHeight">
            <div class="sortable-layer">
                <kendo-sortable
                    [kendoSortableBinding]="items"
                    [itemStyle]="{ border: '0px', opacity: '1', cursor: 'move' }"
                    [emptyItemStyle]="{ height: '30px', border: '1px dashed black' }"
                    [activeItemStyle]="{ border: '1px dashed #adadad', opacity: '0.7' }"
                    [emptyText]="'qrCode.empty' | translate"
                    zone="zone"
                >
                    <ng-template let-item="item">
                        <div class="sortable-item">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </kendo-sortable>
            </div>
        </div>
    </ng-container>
</app-dialog-wrapper>
