import Quill from 'quill';
const Delta = Quill.import('delta');
const Embed = Quill.import('blots/embed');

export class SoftLineBreakBlot extends Embed {
  static blotName = 'softBreak';
  static tagName = 'br';
}

export function shiftEnterHandler(this: any, range) {
  const currentLeaf = this.quill.getLeaf(range.index)[0];
  const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
  this.quill.insertEmbed(range.index, 'softBreak', true, Quill.sources.USER);
  // druhy break pokud jsme na konci nebo next leaf has a different parent (<p>)
  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    this.quill.insertEmbed(range.index, 'softBreak', true, Quill.sources.USER);
  }
  this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

export function brMatcher() {
  let newDelta = new Delta();
  newDelta.insert({ softBreak: true });
  return newDelta;
}
