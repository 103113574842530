export interface ItemConfig {
  title: string;
  fieldName: string;
}

export interface LocalizationPair {
  languageCode: string;
  value: string;
}

export interface BaseLocalization {
  id: number | string;
  name: LocalizationPair[];
}

export interface LocalizationResponseState {
  success: true;
  data: ResourceLocalization | ResourceTypeLocalization;
}

export interface LocalizationResponseFailedState {
  success: false;
  errorCode: string;
  errorDescription: string;
}

export interface ResourceLocalization {
  id: number;
  displayName: LocalizationPair[];
  displayNameForUnit: LocalizationPair[];
  displayNameForApp: LocalizationPair[];
  description: LocalizationPair[];
  privateDisplayNamePlaceholder: LocalizationPair[];
}

export interface ResourceTypeLocalization extends BaseLocalization {
  customTitle: LocalizationPair[];
  customDescription: LocalizationPair[];
}

export interface ResourcePropertyLocalization extends BaseLocalization {
  description: LocalizationPair[];
}

export interface ResourcePropertyItemLocalization {
  id: string;
  itemName: LocalizationPair[];
}

export interface ResourcePropertyItemLocalization {
  id: string;
  uniqueName: LocalizationPair[];
}

export interface ServiceLocalization {
  id: number;
  serviceName: LocalizationPair[];
}

export interface QRCodeLocalization {
  id: string;
  uniqueNameDefault: LocalizationPair[];
  shortDisplayNameDefault: LocalizationPair[];
  mapNameDefault: LocalizationPair[];
  privateDisplayNamePlaceholder: LocalizationPair[];
}

export interface KioskLocalization {
  id: string;
  textForScanQrCodeDefault: LocalizationPair[];
  customTextDefault: LocalizationPair[];
}

export interface NewsLocalization {
  id: string;
  titleDefault: LocalizationPair[];
  shortDescriptionDefault: LocalizationPair[];
  bodyDefault: LocalizationPair[];
}

export interface CookiesLocalization {
  id: string;
  titleDefault: LocalizationPair[];
  shortDescriptionDefault: LocalizationPair[];
  acceptButtonDefault: LocalizationPair[];
  declineButtonDefault: LocalizationPair[];
}

export interface PathLocalization {
  id: string;
  titleForward: LocalizationPair[];
  titleBackward: LocalizationPair[];
  descriptionForward: LocalizationPair[];
  descriptionBackward: LocalizationPair[];
}

export interface ResourceAndResourcePropertyLocalization {
  id: string;
  resourceValueString: LocalizationPair[];
  resourceValueHtmlContent: LocalizationPair[];
}

export const RESOURCE_CONFIG: ItemConfig[] = [
  {
    title: 'resources.displayName',
    fieldName: 'displayName',
  },
  {
    title: 'resources.displayNameForUnit',
    fieldName: 'displayNameForUnit',
  },
  {
    title: 'resources.displayNameForApp',
    fieldName: 'displayNameForApp',
  },
  {
    title: 'resources.description',
    fieldName: 'description',
  },
  {
    title: 'resources.privateDisplayNamePlaceholder',
    fieldName: 'privateDisplayNamePlaceholder',
  },
];

export const RESOURCE_TYPE_CONFIG: ItemConfig[] = [
  {
    title: 'resources.displayName',
    fieldName: 'name',
  },
  {
    title: 'resourceTypes.customTitle',
    fieldName: 'customTitle',
  },
  {
    title: 'resourceTypes.customDescription',
    fieldName: 'customDescription',
  },
];

export const RESOURCE_PROPERTIES_CONFIG: ItemConfig[] = [
  {
    title: 'resourceProperties.name',
    fieldName: 'name',
  },
  {
    title: 'resourceProperties.description',
    fieldName: 'description',
  },
];

export const RESOURCE_PROPERTY_ITEMS_CONFIG: ItemConfig[] = [
  {
    title: 'languages.resourcePropertyItemName',
    fieldName: 'itemName',
  },
];

export const USER_GROUPS_CONFIG: ItemConfig[] = [
  {
    title: 'qrCode.name',
    fieldName: 'uniqueName',
  },
];

export const AREAS_CONFIG: ItemConfig[] = [
  {
    title: 'areas.name',
    fieldName: 'name',
  },
];

export const SERVICE_TYPES_CONFIG: ItemConfig[] = [
  {
    title: 'areas.name',
    fieldName: 'name',
  },
];

export const SERVICES_CONFIG: ItemConfig[] = [
  {
    title: 'languages.serviceName',
    fieldName: 'serviceName',
  },
];

export const QR_CODES_CONFIG: ItemConfig[] = [
  {
    title: 'qrCode.name',
    fieldName: 'uniqueName',
  },
  {
    title: 'qrCode.shortDisplayName',
    fieldName: 'shortDisplayName',
  },
  {
    title: 'qrCode.mapName',
    fieldName: 'mapName',
  },
  {
    title: 'resources.privateDisplayNamePlaceholder',
    fieldName: 'privateDisplayNamePlaceholder',
  },
];

export const QR_CATEGORIES_CONFIG: ItemConfig[] = [
  {
    title: 'qrCategories.name',
    fieldName: 'name',
  },
];

export const KIOSKS_CONFIG: ItemConfig[] = [
  {
    title: 'kiosks.textForScanQrCode',
    fieldName: 'textForScanQrCode',
  },
  {
    title: 'kiosks.customText',
    fieldName: 'customText',
  },
];

export const NEWS_CONFIG: ItemConfig[] = [
  {
    title: 'news.title',
    fieldName: 'title',
  },
  {
    title: 'news.shortDescription',
    fieldName: 'shortDescription',
  },
  {
    title: 'news.body',
    fieldName: 'body',
  },
];

export const COOKIES_CONFIG: ItemConfig[] = [
  {
    title: 'cookies.title',
    fieldName: 'title',
  },
  {
    title: 'cookies.shortDescription',
    fieldName: 'shortDescription',
  },
  {
    title: 'cookies.acceptButton',
    fieldName: 'acceptButton',
  },
  {
    title: 'cookies.declineButton',
    fieldName: 'declineButton',
  },
];

export const PATHS_CONFIG: ItemConfig[] = [
  {
    title: 'areas.titleForward',
    fieldName: 'titleForward',
  },
  {
    title: 'areas.titleBackward',
    fieldName: 'titleBackward',
  },
  {
    title: 'areas.descriptionForward',
    fieldName: 'descriptionForward',
  },
  {
    title: 'areas.descriptionBackward',
    fieldName: 'descriptionBackward',
  },
];

export const RESOURCE_AND_RESOURCE_PROPERTIES_CONFIG: ItemConfig[] = [
  {
    title: 'properties.string',
    fieldName: 'resourceValueString',
  },
  {
    title: 'properties.htmlContent',
    fieldName: 'resourceValueHtmlContent',
  },
];
