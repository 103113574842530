import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ConfigService } from '@cue/admin-core';

@Directive({
  selector: '[appWIPHide]',
  standalone: true,
})
export class WipDirective implements AfterViewInit {
  @Input() appWIPHide;

  constructor(
    private configService: ConfigService,
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.appWIPHide !== false) {
      const isWIP =
        this.configService.value.wip !== null && this.configService.value.wip !== undefined
          ? this.configService.value.wip
          : this.configService.value.apiWip;
      if (!isWIP) {
        this.elementRef.nativeElement.style.setProperty('display', 'none', 'important');
      } else {
        this.elementRef.nativeElement.style.setProperty('background-color', '#e5e5f7', 'important');
        this.elementRef.nativeElement.style.setProperty(
          'background-image',
          'repeating-linear-gradient(45deg, rgba(247, 69, 69, 0.1) 25%, transparent 25%, transparent 75%, rgba(247, 69, 69, 0.1) 75%, rgba(247, 69, 69, 0.23)), repeating-linear-gradient(45deg, rgba(247, 69, 69, 0.1) 25%, #e5e5f7 25%, #e5e5f7 75%, rgba(247, 69, 69, 0.1) 75%, rgba(247, 69, 69, 0.1))',
          'important',
        );
        this.elementRef.nativeElement.style.setProperty('background-position', '0 0, 17px 17px', 'important');
        this.elementRef.nativeElement.style.setProperty('background-size', '34px 34px', 'important');
      }
    }
  }
}
