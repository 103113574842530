import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogWrapperComponent } from '@cue/admin-shared';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { AppNotificationService, SetRankService } from '@cue/admin-core';

@Component({
  selector: 'app-set-rank-dialog',
  templateUrl: './set-rank-dialog.component.html',
  styleUrl: './set-rank-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, DialogWrapperComponent, SortableModule],
})
export class SetRankDialogComponent {
  public setRankClicked = false;
  public items: { id: string; name: string; rank: number }[] = [];
  public maxHeight: number;
  @Input() public set _data(value) {
    if (value && value.success && value.data) {
      this.items = value.data;
    }
  }
  @Input() setRankService: SetRankService;
  @Output() cancelDialog = new EventEmitter();

  constructor(
    private appNotificationService: AppNotificationService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.getScreenSize();
  }

  onSetRank() {
    this.setRankClicked = true;
    const ids = this.items.map((x) => x.id);
    this.setRankService.setRank(ids).subscribe((result) => {
      this.setRankClicked = false;
      if (result.success) {
        this.appNotificationService.success(this.translateService.instant('general.successSave'));
        this.cancelDialog.emit();
      } else {
        this.appNotificationService.error(result.errorCode + ' ' + result.errorDescription);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.maxHeight = window.innerHeight - 120;
  }
}
