import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  testServer(data: any): Observable<any> {
    return this.postAction('/server/test', data);
  }

  testServiceAccount(data: any): Observable<any> {
    return this.postAction('/serviceAccount/test', data);
  }

  testResource(data: any): Observable<any> {
    return this.postAction('/resource/test', data);
  }

  testActiveDirectory(data: any): Observable<any> {
    return this.postAction('/activeDirectory/test', data);
  }

  testAccount(data: any): Observable<any> {
    return this.postAction('/connectionAgent/sendEmail', data);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}
