import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { SetRankService } from './set-rank.service';

@Injectable({
  providedIn: 'root',
})
export class ResourcePropertyService implements SetRankService {
  public entity = CONSTANTS.entity.resourceProperty;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  loadData(): Observable<any> {
    const payload = {
      resourceType: true,
      dataType: true,
      resourcePropertyItem: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      dataType: true,
      resourceType: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  getSelectionsData(): Observable<any> {
    return this.getAction(this.entity + '/selectionsData');
  }

  setSelectionsData(payload: any): Observable<any> {
    return this.postAction(this.entity + '/selectionsData', payload);
  }

  getDetailById(id: string) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/ResourceProperty/grid?&' + query, {}).pipe(
      map(
        (resp: any) =>
          resp.data.map((resProp) => ({
            ...resProp,
          }))[0],
      ),
    );
  }

  getResourcePropertyValues(id: string): Observable<any> {
    return this.getAction(this.entity + '/' + id + '/values');
  }

  setResourcePropertyValues(id: string, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + '/values', payload);
  }

  getRank(): Observable<any> {
    return this.getAction(this.entity + '/GetResourcePropertyRank');
  }

  setRank(payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/SetResourcePropertyRank';
    return this.http.post(url, payload).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }
}
