import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { combineLatest, Observable, of, take } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import {
  AREAS_CONFIG,
  BaseLocalization,
  COOKIES_CONFIG,
  CookiesLocalization,
  ItemConfig,
  KioskLocalization,
  KIOSKS_CONFIG,
  NEWS_CONFIG,
  NewsLocalization,
  QR_CATEGORIES_CONFIG,
  QR_CODES_CONFIG,
  QRCodeLocalization,
  RESOURCE_CONFIG,
  RESOURCE_PROPERTIES_CONFIG,
  RESOURCE_PROPERTY_ITEMS_CONFIG,
  RESOURCE_TYPE_CONFIG,
  ResourceLocalization,
  ResourcePropertyItemLocalization,
  ResourcePropertyLocalization,
  ResourceTypeLocalization,
  SERVICE_TYPES_CONFIG,
  ServiceLocalization,
  SERVICES_CONFIG,
  USER_GROUPS_CONFIG,
  PathLocalization,
  PATHS_CONFIG,
  ResourceAndResourcePropertyLocalization,
  RESOURCE_AND_RESOURCE_PROPERTIES_CONFIG,
} from '@cue/admin-languages';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public entity = CONSTANTS.entity.language;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(LOCALE_ID) private locale: string,
    public datepipe: DatePipe,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  load(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + CONSTANTS.api.load;
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  setData(payload: { id: number }[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/setData';
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  exportLocalizationData(languageIds: number[], exportEndpoint: string): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + exportEndpoint;
    return this.http.post(url, languageIds).pipe(
      map((x) => ({ data: x, success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  importLocalizationData(file: File, languageIds: number[], formValue: any): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/importLocalizationData';
    const formData = new FormData();
    formData.append('importFile', file);
    let index = 0;
    languageIds.forEach((id) => {
      formData.append('languageIds', id.toString());
      ++index;
    });
    formData.append('resourceDisplayName', formValue.resources.displayName);
    formData.append('resourceDisplayNameForUnit', formValue.resources.displayNameForUnit);
    formData.append('resourceDisplayNameForApp', formValue.resources.displayNameForApp);
    formData.append('resourceDescription', formValue.resources.description);
    formData.append('resourcePrivateDisplayNamePlaceholder', formValue.resources.privateDisplayNamePlaceholder);
    formData.append('resourceTypeName', formValue.resourceTypes.name);
    formData.append('resourceTypeCustomTitle', formValue.resourceTypes.customTitle);
    formData.append('resourceTypeCustomDescription', formValue.resourceTypes.customDescription);
    formData.append('resourcePropertyName', formValue.resourceProperties.name);
    formData.append('resourcePropertyDescription', formValue.resourceProperties.description);
    formData.append('resourcePropertyItemName', formValue.resourcePropertyItems.itemName);
    formData.append('userGroupUniqueName', formValue.userGroups.uniqueName);
    formData.append('areasName', formValue.areas.name);
    formData.append('serviceTypeName', formValue.serviceTypes.name);
    formData.append('serviceName', formValue.services.serviceName);
    formData.append('qRCodeUniqueName', formValue.qrCodes.uniqueName);
    formData.append('qRCodeShortDisplayName', formValue.qrCodes.shortDisplayName);
    formData.append('qRCodeMapName', formValue.qrCodes.mapName);
    formData.append('qRCodePrivateDisplayNamePlaceholder', formValue.qrCodes.privateDisplayNamePlaceholder);
    formData.append('qRCategoryName', formValue.qrCategories.name);
    formData.append('kioskTextForScanQrCode', formValue.kiosks.textForScanQrCode);
    formData.append('kioskCustomText', formValue.kiosks.customText);
    formData.append('newsTitle', formValue.news.title);
    formData.append('newsShortDescription', formValue.news.shortDescription);
    formData.append('newsBody', formValue.news.body);
    formData.append('cookiesTitle', formValue.cookies.title);
    formData.append('cookiesShortDescription', formValue.cookies.shortDescription);
    formData.append('cookiesAcceptButton', formValue.cookies.acceptButton);
    formData.append('cookiesDeclineButton', formValue.cookies.declineButton);
    formData.append('pathTitleForward', formValue.paths.titleForward);
    formData.append('pathTitleBackward', formValue.paths.titleBackward);
    formData.append('pathDescriptionForward', formValue.paths.descriptionForward);
    formData.append('pathDescriptionBackward', formValue.paths.descriptionBackward);
    formData.append('resourceAndResourcePropertyResourceValueString', formValue.resourceAndResourceProperties.resourceValueString);
    formData.append(
      'resourceAndResourcePropertyResourceValueHtmlContent',
      formValue.resourceAndResourceProperties.resourceValueHtmlContent,
    );

    return this.http.post(url, formData).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  getActiveUserTranslations(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/activeUserTranslations';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getLanguageSettings(typeId: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/getLanguageSettings/' + typeId;
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  setLanguageSettings(payload: any, ids: number[], typeId: number): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/setLanguageSettings';
    return this.http.post(url, { ...payload, ids, typeId }).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  deleteLanguageTranslations(id: number): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.delete<any>(url).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  exportedFileName(): string {
    const dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Short).replace('yy', 'yyyy');
    let fileDate = this.datepipe.transform(new Date(), dateFormat);
    fileDate = fileDate.replace(/\//g, '-').replace(/_/g, '-');
    return 'cue-solution-lang-' + fileDate;
  }

  getExportData(languageIds: number[]): Observable<any> {
    return combineLatest([
      this.exportLocalizationData(languageIds, 'exportResourceData'),
      this.exportLocalizationData(languageIds, 'exportResourceTypeData'),
      this.exportLocalizationData(languageIds, 'exportResourcePropertyData'),
      this.exportLocalizationData(languageIds, 'exportResourcePropertyItemData'),
      this.exportLocalizationData(languageIds, 'exportUserGroupData'),
      this.exportLocalizationData(languageIds, 'exportAreaData'),
      this.exportLocalizationData(languageIds, 'exportServiceTypeData'),
      this.exportLocalizationData(languageIds, 'exportServiceData'),
      this.exportLocalizationData(languageIds, 'exportQRCodeData'),
      this.exportLocalizationData(languageIds, 'exportQRCategoryData'),
      this.exportLocalizationData(languageIds, 'exportKioskData'),
      this.exportLocalizationData(languageIds, 'exportNewsData'),
      this.exportLocalizationData(languageIds, 'exportCookiesData'),
      this.exportLocalizationData(languageIds, 'exportPathData'),
      this.exportLocalizationData(languageIds, 'exportResourceAndResourcePropertyData'),
    ]).pipe(
      take(1),
      map(
        ([
          resources,
          resourceTypes,
          resourceProperties,
          resourcePropertyItems,
          userGroups,
          areas,
          serviceTypes,
          services,
          qrCodes,
          qrCategories,
          kiosks,
          news,
          cookies,
          paths,
          resourceAndResourceProperties,
        ]) => {
          const exportData = [];
          if (resources.success) {
            exportData.push({
              title: 'Resources',
              name: 'resources',
              data: this.localizationResult(resources.data as ResourceLocalization[], RESOURCE_CONFIG),
              configItems: RESOURCE_CONFIG,
            });
          }
          if (resourceTypes.success) {
            exportData.push({
              title: 'Resource types',
              name: 'resourceTypes',
              data: this.localizationResult(resourceTypes.data as ResourceTypeLocalization[], RESOURCE_TYPE_CONFIG),
              configItems: RESOURCE_TYPE_CONFIG,
            });
          }
          if (resourceProperties.success) {
            exportData.push({
              title: 'Resource properties',
              name: 'resourceProperties',
              data: this.localizationResult(resourceProperties.data as ResourcePropertyLocalization[], RESOURCE_PROPERTIES_CONFIG),
              configItems: RESOURCE_PROPERTIES_CONFIG,
            });
          }
          if (resourcePropertyItems.success) {
            exportData.push({
              title: 'Resource property items',
              name: 'resourcePropertyItems',
              data: this.localizationResult(
                resourcePropertyItems.data as ResourcePropertyItemLocalization[],
                RESOURCE_PROPERTY_ITEMS_CONFIG,
              ),
              configItems: RESOURCE_PROPERTY_ITEMS_CONFIG,
            });
          }
          if (userGroups.success) {
            exportData.push({
              title: 'User groups',
              name: 'userGroups',
              data: this.localizationResult(userGroups.data as BaseLocalization[], USER_GROUPS_CONFIG),
              configItems: USER_GROUPS_CONFIG,
            });
          }
          if (areas.success) {
            exportData.push({
              title: 'Areas',
              name: 'areas',
              data: this.localizationResult(areas.data as BaseLocalization[], AREAS_CONFIG),
              configItems: AREAS_CONFIG,
            });
          }
          if (serviceTypes.success) {
            exportData.push({
              title: 'Service types',
              name: 'serviceTypes',
              data: this.localizationResult(serviceTypes.data as BaseLocalization[], SERVICE_TYPES_CONFIG),
              configItems: SERVICE_TYPES_CONFIG,
            });
          }
          if (services.success) {
            exportData.push({
              title: 'Services',
              name: 'services',
              data: this.localizationResult(services.data as ServiceLocalization[], SERVICES_CONFIG),
              configItems: SERVICES_CONFIG,
            });
          }
          if (qrCodes.success) {
            exportData.push({
              title: 'POI QR codes',
              name: 'qrCodes',
              data: this.localizationResult(qrCodes.data as QRCodeLocalization[], QR_CODES_CONFIG),
              configItems: QR_CODES_CONFIG,
            });
          }
          if (qrCategories.success) {
            exportData.push({
              title: 'POI QR code categories',
              name: 'qrCategories',
              data: this.localizationResult(qrCategories.data as BaseLocalization[], QR_CATEGORIES_CONFIG),
              configItems: QR_CATEGORIES_CONFIG,
            });
          }
          if (kiosks.success) {
            exportData.push({
              title: 'Kiosks',
              name: 'kiosks',
              data: this.localizationResult(kiosks.data as KioskLocalization[], KIOSKS_CONFIG),
              configItems: KIOSKS_CONFIG,
            });
          }
          if (news.success) {
            exportData.push({
              title: 'News',
              name: 'news',
              data: this.localizationResult(news.data as NewsLocalization[], NEWS_CONFIG),
              configItems: NEWS_CONFIG,
            });
          }
          if (cookies.success) {
            exportData.push({
              title: 'Cookies',
              name: 'cookies',
              data: this.localizationResult(cookies.data as CookiesLocalization[], COOKIES_CONFIG),
              configItems: COOKIES_CONFIG,
            });
          }
          if (cookies.success) {
            exportData.push({
              title: 'Paths',
              name: 'paths',
              data: this.localizationResult(paths.data as PathLocalization[], PATHS_CONFIG),
              configItems: PATHS_CONFIG,
            });
          }
          if (cookies.success) {
            exportData.push({
              title: 'Resource and resource property',
              name: 'resourceAndResourceProperties',
              data: this.localizationResult(
                resourceAndResourceProperties.data as ResourceAndResourcePropertyLocalization[],
                RESOURCE_AND_RESOURCE_PROPERTIES_CONFIG,
              ),
              configItems: RESOURCE_AND_RESOURCE_PROPERTIES_CONFIG,
            });
          }
          return exportData;
        },
      ),
    );
  }

  private localizationResult(
    data:
      | BaseLocalization[]
      | ResourceLocalization[]
      | ResourceTypeLocalization[]
      | ResourcePropertyLocalization[]
      | ResourcePropertyItemLocalization[]
      | ServiceLocalization[]
      | QRCodeLocalization[]
      | KioskLocalization[]
      | NewsLocalization[]
      | CookiesLocalization[]
      | PathLocalization[]
      | ResourceAndResourcePropertyLocalization[],
    config: ItemConfig[],
  ) {
    return data.map((item) => {
      const newItem = {
        id: item.id,
      };
      config.forEach((configItem) => {
        newItem[configItem.fieldName] = {};
        item[configItem.fieldName].forEach((x) => {
          newItem[configItem.fieldName][x.languageCode] = x.value;
        });
      });
      return newItem;
    });
  }

  createLocalizationPairs(userTranslations: { id: string; code: string }[], form: FormGroup, controlName: string) {
    const nameLocalization = [];
    userTranslations.forEach((tr) => {
      const value = form.get(controlName + tr.code).value;
      if (value) nameLocalization.push({ value: value, languageCode: tr.code });
    });
    return nameLocalization;
  }

  createLocalizations(userTranslations: { id: string; code: string }[], form: FormGroup, controlName: string) {
    const nameLocalization = [];
    userTranslations.forEach((tr) => {
      const value = form.get(controlName + tr.code).value;
      if (value) nameLocalization[tr.code] = value;
    });
    return nameLocalization;
  }

  formPatchLocalizationValue(
    userTranslations: { id: string; code: string }[],
    form: FormGroup,
    controlName: string,
    localizationArray: any[],
  ) {
    userTranslations.forEach((tr) => {
      const localizationValue = localizationArray[tr.code];
      if (localizationValue) {
        form.get(controlName + tr.code).patchValue(localizationValue, { emitEvent: false });
      }
    });
  }

  formPatchLocalizationValueMultiEdit(
    userTranslations: { id: string; code: string }[],
    form: FormGroup,
    controlName: string,
    multiEditLocalizationData: any[],
  ) {
    userTranslations.forEach((tr) => {
      const multiEditData: string[] = [];
      multiEditLocalizationData.forEach((localization) => {
        const value = localization[tr.code];
        multiEditData.push(value);
      });
      form.get(controlName + tr.code).patchValue(new Set(multiEditData).size === 1 ? multiEditData[0] : null, { emitEvent: false });
    });
  }

  transformData(payload: {
    copyBaseToTranslation: boolean;
    copyTranslationToBase: boolean;
    deleteTranslation: boolean;
    oldLanguageId: number;
    newLanguageId: number;
  }): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/transformData';
    return this.http.post(url, payload).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }
}
